import React, { useContext, useEffect, useState } from "react";
import { Checkbox } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

import DeviceTable from "../../tables/devices/DeviceTable";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyDevicesInfo = () => {
  const { user: adminUser } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const [devices, setDevices] = useState([]);
  const [showOnlyValidated, setShowOnlyValidated] = useState(false);
  const [showWaitingRenewal, setShowWaitingRenewal] = useState(false);
  const [showDoneRenewal, setShowDoneRenewal] = useState(false);
  const [showOnlyExternal, setShowOnlyExternal] = useState(false);

  const validated_statuses = ["PURCHASED", "SHIPPED", "DELIVERED", "BDL_SENT", "RECEIVED"];

  const loadDevices = async () => {
    if (company && company.id) {
      const fetchedDevices = await adminUser.api.getDevices({ params: { company_id: company.id, show_externals: showOnlyExternal } });

      setDevices(fetchedDevices.data);
    }
  };

  useEffect(() => {
    loadDevices();
  }, [company, showOnlyExternal]);

  let devicesToShow = devices;

  if (showOnlyValidated) {
    devicesToShow = devices.filter((d) => validated_statuses.includes(d.status) && d.source === "FLEET");
  } else if (showWaitingRenewal) {
    devicesToShow = devices.filter((d) => d.end_of_life_status === "WAITING" && d.source === "FLEET");
  } else if (showDoneRenewal) {
    devicesToShow = devices.filter((d) => d.end_of_life_status === "DONE" && d.source === "FLEET");
  } else if (showOnlyExternal) {
    devicesToShow = devices.filter((d) => d.source === "EXTERNAL");
  } else {
    devicesToShow = devices.filter((d) => d.source === "FLEET");
  }

  return (
    <>
      <Checkbox
        label="Show only the devices currently rented"
        checked={showOnlyValidated}
        onChange={() => setShowOnlyValidated(!showOnlyValidated)}
      />

      <Checkbox
        label="Show only the waiting for renewal devices"
        checked={showWaitingRenewal}
        onChange={() => setShowWaitingRenewal(!showWaitingRenewal)}
      />

      <Checkbox
        label="Show only the done status devices"
        checked={showDoneRenewal}
        onChange={() => setShowDoneRenewal(!showDoneRenewal)}
      />

      <Checkbox
        label="Show only the external devices"
        checked={showOnlyExternal}
        onChange={() => setShowOnlyExternal(!showOnlyExternal)}
      />

      <DeviceTable reloadPage={loadDevices} devices={devicesToShow} />
    </>
  );
};

export default CompanyDevicesInfo;
