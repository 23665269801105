import React, { useMemo } from "react";
import {
  Icon, Layout, Separator, Text,
} from "@fleet.co/tarmac";
import { faClock, faHandshake, faUser } from "@fortawesome/pro-regular-svg-icons";

import { useSearchContext } from "../../contexts/SearchContext";
import FormatDate from "../../tools/FormatDate";

const CompanyResult = (props) => {
  const { company } = props;
  const { searchHistory } = useSearchContext();

  const isFromHistory = useMemo(() => searchHistory.companies.some((item) => item.id === company.id), [searchHistory, company]);

  return (
    <Layout
      fullWidth
      direction="row"
      key={`companies-${company.id}`}
      divider={<Separator orientation="vertical" />}
      spacing={1}
    >
      <Layout
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
        flexWrap
        sx={{ width: "50%" }}
      >
        <Text variant="body2" noWrap>
          {isFromHistory && (
            <>
              <Icon icon={faClock} />
              {" "}
            </>
          )}
          {company.name}
        </Text>

        <Layout direction="row" alignItems="center" spacing={1}>
          <Text variant="caption">
            <Icon icon={faUser} color="secondary" size="S" />
            {" "}
            {company.employees.length}
          </Text>

          <Text variant="caption">
            <Icon icon={faHandshake} color="secondary" size="S" />
            {" "}
            {company.orders.length}
          </Text>

          {company.lastValidatedOrder && (
            <Text variant="caption">
              (last validated :
              {" "}
              <i><FormatDate date={company.lastValidatedOrder.order_date} /></i>
              )
            </Text>
          )}
        </Layout>
      </Layout>

      <Text variant="body2" align="center" sx={{ width: "15%" }}>{company.siren ? company.siren : <i>SIREN missing</i>}</Text>
      <Text variant="body2" align="center" sx={{ width: "25%" }}>{company.domain ? company.domain : <i>Domain missing</i>}</Text>
    </Layout>
  );
};

export default CompanyResult;
