import React, { useContext, useEffect, useState } from "react";
import qs from "qs";
import {
  Button, Checkbox, Heading, Icon, Layout, TabItem, Tabs,
} from "@fleet.co/tarmac";
import {
  faChevronDown, faChevronRight, faChevronUp, faSend,
} from "@fortawesome/pro-regular-svg-icons";
import ContractSigningRow from "../tables/invoices/ContractSigningRow";
import UserContext from "../../tools/UserContext";
import LoadingSpinner from "../common/LoadingSpinner";
import usePersistedState from "../../hooks/usePersistedState";
import styles from "./ContractSigning.module.scss";
import ActionConfirmation from "../common/ActionConfirmation";
import { leasersList } from "../../data/leasersList";
import { formatMoney } from "../../helpers/formatHelpers";
import { convertPrice } from "../../tools/OrderTools";

const ContractSigning = () => {
  const { user: adminUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [sortingRule, setSortingRule] = useState({
    column: "date",
    direction: false,
  });
  const [massSelectedEnabled, setMassSelectedEnabled] = useState(false);
  const [massSelectedOrderlist, setMassSelectedOrderlist] = useState([]);
  const [leaserSelectedList, setLeaserSelectedList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const today = new Date();
  const one_year_ago = new Date();

  one_year_ago.setFullYear(today.getFullYear() - 1);
  const initial_filters = {
    // Les filtres de date sont null par défaut
    orderMinDate: one_year_ago.toISOString(),
    orderMaxDate: today.toISOString(),
    orderStatus: ["RECEIVED", "FINALIZED"],
    leasers: "",
  };
  const [filters, setFilters] = usePersistedState("invoice_filter", {
    ...initial_filters,
  });

  const getSortingIcon = (columnName) => {
    if (sortingRule.column === columnName) {
      return faChevronRight;
    }

    return sortingRule.direction ? faChevronDown : faChevronUp;
  };

  const iconDate = getSortingIcon("rent");
  const iconRent = getSortingIcon("date");

  const getInvoices = async () => {
    setIsLoading(true);
    let endpointQuery = "";
    const params = {
      status_exclude: ["FAILED"],
    };

    // Copy non-empty state fiters to params
    for (const f of Object.keys(filters)) {
      if (filters[f]) {
        params[f] = filters[f];
      }
    }

    if (Object.keys(params).length) {
      endpointQuery = `${endpointQuery}?${qs.stringify(params)}`;
    }
    const fetchedOrders = await adminUser.api.getOrders(endpointQuery);

    setInvoices(fetchedOrders.data);
    setIsLoading(false);
  };

  // filter by Leaser
  const selectLeaser = (leaser) => {
    setFilters({
      ...filters,
      leasers: leaser,
    });
  };

  // total invoices.net_revenue
  const total = invoices.reduce((acc, d) => parseFloat(acc) + (d.rent_currency === "GBP" && filters.leasers === ""
    ? convertPrice(d.net_revenue, "GBP", "EUR")
    : parseFloat(d.net_revenue)), 0);

  // Pre Order by Date °°° Sort by Date or by Net revenue
  const handleSortClick = (column) => {
    setSortingRule({
      column,
      direction: !sortingRule.direction,
    });
  };

  const sortTable = (a, b) => {
    const a_rent = a.net_revenue ? a.net_revenue : ((a.rent * 100) / a.leaser_rate).toFixed(2);
    const b_rent = b.net_revenue ? b.net_revenue : ((b.rent * 100) / b.leaser_rate).toFixed(2);
    const a_date = a.billing_date ? a.billing_date : "-";
    const b_date = b.billing_date ? b.billing_date : "-";

    if (sortingRule.column === "date") {
      return sortingRule.direction
        ? a_date.localeCompare(b_date)
        : b_date.localeCompare(a_date);
    }

    if (sortingRule.column === "rent") {
      return sortingRule.direction
        ? a_rent.localeCompare(b_rent, undefined, { numeric: true })
        : b_rent.localeCompare(a_rent, undefined, { numeric: true });
    }

    return 0;
  };

  const ordersToDisplay = invoices.sort(sortTable);

  // Mass Selected
  const sendPackage = async () => {
    if (!massSelectedOrderlist.length) return;

    Promise.all(massSelectedOrderlist.map((props) => (
      adminUser.api.sendLeaserPackage(props.id, props.yousign_procedures[0].procedure_id)
    )));
    setMassSelectedOrderlist([]);
    setIsWarningOpen(false);
  };

  const handleClickMassSelected = () => {
    setMassSelectedEnabled(!massSelectedEnabled);
    if (isWarningOpen) {
      setIsWarningOpen(false);
    }
  };

  const selectForMassEdit = (d) => {
    const copy = [...massSelectedOrderlist];
    const copyLeaser = [...leaserSelectedList];
    const idx = massSelectedOrderlist.indexOf(d);

    if (idx === -1) {
      copy.push(d);
      if (!copyLeaser.includes(d.leaser)) {
        copyLeaser.push(d.leaser);
      }
    } else {
      copy.splice(idx, 1);
      copyLeaser.splice(idx, 1);
    }
    setMassSelectedOrderlist(copy);
    setLeaserSelectedList(copyLeaser);
  };

  const handleAllSelect = () => {
    if (allSelected) {
      setMassSelectedOrderlist([]);
    } else {
      setMassSelectedOrderlist(invoices);
    }
    setAllSelected(!allSelected);
  };

  const getCurrency = () => {
    const gbLeasers = leasersList.filter((l) => l.country === "gb").map((l) => l.name);

    return gbLeasers.includes(filters.leasers) ? "GBP" : "EUR";
  };

  useEffect(() => {
    getInvoices();
  }, [filters]);

  return (
    <Layout direction="column">
      {isLoading && <LoadingSpinner isFullScreen={false} />}
      {!isLoading && (
        <Layout direction="column" spacing={2}>
          <Layout direction="row" justifyContent="space-between">
            <Heading variant="h2">Contract Signing</Heading>

            <div className={styles.btnSection}>
              <Button variant="outlined" color="secondary" label="Mass Select" onClick={() => handleClickMassSelected()} />
            </div>
          </Layout>
          <div className={massSelectedEnabled ? styles.massEditDiv : styles.hidden}>
            <Checkbox label="All Selected" onChange={handleAllSelect} checked={allSelected} />

            <Button
              label="Send Leaser Package"
              color="secondary"
              variant="text"
              startIcon={faSend}
              onClick={() => setIsWarningOpen(true)}
            />
          </div>
          {isWarningOpen && (
            <ActionConfirmation
              confirm={sendPackage}
              refuse={() => setIsWarningOpen(false)}
              message={`You're about to send ${massSelectedOrderlist.length} e-mails to Leaser ${leaserSelectedList.toString()}. Are you sure you want to continue ?`}
              clear={() => setIsWarningOpen(false)}
            />
          )}

          <Tabs
            fullWidth
            scrollButtons
            variant="scrollable"
            value={filters.leasers}
            onChange={(e, value) => selectLeaser(value)}
          >
            <TabItem value="" label="All" />
            {leasersList.map((leaser) => <TabItem key={leaser.name} value={leaser.name} label={leaser.label} />)}
          </Tabs>

          <table className="table back-office-table">
            <thead>
              <tr>
                <td>Leaser</td>
                <td>Request ID</td>
                <td>Order ID</td>
                <td>Company</td>
                <td>
                  <span className={styles.pointer} onClick={() => handleSortClick("date")}>
                    Invoicing date
                    {" "}
                    <Icon icon={iconDate} color="secondary" size="S" />
                  </span>
                </td>
                <td>Order status</td>
                <td>Invoice number</td>
                <td>
                  <span className={styles.pointer} onClick={() => handleSortClick("rent")}>
                    Net revenue
                    {" "}
                    <Icon icon={iconRent} color="secondary" size="S" />
                  </span>
                </td>
                <td>Yousign%</td>
                <td style={{ width: "5%" }}>Documents</td>
                {!massSelectedEnabled && <td>Actions</td>}
              </tr>
            </thead>
            <tbody>
              {ordersToDisplay.map((i) => (
                <ContractSigningRow
                  invoice={i}
                  key={i.id}
                  reload={getInvoices}
                  onClick={() => (massSelectedEnabled && selectForMassEdit(i))}
                  isMassEditing={massSelectedEnabled}
                  isSelected={massSelectedOrderlist.includes(i)}
                />
              ))}
            </tbody>
          </table>
          <div className={styles.totalZone}>
            Total :
            {" "}
            {formatMoney(total, getCurrency())}
          </div>
        </Layout>
      )}
    </Layout>
  );
};

export default ContractSigning;
