import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button, Layout,
} from "@fleet.co/tarmac";

const SideIcon = (props) => {
  const location = useLocation();
  const activeLinkBool = location.pathname === "/"
    ? props.link === process.env.REACT_APP_ADMIN_DEFAULT_PAGE
    : location.pathname.startsWith(props.link);

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Layout
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      justifyContent="center"
      sx={{ margin: isHovered ? "0 0 0 2px" : "0" }}
    >
      <Link to={props.link}>
        <Button
          startIcon={props.icon}
          color={activeLinkBool && !isHovered ? "primary" : "secondary"}
          label={isHovered ? props.page : ""}
          variant={isHovered ? "contained" : "text"}
          size="large"
          sx={{ width: "max-content" }}
        />
      </Link>
    </Layout>
  );
};

export default SideIcon;
