import React, { useContext, useEffect, useState } from "react";
import {
  DatePicker, Layout, Link, Text,
} from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";

const Zendesk = () => {
  const { user: adminUser } = useContext(UserContext);
  const [ticket, setTicket] = useState(null);

  const loadTicket = async (zendesk_ticket_id) => {
    const fetchedTicket = await adminUser.api.getTicket(zendesk_ticket_id);

    setTicket(fetchedTicket.data);
  };

  const editTicket = async (field, data) => {
    await adminUser.api.modifyTicket(ticket.consistent_id, { [field]: data });
    loadTicket(ticket.zendesk_id);
  };

  useEffect(() => {
    const client = window.ZAFClient.init();

    client.get("ticket")
      .then((data) => {
        loadTicket(data.ticket.id);
      });
  }, []);

  return (
    ticket && (
      <>
        {/* {ticket.id} < br />
      {ticket.zendesk_id}<br />
      {ticket.content}<br />
      {ticket.order && <>
        Order Number : {ticket.order.order_number}<br />
      </>}
      {ticket.devices.map(d => {
        return <>{d.id} - {d.name}<br /></>;
      }
      )} */}
        <Layout direction="row" spacing={1}>
          <Text variant="body1">Pickup Date</Text>
          <Link label="Delete" onClick={() => editTicket("pickup_date", null)} />
        </Layout>
        <DatePicker
          label="Pickup Date"
          name="startingDate"
          onChange={(date) => editTicket("pickup_date", date)}
          value={ticket.pickup_date ? new Date(ticket.pickup_date) : null}
        />

      </>
    )
  );
};

export default Zendesk;
