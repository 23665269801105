import React, { useContext, useEffect, useState } from "react";
import {
  Button, Dialog, Heading, Layout,
} from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import LoadingSpinner from "../common/LoadingSpinner";
import usePersistedState from "../../hooks/usePersistedState";

import styles from "./FundingEnvelopes.module.scss";
import EnvelopeFilterMenu from "../blocks/EnvelopeFilterMenu";
import EnvelopeModal from "../blocks/EnvelopeModal";
import EnvelopeRow from "../tables/envelopes/EnvelopeRow";
import { getDaysLeft } from "../../tools/DateUtils";
import { leasersList } from "../../data/leasersList";

const initialEnvelopeFilters = {
  leasers: [],
  statuses: ["ACCEPTED", "TODO", "FUNDING_MORE_DOCUMENTS", "FUNDING_REQUESTED"],
  filter: "",
  lessThan15K: false,
  endingIn1Month: false,
};

const statuses = [
  { value: "FUNDING_REQUESTED", label: "FUNDING REQUESTED" },
  { value: "FUNDING_MORE_DOCUMENTS", label: "FUNDING MORE DOCUMENTS" },
  { value: "TODO", label: "TODO" },
  { value: "CLOSED", label: "CLOSED" },
  { value: "ACCEPTED", label: "ACCEPTED" },
  { value: "REJECTED", label: "REJECTED" },
];

const FundingEnvelopes = () => {
  const { user: adminUser } = useContext(UserContext);

  const leasers = leasersList.map((l) => ({ value: l.name, label: l.label }));

  const [allEnvelopes, setAllEnvelopes] = useState([]);
  const [currentEnvelopes, setCurrentEnvelopes] = useState([]);
  const [specificEnvelope, setSpecificEnvelope] = useState(null);
  const [isEnvelopeModalOpen, setIsEnvelopeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = usePersistedState("envelopes_filter", { ...initialEnvelopeFilters });
  const [confirmCloseFunding, setConfirmCloseFunding] = useState(false);

  const onSaveChanges = () => {
    setIsEnvelopeModalOpen(false);
    getAllEnvelopes();
  };

  const resetFilters = () => {
    setFilters(initialEnvelopeFilters);
  };

  const closeEnvelope = async () => {
    await adminUser.api.modifyEnvelope(specificEnvelope.id, { status: "CLOSED" });
    setConfirmCloseFunding(false);
    onSaveChanges();
  };

  const getAllEnvelopes = async () => {
    setIsLoading(true);

    const fetchedEnvelopes = await adminUser.api.getEnvelopes();

    const status_order = {
      ACCEPTED: 0,
      TODO: 1,
      FUNDING_MORE_DOCUMENTS: 2,
      FUNDING_REQUESTED: 3,
      REJECTED: 4,
      CLOSED: 5,
    };
    const sortedEnvelopes = fetchedEnvelopes.data.sort((a, b) => status_order[a.status] - status_order[b.status]);

    setAllEnvelopes(sortedEnvelopes);
    setIsLoading(false);
  };

  const openEditEnvelope = (envelopeToEdit) => {
    setSpecificEnvelope(envelopeToEdit);
    setIsEnvelopeModalOpen(true);
  };

  const openAddNewFunding = () => {
    setSpecificEnvelope(null);
    setIsEnvelopeModalOpen(true);
  };

  const applyFilters = () => {
    let filteredEnvelopes = allEnvelopes;

    // filter less than 15K envelopes
    if (filters.lessThan15K) {
      filteredEnvelopes = filteredEnvelopes.filter((e) => e.current_amount < 15000);
    }

    // filter envelopes ending in one month
    if (filters.endingIn1Month) {
      filteredEnvelopes = filteredEnvelopes.filter((e) => getDaysLeft(new Date(), new Date(e.end_date)) <= 1);
    }

    // filter based on leaser
    if (filters.leasers && filters.leasers.length) {
      filteredEnvelopes = filteredEnvelopes.filter((e) => filters.leasers.includes(e.leaser));
    }

    // filter based on status
    if (filters.statuses && filters.statuses.length) {
      filteredEnvelopes = filteredEnvelopes.filter((e) => filters.statuses.includes(e.status));
    }
    setCurrentEnvelopes(filteredEnvelopes);
  };

  // Premier chargement
  useEffect(() => {
    getAllEnvelopes();
  }, []);

  useEffect(applyFilters, [filters, allEnvelopes]);

  const ActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={() => setConfirmCloseFunding(!confirmCloseFunding)} />
      <Button color="primary" label="Yes" onClick={closeEnvelope} />
    </>
  );

  if (isLoading) {
    return <LoadingSpinner isFullScreen={false} />;
  }

  return (
    <Layout direction="column">
      <Layout direction="row" justifyContent="space-between">
        <Heading variant="h2">
          Funding envelopes
        </Heading>

        <Layout direction="row" spacing={1}>
          <EnvelopeFilterMenu
            statuses={statuses}
            leasers={leasers}
            resetFilters={resetFilters}
            filters={filters}
            setFilters={setFilters}
          />
          <Button
            variant="contained"
            color="primary"
            label="Add new funding"
            onClick={() => openAddNewFunding()}
          />
        </Layout>
      </Layout>

      <div style={{ padding: "1em 0" }}>
        <table className="table back-office-table">
          <thead>
            <tr>
              <td style={{ width: "10%", textAlign: "left" }}>Company</td>
              <td style={{ width: "10%", textAlign: "center" }}>Status</td>
              <td style={{ width: "10%", textAlign: "center" }}>Leasers</td>
              <td style={{ width: "10%", textAlign: "center" }}>Amount left</td>
              <td style={{ width: "10%", textAlign: "center" }}>Amount spent</td>
              <td style={{ width: "10%", textAlign: "center" }}>Amount granted</td>
              <td style={{ width: "10%", textAlign: "center" }}>Time left</td>
              <td style={{ width: "10%", textAlign: "center" }}>Request ID</td>
              <td style={{ width: "11%", textAlign: "right" }}>Actions</td>
            </tr>
          </thead>
          <tbody />
        </table>
        {currentEnvelopes.map((envelope, index) => (
          <EnvelopeRow
            envelope={envelope}
            reloadEnvelopes={getAllEnvelopes}
            key={index}
            index={index}
            openEditEnvelope={openEditEnvelope}
          />
        ))}
      </div>

      <EnvelopeModal
        statuses={statuses}
        open={isEnvelopeModalOpen}
        closeAction={() => setIsEnvelopeModalOpen(false)}
        onSaveChanges={onSaveChanges}
        envelope={specificEnvelope}
        closeEnvelope={setConfirmCloseFunding}
      />

      <Dialog
        title="Close funding"
        onClose={() => setConfirmCloseFunding(!confirmCloseFunding)}
        Actions={ActionComponent}
        open={confirmCloseFunding}
      >
        Are you sure
        {" "}
        <span className={styles.highLightedText}>this funding is closed?</span>
        {" "}
        This action will permantly lock
        this funding and you won’t be able to use this amount for new orders.
      </Dialog>
      <p className={styles.totalEnvelopes}>
        {currentEnvelopes.length > 1 ? `${currentEnvelopes.length} fundings` : `${currentEnvelopes.length} funding`}
      </p>
    </Layout>
  );
};

export default FundingEnvelopes;
