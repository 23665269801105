import React from "react";
import {
  Button, Checkbox, DatePicker, Layout, Popover, Select, Text,
} from "@fleet.co/tarmac";
import { faBan } from "@fortawesome/pro-regular-svg-icons";

const PenaltyFilterMenu = (props) => {
  const {
    filters, applyFilters, excludeNoPenalty, setExcludeNoPenalty,
  } = props;

  const statusOption = [
    { label: "REQUESTED", value: "REQUESTED" },
    { label: "MISSING", value: "MISSING" },
    { label: "PAID", value: "PAID" },
    { label: "NO PENALTY", value: "NO_PENALTY" },
  ];

  const selectedContractMinDate = filters.minDate ? new Date(filters.minDate) : null;
  const selectedContractMaxDate = filters.maxDate ? new Date(filters.maxDate) : null;

  const resetFilters = () => {
    props.resetFilters();
    setExcludeNoPenalty(true);
  };

  const handleSelectStatus = (value) => {
    const currentFilters = { ...filters };

    currentFilters.status = value;
    applyFilters(currentFilters);
  };

  return (
    <Popover
      AnchorComponent={Button}
      anchorProps={{
        variant: "outlined",
        color: "primary",
        label: "Filters",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Layout direction="column" spacing={2}>
        <Checkbox
          label="Hide NO PENALTY devices"
          checked={excludeNoPenalty}
          onChange={() => setExcludeNoPenalty(!excludeNoPenalty)}
        />

        <Select
          label="Only display status :"
          options={statusOption}
          value={filters.status || ""}
          onChange={(value) => handleSelectStatus(value)}
        />
        <Text variant="body1"> Select contract end date range</Text>
        <Layout direction="row" spacing={2}>
          <DatePicker
            label="From"
            name="minDate"
            onChange={(date) => applyFilters({ ...filters, minDate: date })}
            value={selectedContractMinDate}
          />
          <DatePicker
            label="To"
            name="maxDate"
            onChange={(date) => applyFilters({ ...filters, maxDate: date })}
            value={selectedContractMaxDate}
          />
        </Layout>
        <Layout direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={resetFilters}
            label="Reset all filters"
            startIcon={faBan}
          />
        </Layout>
      </Layout>
    </Popover>
  );
};

export default PenaltyFilterMenu;
