import React, { useContext, useEffect, useState } from "react";
import {
  Button, Checkbox, Dialog, Icon,
} from "@fleet.co/tarmac";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import DeviceCompanyRow from "./DeviceCompanyRow";
import UserContext from "../../../tools/UserContext";
import styles from "./DeviceTable.module.scss";
import EditDeviceModal from "../../blocks/EditDeviceModal";
import MassEditModal from "../../blocks/MassEditModal";

const DeviceTable = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const [massEditDevicelist, setMassEditDevicelist] = useState([]);
  const [openMassEditModal, setOpenMassEditModal] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [currentDeviceEditing, setCurrentDeviceEditing] = useState(null);

  const confirmDelete = () => {
    const promiseArray = massEditDevicelist.map((e) => deleteDevice(e));

    Promise.all(promiseArray)
      .then(() => {
        setConfirmDeleteModal(false);
        setMassEditDevicelist([]);
        props.reloadPage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteDevice = async (d) => {
    await adminUser.api.deleteDevice(d.id);
  };
  const handleMassEditModal = () => {
    if (massEditDevicelist.length > 0 || allSelected) {
      setOpenMassEditModal(!openMassEditModal);
    }
  };
  const handleDeletDevices = () => {
    if (massEditDevicelist.length > 0 || allSelected) {
      setConfirmDeleteModal(true);
    }
  };
  const handleAllSelect = () => {
    if (allSelected) {
      setMassEditDevicelist([]);
    } else {
      setMassEditDevicelist(props.devices);
    }
    setAllSelected(!allSelected);
  };

  const tableCount = () => (props.devices.length > 1 ? `${props.devices.length} devices found` : `${props.devices.length} device found`);

  const onCloseMassEdit = () => {
    setOpenMassEditModal(false);
    setMassEditDevicelist([]);
  };

  const selectForMassEdit = (d) => {
    const copy = [...massEditDevicelist];
    const idx = massEditDevicelist.indexOf(d);

    if (idx === -1) {
      copy.push(d);
    } else {
      copy.splice(idx, 1);
    }
    setMassEditDevicelist(copy);
  };

  const openEditModal = (device) => {
    setCurrentDeviceEditing(device);
  };

  const normalizeDevice = (d) => {
    if (!d.order && props.order) {
      return { ...d, order: props.order };
    }

    return { ...d };
  };

  useEffect(() => {
    setMassEditDevicelist([]);
    setAllSelected(false);
  }, [props.isMassEditEnabled]);

  const ActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={() => setConfirmDeleteModal(false)} />
      <Button color="primary" label="Yes" onClick={confirmDelete} />
    </>
  );

  return (
    <>
      <div className={props.isMassEditEnabled ? styles.massEditDiv : styles.hidden}>
        <div className={styles.massEditCta}>
          <Checkbox label="All Selected" onChange={handleAllSelect} checked={allSelected} />
        </div>
        <div className={styles.massEditCta} onClick={handleMassEditModal}>
          <Icon icon={faPen} color="secondary" size="S" />
          <p> Open edit options</p>
        </div>
        <div className={styles.massEditCta} onClick={handleDeletDevices}>
          <Icon icon={faTrash} color="secondary" size="S" />
          <p> Delete devices</p>
        </div>
      </div>
      <div className={styles.titleRow}>
        <div className={styles.row}>
          <div className={styles.productCell}>Product</div>
          <div className={styles.statusCell}>Shipping Status</div>
          <div className={styles.statusCell}>End of Life</div>
          <div className={styles.statusCell}>Return Status</div>
          <div className={styles.rentCell}>Rent</div>
          <div className={styles.assignementCell}>Assignement</div>
          <div className={styles.providerCell}>Provider</div>
          <div className={styles.serialCell}>Serial</div>
          <div className={styles.serialCell}>Tracking number</div>
          {!props.isMassEditEnabled && <div className={styles.actionsCell}>Actions</div>}
        </div>
      </div>
      {props.devices.map((d, idx) => (
        <DeviceCompanyRow
          device={d}
          key={idx}
          idx={idx}
          deleteDevice={deleteDevice}
          reloadOrder={props.reloadOrder}
          onClick={() => (props.isMassEditEnabled ? selectForMassEdit(d) : openEditModal(normalizeDevice(d)))}
          isMassEditing={props.isMassEditEnabled}
          isSelected={massEditDevicelist.includes(d) || currentDeviceEditing?.id === d?.id}
        />
      ))}

      <EditDeviceModal
        open={Boolean(currentDeviceEditing)}
        reloadPage={props.reloadPage}
        device={currentDeviceEditing}
        onClose={() => setCurrentDeviceEditing(false)}
      />

      <MassEditModal
        open={props.isMassEditEnabled && openMassEditModal}
        reloadPage={props.reloadPage}
        onClose={onCloseMassEdit}
        devices={massEditDevicelist}
      />

      <Dialog
        title="Delete selected devices"
        onClose={() => setConfirmDeleteModal(false)}
        Actions={ActionComponent}
        open={confirmDeleteModal}
      >
        This action will permanently delete
        {" "}
        {massEditDevicelist.length}
        {" "}
        devices. Would you like to continue ?
      </Dialog>

      <p className="table-count">
        {" "}
        {tableCount()}
      </p>
    </>
  );
};

export default DeviceTable;
