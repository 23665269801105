import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../tools/UserContext";
import OrderFileRow from "../../tables/order/OrderFileRow";
import FileUploadModal from "../../blocks/FileUploadModal";
import { downloadFileBlob } from "../../../helpers/FilesHelpers";

const OrderFiles = (props) => {
  const {
    order, reloadOrder, showFileUpload, closeUpload,
  } = props;
  const { user: adminUser } = useContext(UserContext);

  const [files, setFiles] = useState([]);

  const uploadFileTypes = [
    {
      label: "Leasing - Contract",
      value: "CONTRACT",
    },
    {
      label: "Leasing - Delivery (Fleet)",
      value: "DELIVERY_RECEIPT",
    },
    {
      label: "Leasing - Delivery (Client)",
      value: "SHIPPING_NOTICE",
    },
    {
      label: "Leasing - SEPA mandate",
      value: "SEPA_MANDATE",
    },
    {
      label: "Insurance - Certificate",
      value: "FLEET_INSURANCE",
    },
    {
      label: "Insurance - Invoice",
      value: "FLEET_INSURANCE_INVOICE",
    },
  ];

  const num_files = files.length > 1 ? `${files.length} files found` : `${files.length} file found`;

  const getFiles = async () => {
    if (!order || !order.id) {
      return;
    }
    const fetchedFiles = await adminUser.api.getOrderFiles(order.id);
    const orderDocTypes = uploadFileTypes.map((f) => f.value);
    const filesToDisplay = fetchedFiles.data.filter((f) => orderDocTypes.includes(f?.type));

    setFiles(filesToDisplay);
  };

  const openFile = async (file) => {
    const response = await adminUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const deleteFile = async (file_id) => {
    await adminUser.api.deleteFile(file_id);
    reloadOrder();
  };

  const onSaveModal = async (fileData) => {
    fileData.order_id = order.id;
    fileData.company_id = order.company_id;

    const formData = new FormData();

    for (const [key, value] of Object.entries(fileData)) {
      if (value !== null) {
        formData.append(key, value);
      }
    }

    await adminUser.api.addFile(formData);
    closeUpload();
    reloadOrder();
  };

  const onCloseModal = () => {
    closeUpload();
    reloadOrder();
  };

  useEffect(() => {
    getFiles();
  }, [order]);

  return (
    <>
      <table className="back-office-table table">
        <thead>
          <tr>
            <td style={{ width: "25%" }}>File Type</td>
            <td style={{ width: "50%" }}>File Name</td>
            <td style={{ width: "25%" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {files.map((f) => (
            <OrderFileRow
              file={f}
              key={`order-file-${f.id}`}
              deleteAction={() => deleteFile(f.id)}
              reload={getFiles}
              company={order.company}
              order={order}
              openFile={() => openFile(f)}
            />
          ))}
        </tbody>
      </table>

      <p className="table-count">
        {" "}
        {num_files}
      </p>
      <FileUploadModal
        onClose={onCloseModal}
        onSave={onSaveModal}
        companyId={order.company_id}
        uploadFileTypes={uploadFileTypes}
        open={showFileUpload}
      />
    </>
  );
};

export default OrderFiles;
