import React, { useContext, useState } from "react";
import {
  Button, Dialog, Layout, Select, Tag, Text,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

const DeviceReturnStatusTag = (props) => {
  const {
    parcel, isEditable, setDeviceInfo, deviceInfo, reloadOrders,
  } = props;
  const [statusUpdateModal, setStatusUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user: adminUser } = useContext(UserContext);

  const handleClick = (type) => {
    setDeviceInfo({
      ...deviceInfo,
      activeReturn: {
        ...deviceInfo.activeReturn, status: type,
      },
    });
    setStatusUpdateModal(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await adminUser.api.modifyDevice(deviceInfo.id, deviceInfo);
    setStatusUpdateModal(false);
    setIsLoading(false);
    reloadOrders();
  };

  const options = [
    { label: "WAITING", value: "WAITING" },
    { label: "SCHEDULED", value: "SCHEDULED" },
    { label: "SHIPPED", value: "SHIPPED" },
    { label: "RECEIVED", value: "RECEIVED" },
    { label: "FAILED", value: "FAILED" },
  ];
  const getColorByStatus = () => {
    let color;

    switch (parcel?.status) {
      case "SCHEDULED":
        color = "cyan";
        break;
      case "SHIPPED":
        color = "blue";
        break;
      case "RECEIVED":
        color = "green";
        break;
      default:
        color = "grey";
        break;
    }

    return color;
  };

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setStatusUpdateModal(false)} />
      <Button color="primary" label={isLoading ? "Loading" : "Confirmation"} onClick={handleSubmit} />
    </>
  );

  return (
    <Layout direction="column">
      {isEditable && parcel
        ? (
          <Select
            value={deviceInfo.activeReturn?.status || "WAITING"}
            options={options}
            onChange={(value) => handleClick(value)}
          />
        )
        : <Tag variant="filled" label={parcel?.status || "WAITING"} backgroundColor={getColorByStatus()} />}
      <Dialog
        title="Warning"
        onClose={() => setStatusUpdateModal(false)}
        Actions={ActionComponent}
        open={statusUpdateModal}
      >
        <Text variant="body1">You are about to change the Parcel status of a device</Text>
        <br />
        <br />
        <Layout direction="row" spacing={2}>
          <Layout direction="column" spacing={2}>
            <Text variant="body1">Device :</Text>
            <Text variant="body1">S/N :</Text>
            <Text variant="body1">New Parcel Status:</Text>
          </Layout>
          <Layout direction="column" spacing={2} alignItems="center">
            <Text variant="body1">{deviceInfo.name}</Text>
            <Text variant="body1">{deviceInfo.serial_number}</Text>
            <Text variant="danger">{deviceInfo.activeReturn?.status}</Text>
          </Layout>
        </Layout>
      </Dialog>
    </Layout>
  );
};

export default DeviceReturnStatusTag;
