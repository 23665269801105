const notifications = [
  {
    value: "SUPPORT",
    title: "Suivi des tickets",
    description: "Simplifiez le suivi de vos tickets en incluant une personne en copie.",
  },
  {
    value: "ORDER",
    title: "Commandes et livraison",
    description: "La personne à contacter pour toute question concernant vos commandes et livraisons Fleet.",
  },
  {
    value: "FINANCE",
    title: "Contact finance",
    description: "La personne de contact pour toute question ou demande liée aux sujets financiers de votre entreprise.",
  },
];

export default notifications;
