import React, { useCallback, useState } from "react";
import {
  faCaretDown, faCaretUp, faChevronDown, faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Checkbox, Icon, IconButton, Layout, Text,
} from "@fleet.co/tarmac";
import DeviceReturnRow from "./DeviceReturnRow";
import styles from "./ContractEndingCSM.module.scss";

const ContractReturnRow = (props) => {
  const {
    order, isMassSelectEnabled, selectForMassEdit, isSelected, reloadOrders,
  } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dateSorting, setDateSorting] = useState("asc");
  const [receptionDateSorting, setReceptionDateSorting] = useState("asc");
  const [fetchedDevices, setFetchedDevices] = useState([...order.devices]);

  const getEditedLabel = useCallback(() => {
    const nbLabel = order.devices.filter((d) => d.activeReturn?.label_url).length;

    return `${nbLabel}/${order.devices.length}`;
  }, [order.devices]);

  const getSentDevices = useCallback(() => {
    const nbSent = order.devices.filter((d) => {
      const { activeReturn } = d;

      return ["SHIPPED", "RECEIVED"].includes(activeReturn?.status);
    }).length;

    return `${nbSent}/${order.devices.length}`;
  }, [order.devices]);

  const getLatePickupNumber = useCallback(() => {
    const nbFailed = order.devices.filter((d) => {
      const { activeReturn } = d;

      return new Date(activeReturn?.pickup_date) < new Date() && activeReturn?.status === "SCHEDULED";
    }).length;

    return nbFailed;
  }, [order.devices]);

  const handleSort = (type, value) => {
    let sortedDevices = [];

    const compareDates = (keyA, keyB) => {
      if (value === "asc") {
        if (keyA < keyB) return -1;

        if (keyA > keyB) return 1;
      } else {
        if (keyA < keyB) return 1;

        if (keyA > keyB) return -1;
      }

      return 0;
    };

    if (type === "reception_date") {
      sortedDevices = order.devices.sort((a, b) => compareDates(new Date(a.activeReturn?.reception_date), new Date(b.activeReturn?.reception_date)));
      setReceptionDateSorting(value);
    } else if (type === "pickup_date") {
      sortedDevices = order.devices.sort((a, b) => compareDates(new Date(a.activeReturn?.pickup_date), new Date(b.activeReturn?.pickup_date)));
      setDateSorting(value);
    }
    setFetchedDevices(sortedDevices);
  };

  const nbLatePickup = getLatePickupNumber();

  return order && (
    <Layout
      className={`${styles.container} ${isMenuOpen ? styles.containerOpen : null}`}
      onClick={selectForMassEdit}
      key={order.id}
    >
      <Layout direction="row" fullWidth className={styles.contractRow}>
        <div className={styles.littleCell}>{isMassSelectEnabled && <Checkbox checked={isSelected} onChange={selectForMassEdit} />}</div>
        <div>{order.contract_number || order.request_number}</div>
        <div>{new Date(order.contract_start_date).toLocaleDateString("fr")}</div>
        <div>{new Date(order.contract_end_date).toLocaleDateString("fr")}</div>
        <div>{order.company.name}</div>
        <div>{getEditedLabel()}</div>
        <div>{getSentDevices()}</div>
        <div><Text variant={nbLatePickup > 0 ? "danger" : ""}>{nbLatePickup}</Text></div>
        <div className={styles.openButton}>
          <IconButton
            variant="contained"
            color={isMenuOpen ? "primary" : "secondary"}
            icon={<Icon icon={isMenuOpen ? faCaretUp : faCaretDown} color="secondary" size="S" />}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>
      </Layout>

      {isMenuOpen && (
        <Layout direction="column">
          <Layout direction="row" fullWidth className={styles.tableHeadDevices}>
            <div className={styles.bigCell}>Name / Serial Number</div>
            <div>Renewal type</div>
            <div>Device EOL status</div>
            <Layout direction="row" spacing={1}>
              Exp. pickup date
              <Icon
                onClick={() => (dateSorting === "desc" ? handleSort("pickup_date", "asc") : handleSort("pickup_date", "desc"))}
                icon={dateSorting === "desc" ? faChevronDown : faChevronUp}
                color="secondary"
                size="S"
              />
            </Layout>
            <div>
              Exp. reception date
              <Icon
                onClick={() => (receptionDateSorting === "desc" ? handleSort("reception_date", "asc") : handleSort("reception_date", "desc"))}
                icon={receptionDateSorting === "desc" ? faChevronDown : faChevronUp}
                color="secondary"
                size="S"
              />
            </div>
            <div>Carrier</div>
            <div>Tracking</div>
            <div>Parcel Status</div>
            <div>Recipient</div>
            <div>Actions</div>
          </Layout>
          {fetchedDevices.map((device) => (
            <DeviceReturnRow
              device={device}
              reloadOrders={reloadOrders}
            />
          ))}
        </Layout>
      )}
    </Layout>
  );
};

export default ContractReturnRow;
