import React from "react";
import { Icon, Layout, Text } from "@fleet.co/tarmac";
import { getLeaserLabel } from "../../../tools/OrderTools";
import leaserStatuses from "../../../data/leaserStatuses";

const OrderLeaserInfoCell = ({ order, leaser }) => {
  const matchingRequest = order.leaser_requests.find((l) => l.leaser === leaser);
  const status = leaserStatuses.find((ls) => ls.value === matchingRequest?.status);

  return (
    <Layout direction="column" spacing={1}>
      <Text variant="body1">{(order.is_AFS && leaser === "BNP") ? "FR_BNP x AFS" : getLeaserLabel(leaser)}</Text>
      {matchingRequest && status && (
        <Text variant="body2" color={status.color}>
          <Icon icon={status.icon} color={status.color} />
          {" "}
          {status.label}
        </Text>
      )}
    </Layout>
  );
};

export default OrderLeaserInfoCell;
