export const deviceStatus = [
  {
    label: "Ready for Purchase",
    value: "READY_FOR_PURCHASE",
  },
  {
    label: "Purchased",
    value: "PURCHASED",
  },
  {
    label: "Shipped",
    value: "SHIPPED",
  },
  {
    label: "Delivered",
    value: "DELIVERED",
  },
  {
    label: "BDL Sent",
    value: "BDL_SENT",
  },
  {
    label: "Received",
    value: "RECEIVED",
  },
];
