import React from "react";
import {
  Button, Layout, Link, Text,
} from "@fleet.co/tarmac";
import styles from "./PunctualOffersRow.module.scss";
import CopyButton from "../../../tools/CopyButton";

const startedOrderStatus = ["LEAD_AGREEMENT", "LEASER_MORE_DOCUMENTS", "LEAD_SIGNING", "READY_FOR_PURCHASE", "PURCHASED", "SHIPPED", "DELIVERED", "BDL_SENT", "RECEIVED"];

const completedOrderStatus = ["FINALIZED", "CLOSED", "INACTIVE"];

const PunctualOffersRow = ({ offer, linkLanguage, openModal }) => {
  // CHECK ORDER STATUS IN ORDER TO DISPLAY THE STATS
  const startedOrders = offer.orders.filter((order) => startedOrderStatus.includes(order.status));

  const completedOrders = offer.orders.filter((order) => completedOrderStatus.includes(order.status));
  const offerLink = `${process.env.REACT_APP_WEBSITEURL}/${linkLanguage}p/${offer.code}`;

  return (
    <Layout direction="row" className={styles.container} alignItems="center" padding={2}>
      <Text style={{ width: "12%" }} textAlign="center">{offer.name}</Text>
      <Text style={{ width: "10%" }} textAlign="center">{offer?.validity_start ? new Date(offer.validity_start).toLocaleDateString("fr") : "-"}</Text>
      <Text style={{ width: "10%" }} textAlign="center">{offer?.validity_end ? new Date(offer.validity_end).toLocaleDateString("fr") : "-"}</Text>
      <Text style={{ width: "10%" }} textAlign="center">{`${offer.source[0].toUpperCase()}${offer.source.slice(1).toLowerCase()}`}</Text>
      <Layout direction="row" style={{ width: "35%" }} justifyContent="center">
        <Link label={offerLink} href={offerLink} />
        <CopyButton value={offerLink} />
      </Layout>
      <Text style={{ width: "8%" }} textAlign="center">{startedOrders.length || "-"}</Text>
      <Text style={{ width: "8%" }} textAlign="center">{completedOrders.length || "-"}</Text>
      <Button variant="contained" color="primary" label="Edit" style={{ width: "8%" }} onClick={() => openModal(offer)} />
    </Layout>
  );
};

export default PunctualOffersRow;
