import React from "react";
import {
  Tag,
} from "@fleet.co/tarmac";

const StatusCell = ({ row: device }) => {
  const { status } = device;

  if (!status) {
    return null;
  }

  const statusNiceName = {
    READY_FOR_PURCHASE: "Ready for Purchase",
    PURCHASED: "Purchased",
    SHIPPED: "Shipped",
    DELIVERED: "Delivered",
    BDL_SENT: "BDL Sent",
    RECEIVED: "Received",
  };

  const statusColors = {
    READY_FOR_PURCHASE: "beige",
    PURCHASED: "cyan",
    SHIPPED: "blue",
    DELIVERED: "purple",
    BDL_SENT: "pink",
    RECEIVED: "green",
  };

  return (
    <Tag
      variant="filled"
      label={statusNiceName[status]}
      backgroundColor={statusColors[status] || "grey"}
      size="small"
    />
  );
};

export default StatusCell;
