import React, {
  useContext, useEffect, useMemo, useState,
} from "react";
import { Checkbox } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import CompanyUserRow from "../../tables/company/CompanyUserRow";
import ChangeCompanyModal from "../../blocks/ChangeCompanyModal";
import AddUserRow from "../../tables/company/AddUserRow";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyUsersInfo = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const [users, setUsers] = useState([]);
  const [showOnlyAdmins, setShowOnlyAdmins] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeCompanyModalOpen, setIsChangeCompanyModalOpen] = useState(false);
  const [changingCompanyUser, setChangingCompanyUser] = useState(null);

  const openChangeCompanyModal = (user) => {
    setChangingCompanyUser(user);
    setIsChangeCompanyModalOpen(true);
  };

  const tableCount = useMemo(() => {
    if (isLoading) {
      return "Loading...";
    }

    if (users.length === 0) {
      return "No users found";
    }

    return `${users.length} user found`;
  }, [isLoading, users]);

  const loadUsers = async () => {
    if (company.id === "") {
      return;
    }

    setIsLoading(true);
    const fetchedUsers = await adminUser.api.getUsers(`?company_id=${company.id}`);

    if (showOnlyAdmins) {
      fetchedUsers.data = fetchedUsers.data.filter((u) => u.roles.find((ub) => ub.role === "ADMIN"));
    }
    setUsers(fetchedUsers.data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadUsers();
  }, [company, showOnlyAdmins]);

  const companyHasEmployeeAccess = company.integrations?.some((itg) => itg.type === "EMPLOYEE_ACCESS" && itg.status === "ACTIVE");

  return (
    <>
      <Checkbox
        label="Only show admins"
        onChange={() => setShowOnlyAdmins(!showOnlyAdmins)}
        checked={showOnlyAdmins}
      />

      <table className="back-office-table table">
        <thead>
          <tr>
            <td style={{ minWidth: "120px" }}>First Name</td>
            <td style={{ minWidth: "120px" }}>Last Name</td>
            <td style={{ minWidth: "120px" }}>E-mail</td>
            <td style={{ minWidth: "120px" }}>Phone</td>
            <td style={{ minWidth: "180px" }}>Roles</td>
            <td style={{ width: "50px" }}>Notifications</td>
            <td>Files</td>
            <td>Birth</td>
            <td style={{ width: "5%" }}>Actions</td>
          </tr>
        </thead>
        <tbody>
          {props.showAddUser && (
            <AddUserRow
              showAddUser={props.showAddUser}
              setShowAddUser={props.setShowAddUser}
              loadUsers={loadUsers}
            />
          )}
          {users.map((u) => (
            <CompanyUserRow
              showCompanyColumn
              showEmailsReceivedColumn={false}
              user={{ ...u, employee_access: companyHasEmployeeAccess }}
              key={`${company.id}-${u.id}`}
              reload={loadUsers}
              openChangeCompanyModal={openChangeCompanyModal}
            />
          ))}
        </tbody>
      </table>
      <ChangeCompanyModal
        open={isChangeCompanyModalOpen}
        closeAction={() => setIsChangeCompanyModalOpen(false)}
        user={changingCompanyUser}
        reload={loadUsers}
      />
      <p className="table-count">
        {" "}
        {tableCount}
      </p>
    </>
  );
};

export default CompanyUsersInfo;
