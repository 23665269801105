import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from "react";
import {
  Alert, Button, Checkbox, DatePicker, Dialog, Heading, Icon, Layout, SearchBar, Select, Separator, Text, TextField,
} from "@fleet.co/tarmac";
import {
  faLaptop,
} from "@fortawesome/pro-regular-svg-icons";
import { formatMoney } from "../../../helpers/formatHelpers";
import CopyButton from "../../../tools/CopyButton";
import FormatDate from "../../../tools/FormatDate";
import UserContext from "../../../tools/UserContext";
import OrderStatusNiceName from "../../blocks/OrderStatusNiceName";

import ErrorNotification from "../../common/ErrorNotification";
import ActionConfirmation from "../../common/ActionConfirmation";
import styles from "./OrderGeneralInfo.module.scss";

import {
  calculateCompanyOfferDiscount,
  calculateDeviceCost,
  calculateGrossSales,
  calculateOrderDiscount,
  calculateOrderSubTotalPricing,
  calculateOrderTotalPricing,
} from "../../../tools/OrderTools";

import { inactiveReason, orderStatus } from "../../../data/orderStatuses";
import SignatoryInfoSection from "../../blocks/SignatoryInfoSection";
import CompanyInfoSection from "../../blocks/CompanyInfoSection";
import { availableInsurance } from "../../../tools/SpecificityByCountries";

const OrderOptions = (props) => (
  <Layout direction="row" alignItems="center" spacing={2}>
    {props.order_number}
    <OrderStatusNiceName status={props.status} />
    <Layout direction="row" alignItems="center" spacing={1}>
      <Icon icon={faLaptop} color="secondary" size="S" />
      {props.num_devices}
    </Layout>
  </Layout>
);

const OrderGeneralInfo = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const [isEditable, setIsEditable] = useState(false);
  const [isShippingDateWarningOpen, setIsShippingDateWarningOpen] = useState(false);
  const [isDateWarningOpen, setIsDateWarningOpen] = useState(false);
  const [yousignLoading, setYousignLoading] = useState(false);
  const [isSendingPackage, setIsSendingPackage] = useState(false);
  const [transferEmail, setTransferEmail] = useState("");
  const [error, setError] = useState("");
  const [companyUsers, setCompanyUsers] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [upgradeContract, setUpgradeContract] = useState(props.order.upgradeContract || null);

  const isInsuranceEligible = availableInsurance(adminUser.company.country);

  const handleStatusChange = (value) => {
    const valueChange = {
      status: value,
      inactive_reason: (props.order.renewal_decision && value === "INACTIVE") ? "END_OF_LIFE" : undefined,
    };

    if (value === "INACTIVE") {
      setConfirmModalOpen(true);
    }
    props.updateOrder({ ...props.order, ...valueChange });
  };

  const handleChange = (name, value) => {
    props.updateOrder({ ...props.order, [name]: value });
  };
  const cancelEdit = () => {
    props.loadOrder();
    setIsEditable(false);
    setIsShippingDateWarningOpen(false);
    setIsDateWarningOpen(false);
  };

  const handleChangeDate = (field_name) => (date) => {
    if (
      field_name === "purchase_date"
      || field_name === "shipping_date"
      || field_name === "delivery_date"
      || field_name === "reception_date"
    ) {
      setIsShippingDateWarningOpen(true);
    } else {
      setIsDateWarningOpen(true);
    }
    const order = { ...props.order };

    order[field_name] = new Date(date).toISOString();
    props.updateOrder(order);
  };

  const saveChanges = () => {
    props.saveOrder();
    setIsEditable(false);
    setConfirmModalOpen(false);
  };

  const saveUpgradeContract = async () => {
    try {
      await adminUser.api.upgradeTheOrder(props.order.id, { newOrder: upgradeContract });
      setIsEditable(false);
      setUpgradeModalOpen(false);
      props.loadOrder();
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const generateContract = async () => {
    setYousignLoading(true);
    setIsEditable(false);
    try {
      await adminUser.api.generateContract(props.order.id);
    } catch (err) {
      setError(err.response.data.message);
    }
    props.loadOrder();
    setYousignLoading(false);
  };

  const cancelEContract = useCallback(async () => {
    setYousignLoading(true);
    try {
      await adminUser.api.cancelEcontracts(props.order.id);
    } catch (err) {
      console.error(err);
    }
    props.loadOrder();
    setYousignLoading(false);
  }, [adminUser.api, props]);

  const sendYousignWebhook = async (procedureId, memberId) => {
    const formattedProcedureId = procedureId.replace("/procedures/", "");

    if (memberId === "finish") {
      await adminUser.api.finishYousignProcedure(formattedProcedureId);
    } else {
      const formattedMemberId = memberId.replace("/members/", "");

      await adminUser.api.sendYousignWebhook(formattedProcedureId, formattedMemberId);
    }
    props.loadOrder();
  };

  const sendPackage = async (procedure_id) => {
    setIsSendingPackage(true);
    setIsEditable(false);
    await adminUser.api.sendLeaserPackage(props.order.id, { procedure_id });
    setIsSendingPackage(false);
  };

  const saveTransferContract = async () => {
    await adminUser.api.moveOrderToPurchaser(props.order.id, { email: transferEmail });
    setTransferModalOpen(false);
    setTransferEmail("");
    setIsEditable(false);
  };

  const switchToEditable = async () => {
    await props.loadOrder();
    setIsEditable(true);
    // Only before sending contract to client
    if (props.order.insurance_type === "FLEET_INSURANCE" && ["LEASER_REQUESTED", "LEAD_AGREEMENT"].includes(props.order.status) && isInsuranceEligible) {
      const errors = [];

      if (props.order.signatory.city_of_birth === null) {
        errors.push("Missing city of birth for insurance ");
      }

      if (props.order.signatory.country_of_birth === null) {
        errors.push("Missing country of birth for insurance ");
      }

      if (props.order.signatory.date_of_birth === null) {
        errors.push("Missing date of birth for insurance ");
      }
      props.setErrors([...props.errors, ...errors]);
    }
  };

  useEffect(() => {
    adminUser.api
      .getUsers(`?company_id=${props.order.company_id}`)
      .then((fetchedUsers) => setCompanyUsers(fetchedUsers.data));
  }, [adminUser, props.order]);

  const order_date = props.order.order_date ? new Date(props.order.order_date) : null;
  const signature_date = props.order.signature_date ? new Date(props.order.signature_date) : null;
  const contract_start_date = props.order.contract_start_date ? new Date(props.order.contract_start_date) : null;
  const contract_end_date = props.order.contract_end_date ? new Date(props.order.contract_end_date) : null;
  const purchase_date = props.order.purchase_date ? new Date(props.order.purchase_date) : null;
  const shipping_date = props.order.shipping_date ? new Date(props.order.shipping_date) : null;
  const delivery_date = props.order.delivery_date ? new Date(props.order.delivery_date) : null;
  const reception_date = props.order.reception_date ? new Date(props.order.reception_date) : null;
  const decision_date = props.order.renewal_decision_date ? new Date(props.order.renewal_decision_date) : null;

  const calculatedGrossSales = calculateGrossSales(props.order);

  let totalCosts = 0.0;
  let netRevenue = 0.0;
  let contributionMargin = 0.0;

  let cmCalculationError = false;
  let nrCalculationError = false;

  if (typeof props.order.devices === "undefined" || !props.order.devices) {
    cmCalculationError = "No orders in device";
    nrCalculationError = "No orders in device";
  }

  if (props.order.devices.filter((d) => !parseFloat(d.acquisition_price)).length) {
    cmCalculationError = "Missing acquisition price";
  }

  if (!props.order.leaser_rate) {
    cmCalculationError = "Missing leaser rate";
    nrCalculationError = "Missing leaser rate";
  }

  if (!nrCalculationError) {
    netRevenue = props.order.net_revenue
      ? props.order.net_revenue
      : ((props.order.rent * 100) / parseFloat(props.order.leaser_rate)).toFixed(2);

    if (!cmCalculationError) {
      const costFunction = (acc, d) => acc + calculateDeviceCost(d, props.order.rent_currency);

      totalCosts = props.order.devices.reduce(costFunction, 0);

      contributionMargin = (netRevenue - totalCosts).toFixed(2);
    }
  }

  const currentInactiveReason = inactiveReason.find((r) => r.value === props.order.inactive_reason);

  const selectSignatory = (user_id) => {
    props.updateOrder({ ...props.order, signatory_id: user_id });
  };

  const canChangeSignatory = !props.order.status === "LEAD_SIGNING" || !props.order.signature_date;
  const procedureOngoing = props.order.yousign_procedures.length > 0;

  const grossMarginPct = `${((100 * contributionMargin) / calculatedGrossSales).toFixed(2)}%`;
  const grossMargin = cmCalculationError || (`${formatMoney(contributionMargin, props.order.rent_currency)}   ${grossMarginPct}`);

  const orderOptions = useMemo(() => props.order.company.orders
    .filter((o) => o.order_number)
    .sort((a, b) => new Date(b.order_date) - new Date(a.order_date))
    .map((o) => ({
      optionProps: {
        order_number: o.order_number,
        status: o.status,
        num_devices: o.num_devices,
      },
      value: o.id,
    })), [props.order.company.orders]);

  const selectedOption = useMemo(() => orderOptions.find((o) => o.value === upgradeContract) || orderOptions[0], [orderOptions, upgradeContract]);

  const hasUpgradedOrder = props.order.upgraded_contract_id && props.order.company.orders.find((o) => o.id === props.order.upgraded_contract_id);

  const InactiveReasonActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setConfirmModalOpen(false)} />
      <Button color="primary" label="Save" disabled={!currentInactiveReason} onClick={saveChanges} />
    </>
  );

  const UpgradeContractActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setUpgradeModalOpen(false)} />
      <Button color="primary" label="Save" disabled={!upgradeContract} onClick={saveUpgradeContract} />
    </>
  );

  const defaultOption = { label: "-", value: "" };
  const userOptions = [defaultOption, ...companyUsers.map((u) => ({ label: u.email, value: u.id }))];

  return (
    <Layout direction="column" spacing={4} style={{ maxWidth: "1024px", margin: "32px auto" }}>
      <Dialog
        title="Choose an inactive reason"
        onClose={() => setConfirmModalOpen(false)}
        Actions={InactiveReasonActionComponent}
        open={confirmModalOpen}
      >
        <Select
          value={currentInactiveReason?.value || ""}
          options={inactiveReason}
          onChange={(value) => handleChange("inactive_reason", value)}
        />
      </Dialog>

      <Dialog
        title="Choose the upgraded contract"
        onClose={() => setUpgradeModalOpen(false)}
        Actions={UpgradeContractActionComponent}
        open={upgradeModalOpen}
      >
        <SearchBar
          value={selectedOption}
          options={orderOptions}
          getOptionLabel={(option) => option.optionProps?.order_number}
          renderOption={(option) => <OrderOptions {...option.optionProps} />}
          onChange={(_, chosenOption) => setUpgradeContract(chosenOption?.value)}
        />
      </Dialog>

      {props.errors.length !== 0 && <ErrorNotification errors={props.errors} clear={props.clear} />}
      <Heading variant="h2">
        Order #
        {props.order.order_number}
        {" "}
        Details
      </Heading>

      <Layout direction="row" spacing={2} justifyContent="flex-start">
        <Select
          label="Status"
          disabled={!isEditable}
          value={props.order.status}
          options={orderStatus}
          onChange={(value) => handleStatusChange(value)}
        />

        <TextField disabled label="SIREN" value={props.order.company.siren} />
        <TextField disabled label="Leaser Rate" value={props.order.leaser_rate || ""} />

        {props.order.status === "INACTIVE"
          && (
            <Select
              label="Inactive reason"
              disabled={!isEditable}
              value={currentInactiveReason?.value || ""}
              options={inactiveReason}
              onChange={(value) => handleChange("inactive_reason", value)}
            />
          )}
      </Layout>

      <Layout direction="row" spacing={2}>
        <TextField label="Total Rent" disabled value={formatMoney(props.order.rent, props.order.rent_currency)} />
        <TextField label="Gross Revenue" disabled value={formatMoney(calculatedGrossSales, props.order.rent_currency)} />
        {isEditable ? (
          <TextField
            disabled={!isEditable}
            name="net_revenue"
            label="Net Revenue"
            value={props.order.net_revenue}
            onChange={props.handleChange}
          />
        ) : (
          <TextField
            disabled
            label="Net Revenue"
            value={nrCalculationError || formatMoney(netRevenue, props.order.rent_currency)}
          />
        )}

        <TextField label="Gross Margin" disabled value={grossMargin} />
      </Layout>

      <Separator orientation="horizontal" />

      {props.order.fraud_status !== "SAFE" && (
        <>
          <Layout direction="column" spacing={2}>

            <Heading variant="h3">KYC</Heading>
            <Layout direction="column">

              <TextField disabled label="Flag" value={props.order.fraud_status === "PROVEN" ? "Proven" : "Potential"} />
              <TextField disabled label="Score" value={props.order.fraud_score} />

              <div className={styles.table}>
                <div className={styles.tableRow}>
                  <Text variant="body2">Description</Text>
                  <Text variant="body2">Impact</Text>
                </div>
                {props.order.fraud_criteria?.sort((a, b) => b.score - a.score).map((fc, index) => (
                  <div key={fc.id} className={`${styles.tableRow} ${index % 2 === 0 && styles.greyRow}`}>
                    <span>{fc.description}</span>
                    <span>{fc.score}</span>
                  </div>
                ))}
              </div>
            </Layout>
          </Layout>

          <Separator orientation="horizontal" />
        </>
      )}

      <Heading variant="h3">Dates</Heading>
      {isShippingDateWarningOpen && (
        <ActionConfirmation
          confirm={() => setIsShippingDateWarningOpen(false)}
          refuse={cancelEdit}
          message="This will change the shipping date of all the devices in the order and can be overwritten automatically via Hubspot or device tracking. Are you sure you want to change it ?"
          clear={() => setIsShippingDateWarningOpen(false)}
        />
      )}
      {isDateWarningOpen && (
        <ActionConfirmation
          confirm={() => setIsDateWarningOpen(false)}
          refuse={cancelEdit}
          message="This date can be overwritten automatically via Hubspot, are you sure you want to change it ?"
          clear={() => setIsDateWarningOpen(false)}
        />
      )}

      <Layout direction="row" spacing={2} flexWrap="wrap">
        {props.order.order_date && (

          <DatePicker
            label="Order"
            disabled={!isEditable}
            name="order_date"
            onChange={handleChangeDate("order_date")}
            value={order_date}
          />
        )}
        {props.order.signature_date && (
          <DatePicker
            label="Signature"
            disabled={!isEditable}
            name="signature_date"
            onChange={handleChangeDate("signature_date")}
            value={signature_date}
          />
        )}
        {props.order.contract_start_date && (
          <DatePicker
            label="Contract Start Date"
            disabled={!isEditable}
            name="contract_start_date"
            onChange={handleChangeDate("contract_start_date")}
            value={contract_start_date}
          />
        )}
        {props.order.contract_end_date && (
          <DatePicker
            label="Contract End Date"
            disabled={!isEditable}
            name="contract_end_date"
            onChange={handleChangeDate("contract_end_date")}
            value={contract_end_date}
          />
        )}
        {purchase_date && (
          <DatePicker
            label="Purchase"
            disabled={!isEditable}
            name="purchase_date"
            onChange={handleChangeDate("purchase_date")}
            value={purchase_date}
          />
        )}
        {shipping_date && (
          <DatePicker
            label="Shipping"
            disabled={!isEditable}
            name="shipping_date"
            onChange={handleChangeDate("shipping_date")}
            value={shipping_date}
          />
        )}

        {delivery_date && (
          <DatePicker
            label="Delivery"
            disabled={!isEditable}
            name="delivery_date"
            onChange={handleChangeDate("delivery_date")}
            value={delivery_date}
          />
        )}

        {reception_date && (
          <DatePicker
            label="Reception"
            disabled={!isEditable}
            name="reception_date"
            onChange={handleChangeDate("reception_date")}
            value={reception_date}
          />
        )}
      </Layout>

      <Separator orientation="horizontal" />

      <Heading variant="h3">Discount</Heading>
      <Layout direction="column">

        <Layout direction="column" spacing={1}>
          <Layout direction="column">
            <div style={{ width: "300px" }}>Devices SubTotal</div>

            <div>
              {formatMoney(calculateOrderSubTotalPricing(props.order), props.order.rent_currency)}
            </div>
          </Layout>

          {props.order.company_offer && <TextField label="Order Offer Discount" disabled value={calculateCompanyOfferDiscount(props.order)} />}

          <Layout direction="column">
            <Checkbox
              label="Month offered"
              disabled={!isEditable}
              name="month_offered"
              checked={props.order.month_offered}
              onChange={() => handleChange("month_offered", !props.order.month_offered)}
            />
            <TextField disabled value={calculateOrderDiscount(props.order)} />
          </Layout>
          <Layout direction="column">
            <div style={{ width: "300px" }}>Total</div>

            <div>
              {formatMoney(calculateOrderTotalPricing(props.order), props.order.rent_currency)}
            </div>
          </Layout>
        </Layout>
      </Layout>

      <Separator orientation="horizontal" />

      <Heading variant="h3">Order Information</Heading>

      <Text variant="body1" bold>Company information</Text>

      <CompanyInfoSection
        company={props.order.company}
        isEditable={isEditable && canChangeSignatory}
        refresh={props.loadOrder}
      />

      <Text variant="body1" bold>Signatory information</Text>

      <Select
        label="Contract signed by"
        disabled={!isEditable || !canChangeSignatory}
        value={companyUsers.length ? props.order.signatory_id : ""}
        options={userOptions}
        onChange={selectSignatory}
      />
      {
        props.order.signatory_id && (
          <SignatoryInfoSection
            order={props.order}
            signatory={companyUsers.find((u) => u.id === props.order.signatory_id)}
            refresh={props.loadOrder}
            isEditable={isEditable && canChangeSignatory}
          />
        )
      }
      <Separator orientation="horizontal" />

      <Heading variant="h3">E-Contract</Heading>
      {
        error && (
          <>
            Error :
            {" "}
            <span className={styles.error}>{error}</span>
            <br />
          </>
        )
      }
      {
        !procedureOngoing && (
          <Button
            variant="contained"
            color="primary"
            label="Generate Contract"
            onClick={generateContract}
            loading={yousignLoading}
            disabled={!isEditable}
          />
        )
      }
      {
        procedureOngoing && (
          <Button
            variant="contained"
            color="primary"
            label="Cancel Procedures"
            onClick={cancelEContract}
            loading={yousignLoading}
            disabled={!isEditable}
          />
        )
      }

      {
        props.order?.yousign_procedures?.length > 0 ? (
          <>
            <Heading variant="h3">Yousign Procedures</Heading>

            {props.order?.yousign_procedures?.map((proc) => {
              const webhooks = props.order.yousign_webhooks.filter((wh) => wh.procedure_id === proc.procedure_id);

              const baseLink = process.env.REACT_APP_YOUSIGN_SIGNATURE_LINK;
              const signatureUi = process.env.REACT_APP_YOUSIGN_SIGNATURE_UI;

              const clientSignatureLink = `${baseLink}=${proc.client_first_id}&signatureUi=/${signatureUi}`;
              const fleetSignatureLink = `${baseLink}=${proc.fleet_id}&signatureUi=/${signatureUi}`;
              const leaserSignatureLink = `${baseLink}=${proc.leaser_id}&signatureUi=/${signatureUi}`;

              return (
                <Layout direction="column" spacing={1}>
                  <Text variant="body1">
                    ID :
                    <a href={`${baseLink}/${proc.procedure_id}`}>{proc.procedure_id}</a>
                  </Text>
                  <Text variant="body1">
                    Creation Date :
                    <FormatDate date={proc.created_at} />
                  </Text>
                  <Text variant="body1">
                    Status :
                    {proc.status}
                  </Text>
                  <Text variant="body1">
                    Signature Status :
                    {proc.signature_status}
                  </Text>
                  <Layout direction="row" spacing={1} alignItems="center">
                    {process.env.REACT_APP_ENV !== "production"
                      && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          label="send client signing wh"
                          onClick={() => sendYousignWebhook(proc.procedure_id, proc.client_first_id)}
                        />
                      )}
                    <a href={clientSignatureLink}>Client Signature Link</a>
                    <CopyButton value={clientSignatureLink}>Copy</CopyButton>
                  </Layout>
                  <Layout direction="row" spacing={1} alignItems="center">
                    {process.env.REACT_APP_ENV !== "production"
                      && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          label="send fleet signing wh"
                          onClick={() => sendYousignWebhook(proc.procedure_id, proc.fleet_id)}
                        />
                      )}
                    <a href={fleetSignatureLink}>Fleet Signature Link</a>
                    <CopyButton value={fleetSignatureLink}>Copy</CopyButton>
                  </Layout>
                  <Layout direction="row" spacing={1} alignItems="center">
                    {process.env.REACT_APP_ENV !== "production"
                      && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          label="send leaser signing wh"
                          onClick={() => sendYousignWebhook(proc.procedure_id, proc.leaser_id)}
                        />
                      )}
                    <a href={leaserSignatureLink}>Leaser Signature Link</a>
                    <CopyButton value={leaserSignatureLink}>Copy</CopyButton>
                  </Layout>
                  {process.env.REACT_APP_ENV !== "production"
                    && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        label="send procedure finish wh"
                        onClick={() => sendYousignWebhook(proc.procedure_id, "finish")}
                      />
                    )}
                  {webhooks.length > 0 ? (
                    <Layout direction="column">
                      <Heading variant="h4">Webhooks</Heading>
                      <table style={{ border: "1px grey solid" }}>
                        <tr style={{ borderBottom: "1px grey solid", backgroundColor: "#e0f1f7" }}>
                          <td>Webhook Date</td>
                          <td>Type</td>
                          <td>Signer</td>
                        </tr>
                        {webhooks.map((wh, index) => {
                          const signer = JSON.parse(wh.member);

                          return (
                            <tr key={index} style={{ borderBottom: "1px grey solid" }}>
                              <td>
                                <FormatDate date={wh.created_at} withTime />
                              </td>
                              <td>{wh.event_name}</td>
                              <td>
                                {signer ? (
                                  <>
                                    <strong>
                                      {signer.firstname}
                                      {" "}
                                      {signer.lastname}
                                    </strong>
                                    <br />
                                    {signer.email}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </Layout>
                  ) : (
                    "No webhooks"
                  )}
                  <div className={styles.econtract}>
                    <Button
                      variant="contained"
                      color="primary"
                      label="Send Leaser Package"
                      onClick={() => sendPackage(proc.procedure_id)}
                      loading={isSendingPackage}
                      disabled={!isEditable}
                    />
                  </div>
                </Layout>
              );
            })}
          </>
        ) : (
          ""
        )
      }
      <Separator orientation="horizontal" />

      <Heading variant="h3">Transfer Order</Heading>

      <Layout direction="row" spacing={2}>
        <TextField
          disabled={!isEditable}
          label="Transfer order to e-mail"
          value={transferEmail}
          onChange={(e) => setTransferEmail(e.target.value)}
        />

        <Button
          disabled={!isEditable}
          variant="contained"
          color="primary"
          label="Transfer Order"
          onClick={() => setTransferModalOpen(true)}
        />
      </Layout>

      <Dialog
        title="Confirm Contract Move"
        onClose={() => setTransferModalOpen(false)}
        Actions={(
          <>
            <Button color="secondary" label="Cancel" onClick={() => setTransferModalOpen(false)} />
            <Button color="primary" label="Confirm Transfer" onClick={saveTransferContract} />
          </>
        )}
        open={transferModalOpen}
      >
        <Alert severity="warning">
          This action cannot be reverted
        </Alert>
        <ul>
          <li>
            Order
            {" "}
            <strong>{props.order.order_number}</strong>
            {" "}
            and
            {" "}
            <strong>
              {props.order.num_devices}
              {" "}
              devices

            </strong>
            {" "}
            will be moved to
            {" "}
            <strong>{transferEmail}</strong>
            's company
          </li>
          <li>Order devices will be unassigned</li>
          <li>
            <strong>{transferEmail}</strong>
            {" "}
            will be set as the order's purchaser
          </li>
          <li>Order files will be moved to the new company</li>
        </ul>
      </Dialog>

      <Separator orientation="horizontal" />

      <Heading variant="h3">End of contract</Heading>

      {hasUpgradedOrder ? (
        <TextField
          disabled
          label="Upgrade contract number"
          value={hasUpgradedOrder.order_number}
        />
      ) : (
        <Button
          disabled={!isEditable}
          variant="contained"
          color="primary"
          label="Upgrade contract"
          onClick={() => setUpgradeModalOpen(true)}
        />
      )}

      {props.order.renewal_decision && (
        <Layout direction="column" spacing={1}>
          <div>
            <Text variant="body2">Decision date</Text>
            <DatePicker
              disabled
              label="Decision date"
              name="renewal_decision_date"
              value={decision_date}
            />
          </div>
          <TextField
            disabled={!isEditable}
            name="return_comment"
            label="Return comment"
            value={props.order.return_comment}
            multiline
            rows={4}
            onChange={props.handleChange}
          />
        </Layout>
      )}

      <Separator orientation="horizontal" />

      <TextField
        label="Comment"
        disabled={!isEditable}
        name="comment"
        value={props.order.comment}
        onChange={props.handleChange}
        multiline
        rows={4}
        style={{ maxWidth: "300px" }}
      />
      <div style={{ position: "fixed", bottom: "100px", right: "1em" }}>
        {
          isEditable ? (
            <Layout direction="row" spacing={1}>
              <Button
                variant="contained"
                color="primary"
                label="Save Changes"
                onClick={saveChanges}
              />

              <Button
                variant="contained"
                color="secondary"
                label="Cancel"
                onClick={cancelEdit}
              />
            </Layout>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              label="Edit"
              onClick={switchToEditable}
            />
          )
        }
      </div>
    </Layout>
  );
};

export default OrderGeneralInfo;
