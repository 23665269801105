import React from "react";
import {
  Button, DatePicker, Layout, Popover, Select, Text,
} from "@fleet.co/tarmac";
import { faBan } from "@fortawesome/pro-regular-svg-icons";

const ReturnFilterMenu = (props) => {
  const {
    filters, applyFilters, resetFilters,
  } = props;

  const statusOption = [
    { label: "WAITING", value: "WAITING" },
    { label: "SCHEDULED", value: "SCHEDULED" },
    { label: "SHIPPED", value: "SHIPPED" },
    { label: "RECEIVED", value: "RECEIVED" },
  ];

  const handleSelectStatus = (value) => {
    const currentFilters = { ...filters };

    currentFilters.someParcelStatus = value;
    applyFilters(currentFilters);
  };

  const selectedBillingMinDate = filters.contractStartMinDate ? new Date(filters.contractStartMinDate) : null;
  const selectedBillingMaxDate = filters.contractStartMaxDate ? new Date(filters.contractStartMaxDate) : null;
  const selectedContractMinDate = filters.contractEndMinDate ? new Date(filters.contractEndMinDate) : null;
  const selectedContractMaxDate = filters.contractEndMaxDate ? new Date(filters.contractEndMaxDate) : null;

  return (
    <Popover
      AnchorComponent={Button}
      anchorProps={{
        variant: "outlined",
        color: "primary",
        label: "Filters",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Layout direction="column" spacing={1}>
        <Layout direction="row" spacing={1} alignItems="center">
          <Text variant="body1">Orders with at least one </Text>
          <Select
            options={statusOption}
            value={filters.someParcelStatus || ""}
            onChange={(value) => handleSelectStatus(value)}
          />
          <Text variant="body1">return.</Text>
        </Layout>
        <Text variant="body1"> Select invoicing start date range</Text>
        <Layout direction="row" spacing={2}>
          <DatePicker
            label="Start date"
            name="minDate"
            onChange={(date) => applyFilters({ ...filters, contractStartMinDate: date })}
            value={selectedBillingMinDate}
          />
          <DatePicker
            label="End date"
            name="maxDate"
            onChange={(date) => applyFilters({ ...filters, contractStartMaxDate: date })}
            value={selectedBillingMaxDate}
          />
        </Layout>
        <Text variant="body1"> Select contract end date range</Text>
        <Layout direction="row" spacing={2}>
          <DatePicker
            label="Start date"
            name="minDate"
            onChange={(date) => applyFilters({ ...filters, contractEndMinDate: date })}
            value={selectedContractMinDate}
          />
          <DatePicker
            label="End date"
            name="maxDate"
            onChange={(date) => applyFilters({ ...filters, contractEndMaxDate: date })}
            value={selectedContractMaxDate}
          />
        </Layout>
        <Layout direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={resetFilters}
            label="Reset all filters"
            startIcon={faBan}
          />
        </Layout>
      </Layout>
    </Popover>
  );
};

export default ReturnFilterMenu;
