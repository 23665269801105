import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import useLocalStorage from "../hooks/useLocalStorage";

const Context = createContext({});

export const useSearchContext = () => useContext(Context);

const intialSearchState = {
  users: [],
  companies: [],
  devices: [],
  orders: [],
};

const moveItemToTopOfList = (itemCliked, list, max_size = 5) => {
  if (!list) return [itemCliked];
  const duplicateItem = list.find((item) => item.id === itemCliked.id);

  if (duplicateItem) {
    const itemsUpdated = list.filter((item) => item.id !== itemCliked.id);

    list = itemsUpdated;
  } else if (list.length >= max_size) {
    list.pop();
  }

  return [itemCliked, ...list];
};

export const SearchProvider = ({ children }) => {
  const [searchHistory, setSearchHistory] = useLocalStorage("search_history", intialSearchState);
  const [results, setResults] = useState(intialSearchState);

  const addToSearchHistory = useCallback((type, item) => {
    let list = searchHistory[type];

    if (!list) {
      list = JSON.parse(searchHistory)[type];
    }

    const recordOnTopOfList = moveItemToTopOfList(item, list);

    const searchHistoryUpdated = {
      ...searchHistory,
      [type]: recordOnTopOfList,
    };

    setSearchHistory(searchHistoryUpdated);
    setResults(searchHistoryUpdated);
  }, [searchHistory, setSearchHistory]);

  useEffect(() => {
    // If there is no results, set the search history as results
    if (Object.values(results).some((type) => type.length)) return;

    setResults(searchHistory);
  }, [results, searchHistory]);

  const values = useMemo(
    () => ({
      searchHistory,
      addToSearchHistory,
      results,
      setResults,
    }),
    [searchHistory, addToSearchHistory, results, setResults],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
