import React from "react";
import {
  Icon, Layout, Tooltip,
} from "@fleet.co/tarmac";
import { faHome, faMailbox, faSuitcase } from "@fortawesome/pro-regular-svg-icons";
import CopyButton from "../../../../tools/CopyButton";

const AddressCell = ({ row: device }) => {
  if (!device.address) return "No address";

  const {
    address1,
    address2,
    zip,
    city,
    country,
    type,
  } = device.address;

  const addressTypes = {
    OFFICE: { icon: faSuitcase, label: "Office" },
    HOME: { icon: faHome, label: "Home" },
    PO_BOX: { icon: faMailbox, label: "PO Box" },
  };

  const addressType = addressTypes[type || "OFFICE"];

  const fullAddress = (
    <Layout direction="column" style={{ padding: "8px 12px" }} gap={1}>
      <div>
        <strong>
          <Icon fontSize="12" color="white" icon={addressType.icon} />
          {" "}
          {addressType.label}
        </strong>
      </div>
      <div>
        {address1}
        <br />
        {address2 && <div>{address2}</div>}
        <div>
          {zip}
          {" "}
          -
          {" "}
          {city}
        </div>
        <div><strong>{country}</strong></div>
      </div>
    </Layout>
  );

  const fullAddressText = `${address1}\n${address2}\n${zip}\n${city}\n${country}`;

  return (
    <Tooltip title={fullAddress}>
      <Layout direction="column">
        <CopyButton value={fullAddressText}>
          <strong>
            <Icon fontSize="12" icon={addressType.icon} />
            {" "}
            {addressType.label}
          </strong>
          <br />
          {address1}
          <br />
          {`${zip} ${city}`}
          <br />
          {country}
        </CopyButton>
      </Layout>
    </Tooltip>
  );
};

export default AddressCell;
