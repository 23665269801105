import React, { useState } from "react";
import { Icon, Text } from "@fleet.co/tarmac";
import { faClone } from "@fortawesome/pro-regular-svg-icons";

const CopyButton = (props) => {
  const { children, value } = props;
  const [copied, setCopied] = useState(false);

  if (!value) return null;

  return (
    <Text
      variant="body2"
      component="span"
      style={{ padding: "0 5px", cursor: "pointer" }}
      color
      onClick={(e) => {
        // Do not trigger other actions
        e.stopPropagation();
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
        navigator.clipboard.writeText(value);
      }}
    >
      {children}
      {" "}
      {copied ? "Copié" : <Icon icon={faClone} color="secondary" size="S" />}
    </Text>
  );
};

export default CopyButton;
