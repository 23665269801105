import React, { useContext, useState } from "react";
import {
  Button, Card, Heading, Icon, Layout, Select, Text,
} from "@fleet.co/tarmac";
import { faExternalLink, faShare, faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../tools/UserContext";
import LeaserScoring from "./LeaserScoring";
import leaserStatuses from "../../data/leaserStatuses";

const LeaserBlock = ({
  leaser, order, reload, index, isAuto, setStatus, leaserStatus, setError,
}) => {
  const { user: adminUser } = useContext(UserContext);

  const [isRequesting, setIsRequesting] = useState(false);

  if (!order) return null;

  let request = null;
  let request_status = null;
  let canRefresh = false;

  if (order.leaser_requests) {
    request = order.leaser_requests.find((lr) => lr.leaser === leaser.name);
    if (leaserStatus) {
      request_status = leaserStatuses.find((ls) => ls.value === leaserStatus.status);
    } else if (request) {
      request_status = leaserStatuses.find((ls) => ls.value === request.status);
      const isFranfi = request.leaser === "Franfinance";
      const isFinalized = ["REJECTED", "ACCEPTED"].includes(request.status);

      canRefresh = isAuto && !isFranfi && !isFinalized;
    }
  }

  const scoring = order.leaserScoring?.find((l) => l.leaser === leaser.name);

  const sendLeaserRequest = async () => {
    setIsRequesting(true);
    try {
      await adminUser.api.requestLeaser(order.id, { leaser: leaser.name });
      setIsRequesting(false);
      reload();
      setError(null);
    } catch (err) {
      const e = err.response.data;

      setError(`${e.leaser} : ${e.errors}`);
      setIsRequesting(false);
    }
  };

  const statusValues = [
    { label: "-", value: null },
    ...leaserStatuses.map((ls) => ({
      label: ls.label,
      value: ls.value,
    }))];

  return (
    <Card
      spacing={2}
      header
      headerProps={{
        type: "default",
        color: index === 0 ? "yellow" : "blue",
        children: (
          <Layout direction="row" spacing={2} justifyContent="space-between" fullWidth>
            <Heading variant="h5">
              {index + 1}
              {" "}
              {leaser.label}
            </Heading>
            {leaser.platform_link && <Icon color="secondary" onClick={() => window.open(leaser.platform_link, "_blank")} icon={faExternalLink} />}
          </Layout>
        ),
      }}
    >
      <Layout direction="column" spacing={2}>
        {Boolean(request_status) && (
          <Layout direction="row" spacing={1} alignItems="center">
            <Icon icon={request_status.icon} color={request_status.color} />
            <Text variant="body1" color={request_status.color}>
              {request_status.label}
            </Text>
          </Layout>
        )}

        {isAuto && !request && (
          <Button
            label="Request"
            loading={isRequesting}
            color="secondary"
            variant="outlined"
            onClick={sendLeaserRequest}
            startIcon={faShare}
          />
        )}

        {canRefresh && (
          <Button
            loading={isRequesting}
            onClick={sendLeaserRequest}
            color="info"
            variant="outlined"
            label="Refresh"
            startIcon={faSyncAlt}
          />
        )}

        {!isAuto && (
          <Select
            options={statusValues}
            name={leaser.name}
            value={leaserStatus?.status}
            onChange={(option) => setStatus(option.value)}
          />
        )}

        <LeaserScoring scoring={scoring} order={order} />
      </Layout>
    </Card>
  );
};

export default LeaserBlock;
