import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import ScrollToTop from "./tools/ScrollToTop";
import App from "./App";
import { ToastProvider } from "./contexts/ToastContext";

import * as serviceWorker from "./serviceWorker";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";
import "./scss/tables.scss";

ReactDOM.render(
  <Router>
    <ScrollToTop>
      <ToastProvider>
        <App />
      </ToastProvider>
    </ScrollToTop>
  </Router>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
