import React, { useState } from "react";

import { Alert, Button, Dialog } from "@fleet.co/tarmac";

const DeleteConfirmModal = ({
  closeAction, deleteAction, target, open,
}) => {
  const [errors, setErrors] = useState("");

  const ActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={closeAction} />
      <Button color="primary" label="Yes" onClick={deleteAction} />
    </>
  );

  return (
    <Dialog
      onClose={closeAction}
      title="Delete"
      Actions={ActionComponent}
      open={open}
    >
      {errors && <Alert severity="error" onClose={() => setErrors("")}>{errors.message}</Alert>}
      This action will permanently delete
      {" "}
      {target}
      .
      <br />
      Would you like to continue ?
      <br />
      <br />
    </Dialog>
  );
};

export default DeleteConfirmModal;
