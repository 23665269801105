import React, { useContext, useState } from "react";
import {
  Button, Dialog, Layout, Select, Tag, Text,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

const DeviceRenewalStatus = (props) => {
  const {
    device, deviceInfo, setDeviceInfo, isEditable, setIsEditable, reloadOrders,
  } = props;
  const { user: adminUser } = useContext(UserContext);
  const [statusUpdateModal, setStatusUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (type) => {
    setDeviceInfo({ ...deviceInfo, renewal_type: type });
    setStatusUpdateModal(true);
    setIsEditable(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await adminUser.api.modifyDevice(device.id, deviceInfo);
    setStatusUpdateModal(false);
    setIsEditable(false);
    setIsLoading(false);
    reloadOrders();
  };

  const options = [
    { label: "STOP", value: "STOP" },
    { label: "RENEW", value: "RENEW" },
    { label: "LOST", value: "LOST" },
    { label: "BROKEN", value: "BROKEN" },
    { label: "BUYBACK", value: "BUYBACK" },
  ];

  const getColorByStatus = () => {
    let color;

    switch (device.renewal_type) {
      case "STOP":
        color = "red";
        break;
      case "RENEW":
        color = "green";
        break;
      case "LOST":
        color = "purple";
        break;
      case "BROKEN":
        color = "orange";
        break;
      case "BUYBACK":
        color = "cyan";
        break;
      default:
        color = "grey";
        break;
    }

    return color;
  };

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setStatusUpdateModal(false)} />
      <Button color="primary" label={isLoading ? "Loading" : "Confirmation"} onClick={handleSubmit} />
    </>
  );

  return (
    <Layout direction="column">
      {isEditable
        ? (
          <Select
            value={device.renewal_type}
            options={options}
            onChange={(value) => handleClick(value)}
          />
        )
        : <Tag variant="filled" label={device.renewal_type} backgroundColor={getColorByStatus()} />}
      <Dialog
        title="Warning"
        onClose={() => setStatusUpdateModal(false)}
        Actions={ActionComponent}
        open={statusUpdateModal}
      >
        <Text variant="body1">You are about to change the renewal status of a device</Text>
        <br />
        <br />
        <Layout direction="row" spacing={2}>
          <Layout direction="column" spacing={2}>
            <Text variant="body1">Device :</Text>
            <Text variant="body1">S/N :</Text>
            <Text variant="body1">New EOL Status:</Text>
          </Layout>
          <Layout direction="column" spacing={2} alignItems="center">
            <Text variant="body1">{deviceInfo.name}</Text>
            <Text variant="body1">{deviceInfo.serial_number}</Text>
            <Text variant="danger">{deviceInfo.renewal_type}</Text>
          </Layout>
        </Layout>
      </Dialog>
    </Layout>
  );
};

export default DeviceRenewalStatus;
