import React, { useContext } from "react";
import {
  Icon, IconButton, Layout, Menu, MenuItem, Text, Tooltip,
} from "@fleet.co/tarmac";
import { useHistory } from "react-router-dom";
import {
  faEllipsisVertical, faExclamationTriangle, faFileArchive, faFileContract, faLaptop, faMoneyBill, faPeople, faRankingStar,
} from "@fortawesome/pro-regular-svg-icons";
import FormatDate from "../../../tools/FormatDate";
import OrderStatusNiceName from "../../blocks/OrderStatusNiceName";
import OrderLeaserInfoCell from "./OrderCellInfo";
import { calculateGrossSales, getLeaserList } from "../../../tools/OrderTools";
import UserContext from "../../../tools/UserContext";
import { downloadFileBlob } from "../../../helpers/FilesHelpers";
import { formatMoney } from "../../../helpers/formatHelpers";

const LeaserRow = (props) => {
  const history = useHistory();
  const { user: adminUser } = useContext(UserContext);
  const { order, setSelectedOrder } = props;

  const seeOrderDetails = () => {
    const orderUrl = `/orders/${order.id}`;

    history.push({
      pathname: orderUrl,
    });
  };

  const selectedLeaser = order.leaser;
  const devicesUrl = `/orders/${order.id}/devices`;
  const usersUrl = `/companies/${order.company_id}/users`;
  const filesUrl = `/orders/${order.id}/files`;
  const billingUrl = `/orders/${order.id}/billing`;

  const generateOrderForm = async () => {
    const response = await adminUser.api.orderForm(order.id);

    downloadFileBlob(response.data, `order_form_contract_${order.contract_number}.pdf`);
  };

  const menuContent = [
    { label: "Access to devices", action: () => history.push(devicesUrl), icon: <Icon icon={faLaptop} /> },
    { label: "Access to users", action: () => history.push(usersUrl), icon: <Icon icon={faPeople} /> },
    { label: "Access to files", action: () => history.push(filesUrl), icon: <Icon icon={faFileArchive} /> },
    { label: "Access to Invoicing", action: () => history.push(billingUrl), icon: <Icon icon={faMoneyBill} /> },
    { label: "Order form", action: () => generateOrderForm(), icon: <Icon icon={faFileContract} /> },
    { label: "Leaser scoring", action: () => setSelectedOrder(order), icon: <Icon icon={faRankingStar} /> },
  ];

  const fraudType = order.fraud_status?.toLowerCase();
  const fraudCriteria = order.fraud_criteria;
  const kycFraudTypes = ["proven", "potential"];

  const leasers = getLeaserList(order);

  if (leasers.length < 3) {
    while (leasers.length < 3) {
      leasers.push({ name: "-" });
    }
  }

  return (
    <tr className="selectedRow" key={order.id}>
      <td onClick={seeOrderDetails}>
        <FormatDate date={order.order_date} />
      </td>
      <td onClick={seeOrderDetails}>
        <Layout direction="row" spacing={1}>
          {order.company_id
            ? <span>{order.company.name}</span>
            : <span style={{ color: "#ff4d4d" }}>No company</span>}
        </Layout>
      </td>
      <td onClick={seeOrderDetails}>
        <OrderStatusNiceName status={order.status} />
      </td>
      <td onClick={seeOrderDetails}>{order.num_devices}</td>
      <td onClick={seeOrderDetails}>
        <Layout direction="row" spacing={1} alignItems="center">
          {formatMoney(calculateGrossSales(order), order.rent_currency)}
          {kycFraudTypes.includes(fraudType) && (
            <Tooltip
              title={(
                <Layout direction="column" spacing={1}>
                  <Text>
                    {`${fraudType === "proven" ? "Proven" : "Potential"} fraud:`}
                  </Text>
                  {fraudCriteria.map((fc) => <p key={fc.description}>{fc.description}</p>)}
                </Layout>
              )}
              placement="top"
            >
              <Icon
                icon={faExclamationTriangle}
                color={fraudType === "proven" ? "error" : "warning"}
                size="S"
              />
            </Tooltip>
          )}
        </Layout>
      </td>

      {leasers
        .slice(0, 3)
        .map((l) => (
          <td onClick={() => setSelectedOrder(order)} key={l.name}>
            <OrderLeaserInfoCell leaser={l.name} order={order} />
          </td>
        ))}

      <td onClick={() => setSelectedOrder(order)}>
        {selectedLeaser
          ? (<OrderLeaserInfoCell leaser={selectedLeaser} order={order} />)
          : "-"}
      </td>
      <td>
        {order.hubspot_id && (
          <a
            href={`https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTALID}/deal/${order.hubspot_id}`}
            target="_new"
          >
            {order.hubspot_id}
          </a>
        )}
      </td>
      <td className="bo-dropdown-menu-container">
        <Menu
          AnchorComponent={IconButton}
          anchorProps={{ color: "secondary", size: "M", icon: <Icon icon={faEllipsisVertical} /> }}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
        >
          {menuContent.map((item, index) => (
            <MenuItem
              key={index}
              onClick={item.action}
              label={item.label}
              icon={item.icon}
            />
          ))}
        </Menu>
      </td>
    </tr>
  );
};

export default LeaserRow;
