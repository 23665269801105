import React, { useContext, useEffect, useState } from "react";
import {
  Button, Checkbox, Drawer, Heading, Icon, Layout, Select, Tag, Text, TextField,
} from "@fleet.co/tarmac";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import OrderStatusNiceName from "./OrderStatusNiceName";
import UserContext from "../../tools/UserContext";
import { leasersList } from "../../data/leasersList";
import { calculateLeaserRate, getLeaserList } from "../../tools/OrderTools";
import BreadcrumbAmount from "../common/BreadcrumbAmount";
import ConfirmLeaserChoice from "./ConfirmLeaserChoice";
import { formatMoney } from "../../helpers/formatHelpers";
import LeaserBlock from "./LeaserBlock";

const isManualLeaser = (lr) => {
  const l = leasersList.find((l) => l.name === lr.leaser);

  return !l.has_auto_request;
};

const initialOrderLeaserInfo = {
  leaser: null,
  leaser_reason: "",
  leaser_rate: null,
  contract_duration: 36,
  request_number: "",
  contract_number: "",
  wants_insurance: false,
  leaser_status: [],
};

const LeaserModal = (props) => {
  const {
    open,
    closeAction,
    order,
    reload,
  } = props;

  const { user: adminUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showLeaserChoiceReasonModal, setLeaserChoiceReasonModalOpen] = useState(false);
  const [orderLeaserInfo, setOrderLeaserInfo] = useState(initialOrderLeaserInfo);
  const [acceptedEnvelopes, setAcceptedEnvelopes] = useState([]);

  useEffect(() => {
    if (order) {
      const leaserStatus = order.leaser_requests.filter(isManualLeaser).map((lr) => ({ name: lr.leaser, status: lr.status }));

      setOrderLeaserInfo((prevInfo) => ({
        ...prevInfo,
        leaser: order.leaser,
        leaser_reason: order.leaser_reason,
        leaser_rate: order.leaser_rate || calculateLeaserRate(order.leaser),
        contract_duration: order.contract_duration,
        request_number: order.request_number,
        contract_number: order.contract_number,
        wants_insurance: order.wants_insurance,
        // Object {"name": "leaser name", "name": "STATUS"}
        leaser_status: leaserStatus,
        cosme: order.cosme,
      }));

      setAcceptedEnvelopes(order.company.funding_envelopes.filter((e) => e.status === "ACCEPTED"));
    }
  }, [order]);

  const leasers = getLeaserList(order);

  const knowContractNumberLeaser = leasersList
    .filter((l) => l.has_same_request_contract_number)
    .map((l) => l.name);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleSelect = (value) => {
    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, leaser_reason: value }));
  };

  const selectLeaser = (leaser) => {
    if (!leaser) {
      setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, leaser: null }));

      return;
    }

    // Do that only in the Front End so the sales can modify data if wanted, they know what they are validating
    const matchingRequest = order?.leaser_requests.find((request) => request.leaser === leaser && request.status === "ACCEPTED");
    const shouldUpdateContractNumber = matchingRequest && knowContractNumberLeaser.includes(leaser);

    setOrderLeaserInfo((prevInfo) => ({
      ...prevInfo,
      request_number: matchingRequest?.final_request_number ? matchingRequest.final_request_number : "",
      contract_number: shouldUpdateContractNumber ? matchingRequest.final_request_number : "",
      cosme: matchingRequest?.cosme,
      is_e_contract: leasersList.find((l) => l.name === leaser).is_e_contract,
      leaser_rate: calculateLeaserRate(leaser),
      leaser,
    }));
  };

  const toggleValue = (name) => {
    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, [name]: !prevInfo[name] }));
  };

  const setLeaserStatus = (leaserName, status) => {
    let updatedLeaserStatus = [];

    if (orderLeaserInfo.leaser_status.find((ls) => ls.name === leaserName)) {
      updatedLeaserStatus = orderLeaserInfo.leaser_status.map((ls) => (ls.name === leaserName ? { name: leaserName, status } : ls));
    } else {
      updatedLeaserStatus = [...orderLeaserInfo.leaser_status, { name: leaserName, status }];
    }

    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, leaser_status: updatedLeaserStatus }));
  };

  const saveChanges = async () => {
    setIsSaving(true);
    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, best_leaser: leasers[0].name }));
    const isLeaserNotEmpty = orderLeaserInfo.leaser !== "";
    const isLeaserDifferentFromBestLeaser = orderLeaserInfo.leaser !== leasers[0].name;
    const isLeaserChanged = order.leaser !== orderLeaserInfo.leaser;

    if (isLeaserNotEmpty && isLeaserDifferentFromBestLeaser && isLeaserChanged) {
      // Leaser is not #1
      // Open the modal
      setLeaserChoiceReasonModalOpen(true);

      return;
    }
    updateOrder();
  };

  const updateOrder = async () => {
    if (orderLeaserInfo.leaser) {
      await adminUser.api.modifyOrder(order.id, orderLeaserInfo);
    }
    setIsSaving(false);
    closeAction();
    reload();
  };

  const leaserOptions = [
    { value: null, label: "-", selected: !orderLeaserInfo.leaser },
    ...leasers.map((l) => ({ value: l.name, label: l.name, selected: orderLeaserInfo.leaser === l.name })),
  ];

  const Actions = (
    <Button
      variant="contained"
      color="primary"
      label="Save"
      loading={isSaving}
      onClick={saveChanges}
    />
  );

  return (
    <Drawer
      open={open}
      onClose={closeAction}
      title="Leaser Scoring"
      Actions={Actions}
    >
      {order && (
        <>
          <Layout direction="column" spacing={2}>
            <Layout direction="column" spacing={2}>
              <Heading variant="h5">
                COMPANY NAME:
                {" "}
                {order.company.name}
              </Heading>

              <Layout direction="row" spacing={2}>
                <Heading variant="h5">ORDER STATUS</Heading>
                <OrderStatusNiceName status={order.status} />
              </Layout>
            </Layout>

            {acceptedEnvelopes.length > 0 && (
              <Layout direction="column">
                <Heading variant="h5">FUNDING ENVELOPES</Heading>
                <Layout direction="column">
                  {acceptedEnvelopes.map((e) => (
                    <a
                      key={e.id}
                      href={`/companies/${order.company.id}/funding`}
                      target="blank"
                    >
                      <Text variant="body1">{e.leaser}</Text>
                      <Layout>
                        <Text>
                          {formatMoney(e.current_amount, order.currency)}
                        </Text>
                        <BreadcrumbAmount total_amount={e.total_amount} current_amount={e.current_amount} />
                      </Layout>
                    </a>
                  ))}
                </Layout>
              </Layout>
            )}

            {order.leaserScoring && (
              <Layout direction="column" spacing={2}>
                <Heading variant="h5">LEASER SCORING STATUS</Heading>
                <Text variant="body1">Our algorithm suggests the leasers most likely to accept the funding request</Text>
                {errorMessage && (
                  <div>
                    <div>{errorMessage}</div>
                    <Icon onClick={() => setErrorMessage(null)} icon={faTimes} />
                  </div>
                )}
                {order.is_AFS && (
                  <Tag label="Eligible to BNP x Apple Financial Service" backgroundColor="green" variant="filled" />
                )}
                <Layout direction="column" spacing={2}>
                  {leasers.map((leaser, index) => (
                    <LeaserBlock
                      key={index}
                      order={order}
                      index={index}
                      leaser={leaser}
                      reload={reload}
                      isAuto={leaser.has_auto_request}
                      setError={setErrorMessage}
                      leaserStatus={orderLeaserInfo.leaser_status.find((ls) => ls.name === leaser.name)}
                      setStatus={(status) => setLeaserStatus(leaser.name, status)}
                    />
                  ))}
                </Layout>
              </Layout>
            )}

            <Layout direction="column" spacing={2}>
              <Heading variant="h3">LEASER INFORMATION</Heading>
              <Layout direction="row" spacing={1}>
                <Select
                  label="Name"
                  value={orderLeaserInfo.leaser}
                  options={leaserOptions}
                  onChange={(value) => selectLeaser(value)}
                />

                <TextField label="Rate" type="number" name="leaser_rate" value={orderLeaserInfo.leaser_rate || ""} onChange={handleChange} />

                <TextField
                  label="Suggested rate"
                  type="number"
                  name="leaser_rate"
                  disabled
                  value={calculateLeaserRate(orderLeaserInfo.leaser) || ""}
                />
              </Layout>

              <div>
                <TextField label="Duration" value={orderLeaserInfo.contract_duration} disabled />

                {orderLeaserInfo.leaser === "Franfinance" && (
                  <Checkbox
                    label="COSME"
                    name="cosme"
                    checked={orderLeaserInfo.cosme}
                    onChange={() => toggleValue("cosme")}
                  />
                )}
              </div>

              <Layout direction="row" spacing={1}>
                <TextField label="Request ID" name="request_number" value={orderLeaserInfo.request_number || ""} onChange={handleChange} />

                <TextField label="Contract ID" name="contract_number" value={orderLeaserInfo.contract_number || ""} onChange={handleChange} />
              </Layout>
            </Layout>
          </Layout>

          <ConfirmLeaserChoice
            openModal={setLeaserChoiceReasonModalOpen}
            orderLeaserInfo={orderLeaserInfo}
            updateOrder={updateOrder}
            changeReason={handleSelect}
            open={showLeaserChoiceReasonModal}
          />
        </>
      )}
    </Drawer>
  );
};

export default LeaserModal;
