import React, {
  useCallback, useContext, useEffect, useState,
} from "react";
import {
  Alert, Button, Drawer, Heading, Icon, Text, TextField,
} from "@fleet.co/tarmac";
import { faChevronDown, faSearch } from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../tools/UserContext";
import CardShadowBox from "../common/CardShadowBox";
import styles from "./ChangeCompanyModal.module.scss";
import LoadingSpinner from "../common/LoadingSpinner";

const ChangeCompanyModal = (props) => {
  const {
    user, closeAction, reload, open,
  } = props;

  const { user: adminUser } = useContext(UserContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [initialCompany, setInitialCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [queryString, setQueryString] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [nameResults, setNameResults] = useState([]);
  const [hubspotIdResults, setHubspotIdResults] = useState([]);

  const onCloseChangeCompanyModal = () => {
    setErrors("");
    closeAction();
    reload();
  };

  const saveChange = async () => {
    try {
      setIsLoading(true);
      const requiredCompanyInfo = {
        id: selectedCompany.id,
        hubspot_id: selectedCompany.hubspot_id,
      };
      const isMoved = await adminUser.api.migrateUser(user.id, requiredCompanyInfo);

      setIsLoading(false);
      if (isMoved) {
        reload();
        onCloseChangeCompanyModal();
      } else {
        setErrors("User not updated");
      }
    } catch (err) {
      setIsLoading(false);
      setErrors(err.response?.data?.message || "Couldn't proceed. Please verify your information");
    }
  };

  const fetchCompany = useCallback(async (companyId) => {
    try {
      const fetchedCompany = await adminUser.api.getCompany(companyId);

      return fetchedCompany.data;
    } catch (err) {
      console.error(err);
      setErrors("Company not found");
      throw err;
    }
  }, [adminUser.api]);

  const getResults = async (e) => {
    const query = e.target.value;

    try {
      setQueryString(query);

      const companiesByHubspot = await adminUser.api.getCompanies(`?hubspot_id=${query}`);
      const companiesByName = await adminUser.api.getCompanies(`?name=${query}`);

      setNameResults(companiesByName.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setHubspotIdResults(companiesByHubspot.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
    } catch (err) {
      console.log(err);
      setErrors("Companies not found");
    }
  };

  useEffect(() => {
    if (!open || !user) return;
    const initialCompanyInfo = async () => {
      const response = await fetchCompany(user.company_id);

      setInitialCompany(response);
      setIsLoading(false);
    };

    initialCompanyInfo();
  }, [user, fetchCompany, open]);

  const Actions = (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={saveChange}
        disabled={!selectedCompany}
        label="Save"
      />

      <Button
        variant="outlined"
        color="secondary"
        onClick={closeAction}
        label="Cancel"
      />
    </>
  );

  return (
    <Drawer
      open={open}
      onClose={onCloseChangeCompanyModal}
      title={`Move ${user?.firstName} ${user?.lastName} to another company`}
      Actions={Actions}
    >

      {errors && (
        <Alert severity="error" onClose={() => setErrors("")}>
          {errors}
        </Alert>
      )}

      {!isLoading && initialCompany ? (
        <div className={styles.layout}>
          <div>
            <Text variant="body2">Current company</Text>
            <CardShadowBox className={styles.companyBox}>
              <div className={styles.companyBoxHeader}>
                <Heading variant="h4">
                  {initialCompany.name ? initialCompany.name : "N.C."}
                </Heading>
                {initialCompany.hubspot_id ? (
                  <Button
                    className={`is-secondary button ${styles.hubspotButton}`}
                    onClick={() => {
                      window.open(
                        `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTALID}/company/${initialCompany.hubspot_id}`,
                      );
                    }}
                    label="View Hubspot Page"
                    color="secondary"
                  />
                ) : null}
              </div>
            </CardShadowBox>
          </div>
          <div className={styles.chevronIcon}>
            <Icon icon={faChevronDown} color="secondary" size="S" />
          </div>

          <div>
            <div className={styles.field} onFocus={() => setIsSearchActive(true)}>
              <div className={styles.searchHeader}>
                <div className="has-icons-left">
                  <TextField
                    label="New Company"
                    placeholder="Name or Hubspot ID..."
                    onChange={(e) => getResults(e)}
                    value={queryString}
                    type="search"
                    icon={faSearch}
                    autoComplete="off"
                  />
                </div>
              </div>

              {isSearchActive && (hubspotIdResults || nameResults) && queryString.length > 0 && (
                <div className={styles.companyResults}>
                  {!nameResults.length && !hubspotIdResults.length ? (
                    <p>No company found</p>
                  ) : (
                    <p>
                      <Text variant="body2">Résultats</Text>
                      <span>
                        <Text variant="body2">Hubspot ID</Text>
                      </span>
                    </p>
                  )}

                  {nameResults.length > 0
                    && nameResults.map((company) => (
                      <p
                        onClick={() => {
                          setSelectedCompany(company);
                          setIsSearchActive(false);
                        }}
                      >
                        {company.name}
                        <span>{company.hubspot_id}</span>
                      </p>
                    ))}
                  {nameResults.length && hubspotIdResults.length ? <hr /> : null}

                  {hubspotIdResults.length > 0
                    && hubspotIdResults.map((company) => (
                      <p
                        onClick={() => {
                          setSelectedCompany(company);
                          setIsSearchActive(false);
                        }}
                      >
                        {company.name}
                        <span>{company.hubspot_id}</span>
                      </p>
                    ))}
                </div>
              )}
            </div>
            {selectedCompany ? (
              <CardShadowBox className={styles.companyBox}>
                <div className={styles.companyBoxHeader}>
                  <Heading variant="h4">
                    {selectedCompany.name ? selectedCompany.name : "N.C."}
                  </Heading>
                  {selectedCompany.hubspot_id ? (
                    <Button
                      className={`is-secondary button ${styles.hubspotButton}`}
                      onClick={() => {
                        window.open(
                          `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTALID}/company/${selectedCompany.hubspot_id}`,
                        );
                      }}
                      label="View Hubspot Page"
                      color="secondary"
                    />
                  ) : null}
                </div>
              </CardShadowBox>
            ) : null}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Drawer>
  );
};

export default ChangeCompanyModal;
