import React, { useState } from "react";
import {
  Button, Icon, Layout, Tag,
} from "@fleet.co/tarmac";
import { faCheckCircle, faCircle } from "@fortawesome/pro-regular-svg-icons";
import OrderStatusNiceName from "../../blocks/OrderStatusNiceName";
import EndOfLifeTypeTag from "../../blocks/EndOfLifeTypeTag";
import styles from "./DeviceTable.module.scss";
import { formatMoney } from "../../../helpers/formatHelpers";

const DeviceCompanyRow = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const { device } = props;

  const getRowStyle = () => {
    const res = [];

    if (props.isMassEditing) {
      res.push(styles.pointer);
    }

    if (props.isSelected) {
      res.push(styles.selectedRow);
    } else if (props.idx % 2 === 0) {
      res.push(styles.backgroundGrey);
    }

    return res.join(" ");
  };

  const rowClass = [styles.row, getRowStyle()].join(" ");

  const handleMouseHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const generateCheckCircle = () => {
    const currentClassName = isHovering || props.isSelected ? styles.check : styles.hidden;

    if (props.isSelected) {
      return (
        <div className={currentClassName}>
          <Icon icon={faCheckCircle} color="secondary" size="S" className={styles.check} />
        </div>
      );
    }

    if (isHovering) {
      return (
        <div className={currentClassName}>
          <Icon icon={faCircle} color="secondary" size="S" className={styles.check} />
        </div>
      );
    }

    return <div className={currentClassName} />;
  };

  let assignmentValue = <span style={{ fontStyle: "italic" }}>{device.usage_status === "SPARE" ? "Spare" : "Unassigned"}</span>;

  if (device.user) {
    assignmentValue = `${device.user.firstName} ${device.user.lastName}`;
  }

  const providerValue = device.provider || "-";
  const serialValue = device.serial_number || "-";
  const providerRequestNumberValue = device.provider_request_number || "-";

  const serialChanges = device.activity.filter((a) => a.activity_type === "SERIAL_CHANGE");

  const hasActiveReturnStatus = Boolean(device.activeReturn);

  let eolStatus = "✅ DONE";

  if (device.end_of_life_status === "WAITING") {
    eolStatus = "🕣 WAITING";
    if (device.renewal_type === "RENEW" || device.renewal_type === "STOP" || device.renewal_type === "BROKEN") {
      eolStatus += " FOR RETURN";
    } else if (device.renewal_type === "BUYBACK" || device.renewal_type === "LOST") {
      eolStatus += " FOR PAYMENT";
    } else if (device.renewal_type === "UPGRADE") {
      eolStatus += " FOR UPGRADE";
    }
  }

  return (
    <div
      className={props.isMassEditing && (isHovering || props.isSelected) ? styles.slideRight : styles.slideLeft}
      onClick={props.isMassEditing ? props.onClick : undefined}
      onMouseOver={handleMouseHover}
      onMouseLeave={handleMouseLeave}
    >
      {props.isMassEditing && generateCheckCircle()}

      <div className={rowClass} onClick={props.isMassEditing ? props.onClick : undefined}>
        <div className={styles.productCell}>
          {device.name}
          {device.source === "FLEET" && ["COMPUTER", "TABLET", "SMARTPHONE"].includes(device.category) && (
            <div className={styles.specDiv}>
              {`${device.product.storage || ""} ${device.product.processor || ""} ${device.product.ram || ""}`}
            </div>
          )}
          {device.source === "EXTERNAL" && (
            <div className={styles.specDiv}>
              External device
            </div>
          )}
          {device.has_abm_enrollment && (
            <div className={styles.specDiv}>
              <span className={styles.redText}>Has ABM enrollment</span>
            </div>
          )}
        </div>

        <div className={styles.statusCell}>
          <OrderStatusNiceName status={device.status} />
        </div>
        <Layout direction="column" spacing={1} className={styles.statusCell}>
          <EndOfLifeTypeTag type={device.renewal_type} />
          <Tag
            label={eolStatus}
            backgroundColor={device.end_of_life_status === "DONE" ? "green" : "grey"}
            variant="filled"
            size="small"
          />
        </Layout>

        <Layout direction="column" spacing={1} className={styles.statusCell}>
          {device.renewal_type && hasActiveReturnStatus ? (
            <Layout direction="column" spacing={1} alignItems="center">
              <OrderStatusNiceName status={device.activeReturn.status} />
              <div>
                <a
                  href={`https://fleet.aftership.com/${device.activeReturn.tracking_number}?page-name=tracking-page`}
                  target="blank"
                >
                  {device.activeReturn.tracking_number}
                </a>
              </div>
              <div>{device.activeReturn.carrier}</div>
            </Layout>
          ) : "-"}
        </Layout>

        <div className={styles.rentCell}>
          {device.rent && device.order && formatMoney(device.rent, device.order?.rent_currency)}
        </div>

        <div className={styles.assignementCell}>{assignmentValue}</div>

        <div className={styles.providerCell}>
          {providerValue}
          <div className={styles.specDiv}>{providerRequestNumberValue}</div>
        </div>
        <div className={styles.serialCell}>
          {serialValue}
          {serialChanges.length > 0 && (
            <div className={styles.specDiv}>
              Previously :
              <br />
              {serialChanges.map((a) => (
                <>
                  {a.previous_serial_number}
                  <br />
                </>
              ))}
            </div>
          )}
        </div>
        <div className={styles.serialCell}>
          {device.tracking_number
            ? (
              <a
                href={`https://fleet.aftership.com/${device.tracking_number}?page-name=tracking-page`}
                target="blank"
              >
                {device.tracking_number}
              </a>
            ) : "-"}
        </div>
        {!props.isMassEditing && (
          <div className={styles.actionsCell}>
            <Button
              variant="contained"
              color="primary"
              label="Edit"
              onClick={props.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceCompanyRow;
