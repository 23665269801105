import React, {
  useContext, useState,
} from "react";
import {
  Button,
  Card, Heading, Layout,
  TextField,
} from "@fleet.co/tarmac";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyMdmInfo = (props) => {
  const { onSave } = props;
  const { company, setCompany } = useContext(CompanyContext);
  const [isEditable, setIsEditable] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;

    setCompany((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const onSaveMdm = () => {
    onSave();
    setIsEditable(false);
  };

  return (
    <Card spacing={4}>
      <Layout direction="column" spacing={4}>
        <Heading variant="h3" color="info">
          MDM
        </Heading>
        <Layout direction="row" spacing={1}>
          <TextField
            name="mdm_employee_password"
            label="Mdm Password"
            value={company.mdm_employee_password || ""}
            onChange={onChange}
            disabled={!isEditable}
          />
          <TextField
            name="abm_id"
            label="Apple Business Manager ID"
            value={company.abm_id || ""}
            onChange={onChange}
            disabled={!isEditable}
          />
        </Layout>
        {isEditable ? (
          <Layout direction="row" spacing={2}>
            <Button variant="contained" color="primary" label="Save Changes" onClick={onSaveMdm} />
            <Button variant="outlined" color="secondary" label="Cancel" onClick={() => setIsEditable(false)} />
          </Layout>
        ) : (
          <Button variant="contained" color="primary" label="Edit" onClick={() => setIsEditable(true)} />
        )}
      </Layout>
    </Card>
  );
};

export default CompanyMdmInfo;
