import React, { useState } from "react";
import {
  Button, Heading, Icon, IconButton, Tag,
} from "@fleet.co/tarmac";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";

import { COUNTRY_CURRENCY_MAPPING } from "src/common/i18n-consts";
import styles from "./EnvelopeRow.module.scss";
import BreadcrumbAmount from "../../common/BreadcrumbAmount";

import { formatMoney } from "../../../helpers/formatHelpers";
import { calculateGrossSales, getLeaserLabel } from "../../../tools/OrderTools";
import FormatDate from "../../../tools/FormatDate";
import OrderStatusNiceName from "../../blocks/OrderStatusNiceName";
import EnvelopeCountdown from "../../blocks/EnvelopeCountdown";

const EnvelopeRow = (props) => {
  const [isOpenOrderList, setOpenOrderList] = useState(false);
  const greyContainer = props.index % 2 === 0;

  const getTagLabel = [
    { label: "ACCEPTED", value: "ACCEPTED", color: "green" },
    { label: "TODO", value: "TODO", color: "cyan" },
    { label: "CLOSED", value: "CLOSED", color: "pink" },
    { label: "REJECTED", value: "REJECTED", color: "red" },
    { label: "REQUESTED", value: "FUNDING_REQUESTED", color: "orange" },
    { label: "MORE DOCUMENTS", value: "FUNDING_MORE_DOCUMENTS", color: "yellow" },
  ];

  const initalStatus = getTagLabel.find((status) => status.value === props.envelope.status);

  const amountSpent = props.envelope.total_amount - props.envelope.current_amount;
  const companyCurrency = COUNTRY_CURRENCY_MAPPING[props.envelope.company.country];

  return (
    <div className={`${isOpenOrderList ? styles.blue_border : ""}`}>
      <div className={`${styles.container} ${greyContainer ? styles.greyContainer : ""}`}>
        {!props.fromCompanyView && (
          <a href={`/companies/${props.envelope.company.id}`} className={`${styles.companyContent}`}>
            <p>{props.envelope.company.name}</p>
          </a>
        )}

        <div className={`${styles.statusCell} ${styles.colContent}`}>
          {props.envelope.status ? <Tag backgroundColor={initalStatus.color} label={initalStatus.label} variant="filled" /> : <>-</>}
        </div>

        <div className={`${styles.leaserName} ${styles.colContent}`}>{getLeaserLabel(props.envelope.leaser)}</div>

        {/* // ! AMOUNT LEFT */}
        <div className={`${styles.amount}`}>
          {props.envelope.status === "ACCEPTED" || props.envelope.status === "CLOSED" ? (
            <p>
              {formatMoney(props.envelope.current_amount, companyCurrency)}
              <BreadcrumbAmount
                total_amount={props.envelope.total_amount}
                current_amount={props.envelope.current_amount}
              />
            </p>
          ) : (
            <p>-</p>
          )}
        </div>
        {/* // ! AMOUNT SPENT */}
        <div className={`${styles.amount} ${styles.colContent}`}>
          {props.envelope.status === "ACCEPTED" || props.envelope.status === "CLOSED" ? (
            formatMoney(amountSpent, companyCurrency)
          ) : (
            <p>-</p>
          )}
        </div>
        <div className={`${styles.amount} ${styles.colContent}`}>
          {formatMoney(props.envelope.total_amount, companyCurrency)}
        </div>

        <div className={`${styles.monthsLeftCell} ${styles.colContent}`}>
          {props.envelope.status === "ACCEPTED" || props.envelope.status === "CLOSED" ? (
            <div className={`${styles.monthsLeftLogo}`}>
              {props.envelope.status === "CLOSED" ? <p>-</p> : <EnvelopeCountdown envelope={props.envelope} />}
            </div>
          ) : (
            <p>-</p>
          )}
        </div>
        {/* //! TO CHANGE AS A REQUEST ID */}
        <div className={`${styles.requestIDCell} ${styles.colContent}`}>
          {props.envelope.status === "ACCEPTED" || props.envelope.status === "CLOSED" ? (
            <p>{props.envelope.request_id}</p>
          ) : (
            <p>-</p>
          )}
        </div>

        <div className={`${styles.editCell}`}>
          {props.envelope.orders.length > 0 && (
            <IconButton
              onClick={() => setOpenOrderList(!isOpenOrderList)}
              icon={<Icon icon={faAngleDown} color="secondary" size="S" />}
              color="secondary"
              size="medium"
            />

          )}

          <Button
            variant="contained"
            color="primary"
            label="Edit"
            onClick={() => props.openEditEnvelope(props.envelope)}
          />
        </div>
      </div>
      {isOpenOrderList ? (
        <div className={styles.linked_orders}>
          <hr style={{ margin: "0rem" }} />
          <Heading variant="h3">Orders</Heading>
          <div className={styles.info_titles}>
            <p>Order date</p>
            <p>Invoice status</p>
            <p>Nb.devices</p>
            <p>Net Revenue</p>
            <p>GBS</p>
            <p>Hubspot</p>
          </div>
          {props.envelope.orders.map((o, index) => {
            const getBackgroundColor = () => (index % 2 === 0 ? styles.whitebg : styles.greybg);

            return (
              <div className={`${styles.order_row_info} ${getBackgroundColor()}`} key={index}>
                <p className={styles.order_infos}>
                  <FormatDate date={o.order_date} />
                </p>
                <p className={styles.order_infos}>
                  <OrderStatusNiceName status={o.status} className={styles.order_infos} />
                </p>
                <p className={styles.order_infos}>{o.num_devices}</p>
                <p className={styles.order_infos}>
                  {formatMoney(o.net_revenue, companyCurrency)}
                </p>
                <p className={styles.order_infos}>
                  {formatMoney(calculateGrossSales(o), companyCurrency)}
                </p>
                <p className={styles.order_infos}>{o.hubspot_id}</p>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default EnvelopeRow;
