import React from "react";
import { Layout, Toast } from "@fleet.co/tarmac";
import { useToastContext } from "../../contexts/ToastContext";

const CustomToast = ({ toast }) => {
  const { removeToast } = useToastContext();
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      removeToast(toast.uid);
    }, 500);
  };

  return (
    <Toast
      open={isOpen}
      onClose={handleClose}
      severity={toast.variant}
    >
      {toast.message}
    </Toast>
  );
};

export const ToastContainer = () => {
  const { toasts } = useToastContext();

  return (
    <Layout direction="row-reverse">
      {toasts.map((toast) => (
        <CustomToast key={toast.uid} toast={toast} />
      ))}
    </Layout>
  );
};
