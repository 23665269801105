import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthRoute = ({ component: Component, isSuperAdmin, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isSuperAdmin ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    ))}
  />
);

export default AuthRoute;
