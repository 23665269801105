/**
 * Quick explain of the fields
 * - name: The name of the leaser (need to be the same as in DB - leaser.db_name in backend)
 * - has_auto_request: Can we auto request the leaser in the admin (should create a service for that)
 * - is_e_contract: Does the leaser works with an e-contract
 * - automatic_mail_leaser : Mail with package is send to the leaser when the order is validated on the deliveries view
 * Optionals
 * - platform_link: the link to the leaser website platform
 */

export const leasersList = [
  {
    name: "BNP",
    label: "FR_BNP",
    platform_link: "https://leaseoffers.leasingsolutions.bnpparibas.fr/ExtranetEuro/LoginEvent.do",
    has_auto_request: true,
    is_e_contract: true,
    has_same_request_contract_number: true,
    country: "fr",
  },
  {
    name: "Franfinance",
    label: "FR_Franfinance",
    platform_link: "https://www.flashlease.com/flashlease",
    has_auto_request: true,
    is_e_contract: true,
    has_same_request_contract_number: true,
    country: "fr",
  },
  {
    name: "Leasecom",
    label: "FR_Leasecom",
    platform_link: "https://www.myleasecom.com",
    has_auto_request: true,
    is_e_contract: true,
    has_same_request_contract_number: true,
    country: "fr",
  },
  {
    name: "Locam",
    label: "FR_Locam",
    platform_link: "https://www.locam.fr/",
    has_auto_request: true,
    is_e_contract: true,
    has_same_request_contract_number: true,
    country: "fr",
  },
  {
    name: "NBB Lease",
    label: "FR_NBB Lease",
    platform_link: "https://front.nbb-lease.fr/clymene-IHM/",
    has_auto_request: false,
    is_e_contract: true,
    has_same_request_contract_number: false,
    country: "fr",
  },
  {
    name: "Grenke",
    label: "FR_Grenke",
    platform_link: "https://partner.grenkeonline.com/portal/#/",
    has_auto_request: true,
    is_e_contract: true,
    has_same_request_contract_number: true,
    country: "fr",
  },
  {
    name: "Peac France",
    label: "FR_Peac",
    has_auto_request: false,
    is_e_contract: true,
    has_same_request_contract_number: false,
    country: "fr",
  },
  {
    name: "Grenke España",
    label: "ES_Grenke",
    platform_link: "https://partner.grenkeonline.com/es-ES/",
    has_auto_request: true,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
    automatic_mail_leaser: true,
  },
  {
    name: "SG España",
    label: "ES_SGEF",
    platform_link: "https://www.societegenerale.es/en/",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
    automatic_mail_leaser: false,
  },
  {
    name: "Siemens Spain",
    label: "ES_Siemens",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
    automatic_mail_leaser: false,
  },
  {
    name: "HPFS Spain",
    label: "ES_HPFS",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
    automatic_mail_leaser: false,
  },
  {
    name: "Iberent Spain",
    label: "ES_Iberent",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
    automatic_mail_leaser: false,
  },
  {
    name: "DLL España",
    label: "ES_DLL",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
    automatic_mail_leaser: false,
  },
  {
    name: "Grenke Deutschland",
    label: "DE_Grenke",
    has_auto_request: true,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "de",
    automatic_mail_leaser: true,
  },
  {
    name: "BNP Deutschland",
    label: "DE_BNP",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "de",
    automatic_mail_leaser: false,
  },
  {
    name: "Peac Germany",
    label: "DE_Peac",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "de",
    automatic_mail_leaser: false,
  },
  {
    name: "DLL Germany",
    label: "DE_DLL",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "de",
    automatic_mail_leaser: false,
  },
  {
    name: "Peac UK",
    label: "GB_Peac",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "gb",
  },
  {
    name: "Grenke UK",
    label: "GB_Grenke",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "gb",
  },
  {
    name: "CF Corporate Finance LTD",
    label: "GB_CF",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "gb",
  },
  {
    name: "Grenke Portugal",
    label: "PT_Grenke",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "pt",
  },
  {
    name: "Grenke International",
    label: "INT_Grenke",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "eu",
  },
  {
    name: "Tecfyspay Sl. España",
    label: "ES_TECFYS",
    has_auto_request: false,
    is_e_contract: false,
    has_same_request_contract_number: false,
    country: "es",
  },
];
