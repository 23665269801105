import React, { useMemo } from "react";

import { Button } from "@fleet.co/tarmac";
import styles from "./AddDeviceProductRow.module.scss";

import { productSpecs } from "../../../../data/productSpecs";
import { formatMoney } from "../../../../helpers/formatHelpers";

const AddDeviceProductRow = (props) => {
  const getShippingRange = () => {
    if (props.product.min_shipping && props.product.max_shipping) {
      return (
        <>
          {props.product.min_shipping}
          {" "}
          À
          {" "}
          {props.product.max_shipping}
          {" "}
          JOURS
          {" "}
        </>
      );
    }

    return "-";
  };

  const price = useMemo(() => {
    if (!props.product) return null;

    const currentCurrency = props.order.rent_currency;

    return formatMoney(props.product?.prices.find((price) => price.currency === currentCurrency)?.amount, currentCurrency);
  }, [props.product, props.order]);

  return (
    <div className={styles.productRow}>

      <div className={styles.price}>
        {price}
      </div>
      {productSpecs.filter((spec) => spec.categories.includes(props.product_group.category) && spec.applyTo.includes("PRODUCT")).map((spec) => (
        <div>
          {spec.options(props.product_group.category).find((o) => o.value === props.product[spec.name])?.label}
        </div>
      ))}
      <div className={styles.shipping}>{getShippingRange()}</div>
      <Button
        variant="contained"
        color="primary"
        label="Add"
        onClick={() => props.openModal(props.product_group, props.product)}
      />
    </div>
  );
};

export default AddDeviceProductRow;
