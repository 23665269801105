import React from "react";
import styles from "./CardShadowBox.module.scss";

const CardShadowBox = (props) => (
  <div
    {...props}
    className={`${styles.shadowBox} ${
      props.className ? props.className : ""
    } `}
  >
    {props.children}
  </div>
);

export default CardShadowBox;
