import React, { useContext, useState } from "react";
import {
  Button, Dialog, FileDropzone, Icon, Select,
} from "@fleet.co/tarmac";
import { faCheckSquare, faEye, faUpload } from "@fortawesome/pro-regular-svg-icons";
import FileTypeNiceName from "../../blocks/FileTypeNiceName";
import FileStatusNiceName from "../../blocks/FileStatusNiceName";
import FormatDate from "../../../tools/FormatDate";
import UserContext from "../../../tools/UserContext";
import { downloadFileBlob, fileChecking } from "../../../helpers/FilesHelpers";

import styles from "../../views/FileValidation.module.scss";

const FileValidationSubRow = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { order, file, reload } = props;

  const [showFileExaminationModal, setShowFileExaminationModal] = useState(false);
  const [showFileRefusedModal, setShowFileRefusedModal] = useState(false);
  const [refusalReason, setRefusalReason] = useState("");
  const [refusalReasonOther, setRefusalReasonOther] = useState("");
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // on empty files never uploaded : display date of reminder email as last notification
  const getLastNotification = () => {
    if (file.last_notification) return file.last_notification;
    let mail = "";

    if (order.mails) {
      mail = order.mails.find((mail) => (order.status === "LEASER_MORE_DOCUMENTS"
        ? mail.campaign_id === "more_documents_reminder"
        : mail.campaign_id === "missing_info_reminder"));
    }

    return mail ? mail.sent_at || mail.created_at : null;
  };

  file.last_notification = getLastNotification();

  const refusal_reason_options = [
    { label: "Expired Document", value: "EXPIRED" },
    { label: "Unofficial Document", value: "UNOFFICIAL" },
    { label: "Not Double-Sided", value: "NOT_DOUBLE_SIDED" },
    { label: "Qonto RIB Not Accepted", value: "QONTO_RIB" },
    { label: "Other", value: "OTHER" },
  ];

  const openFile = async () => {
    const response = await adminUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const setStatusAccepted = async () => {
    await adminUser.api.modifyFile(file.id, { status: "ACCEPTED" });
    reload();
    setShowFileExaminationModal(false);
  };

  const setStatusRefused = async () => {
    // xxxxx and send to client ?
    const file_patch = {
      status: "REFUSED",
      refusal_reason: refusalReason,
      refusal_reason_other: refusalReasonOther,
      last_notification: new Date(Date.now()),
    };

    await adminUser.api.modifyFile(file.id, file_patch);

    reload();
    setShowFileRefusedModal(false);
  };

  const uploadFile = async (files) => {
    const errMsg = fileChecking(files[0]);

    if (errMsg) {
      setErrorMsg(errMsg);

      return;
    }
    setErrorMsg(null);

    const formData = new FormData();

    formData.append("type", file.type);
    formData.append("status", "ACCEPTED");
    formData.append("company_id", order.company_id);
    formData.append("file_contents", files[0]);
    await adminUser.api.addFile(formData);
    reload();
    setShowFileUpload(false);
  };

  const ExaminationActionComponent = (
    <>
      <Button
        color="secondary"
        label="Refuse"
        onClick={() => {
          setShowFileExaminationModal(false);
          setShowFileRefusedModal(true);
        }}
      />
      <Button color="primary" label="Accept" onClick={setStatusAccepted} />
    </>
  );

  const RefuseActionComponent = (
    <>
      <Button color="secondary" label="Refuse" onClick={() => setShowFileRefusedModal(false)} />
      <Button color="primary" label="Send to Client" onClick={setStatusRefused} />
    </>
  );

  return (
    <tr className={file.status === "ACCEPTED" ? styles.acceptedRow : styles.pendingRow}>
      <td>
        <FileTypeNiceName type={file.type} />
      </td>
      <td>
        {order.company.name}
        {" "}
        -
        <FileTypeNiceName type={file.type} />
        {" "}
        -
        {file.original_name || file.description}
      </td>
      <td>{file.user ? `${file.user.firstName} ${file.user.lastName}` : "-"}</td>
      <td>
        <FileStatusNiceName status={file.status} />
      </td>
      <td>
        <FormatDate date={file.created_at} />
      </td>
      <td>
        <FormatDate date={file.last_notification} />
      </td>
      <td className={styles.actionsCell}>
        {file.status === "TO_EXAMINE" && (
          <div className={styles.actionCellContainer}>
            <div className={styles.actionIconContainer} onClick={openFile}>
              <Icon className={styles.actionIcon} icon={faEye} color="secondary" size="S" />
            </div>
            <div className={styles.actionIconContainer} onClick={() => setShowFileExaminationModal(true)}>
              <Icon className={styles.actionIcon} icon={faCheckSquare} color="secondary" size="S" />
            </div>
          </div>
        )}
        {file.status === "EMPTY" && (

          <div className={styles.actionIconContainer} onClick={() => setShowFileUpload(true)}>
            <Icon className={styles.actionIcon} icon={faUpload} color="secondary" size="S" />
          </div>
          // </div>
        )}
        {file.status === "ACCEPTED" && <div className={styles.actionCellEmpty} />}
      </td>

      <Dialog
        onClose={() => setShowFileUpload(false)}
        title="File upload"
        Actions={<Button color="primary" label="Close" onClick={() => setShowFileUpload(false)} />}
        open={showFileUpload}
      >
        Please upload the
        {" "}
        <span className={styles.fileIdentification}><FileTypeNiceName type={file.type} /></span>
        {" "}
        for
        {" "}
        {order.company.name}
        .
        <div className={styles.uploadContainer}>
          <FileDropzone
            onFilesAdded={uploadFile}
            helperText="Drag and drop or select"
            errorText={errorMsg}
          />
        </div>
      </Dialog>

      {/* ======= WAITING VALIDATION files : action popups */}
      <Dialog
        onClose={() => setShowFileExaminationModal(false)}
        title="File Examination"
        Actions={ExaminationActionComponent}
        open={showFileExaminationModal}
      >
        <span className={styles.fileIdentification}><FileTypeNiceName type={file.type} /></span>
        <br />
        Do you want to accept this file for
        {" "}
        {order.company.name}
        ?
      </Dialog>

      <Dialog
        onClose={() => {
          setShowFileRefusedModal(false);
          setRefusalReason("");
          setRefusalReasonOther("");
        }}
        title="File Refused"
        open={showFileRefusedModal}
        Actions={RefuseActionComponent}
      >
        <div style={{ minHeight: "360px" }}>
          {order.company.name}
          {" "}
          :
          {" "}
          <span className={styles.fileIdentification}><FileTypeNiceName type={file.type} /></span>
          <br />

          <Select
            label="Why are you refusing this file ?"
            value={refusalReason}
            options={refusal_reason_options}
            onChange={(value) => setRefusalReason(value)}
          />

          {refusalReason === "OTHER" && (
            <>
              <p className={styles.commentTitle}>Comment</p>
              <textarea
                className={styles.commentInput}
                placeholder="More details... (255 characters max)"
                value={refusalReasonOther}
                onChange={(e) => setRefusalReasonOther(e.target.value)}
                maxLength="255"
                rows="6"
                cols="54"
              />
            </>
          )}
        </div>
      </Dialog>
    </tr>
  );
};

export default FileValidationSubRow;
