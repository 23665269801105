import React, { useCallback, useContext, useState } from "react";
import {
  Alert, Button, Dialog, FileDropzone, Heading, Layout, Text,
} from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import { fileChecking, xlsExtensions } from "../../helpers/FilesHelpers";

const acceptedMimeTypes = {
  csv: ["application/vnd.ms-excel", "text/csv"],
  xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
};

const isXLSX = (fileType) => acceptedMimeTypes.xlsx.includes(fileType);

const RenewalImportModal = ({ onClose, open }) => {
  const { user: adminUser } = useContext(UserContext);
  const [fileData, setFileData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isImported, setIsImported] = useState(false);

  const onSave = async () => {
    setIsLoading(true);
    const response = await adminUser.api.renewalImport(fileData);

    if (response.data.duplicatesSerialNumbers.length) {
      setErrorMsg(`Warning ! The following serial numbers refer to several devices (duplicates) : ${response.data.duplicatesSerialNumbers.toString().replace(/,/g, " - ")}`);
    }

    if (response) {
      setIsImported(true);
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    if (!isLoading && fileData) {
      onSave();
    }
  };

  const handleFile = useCallback((files) => {
    const errMsg = fileChecking(files[0], xlsExtensions);

    if (errMsg) {
      setErrorMsg(errMsg);

      return;
    }
    setErrorMsg(null);

    import("xlsx").then((XLSX) => {
      try {
        const file = files[0];
        const reader = new FileReader();
        const isExcel = isXLSX(file.type);

        if (isExcel) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsText(file);
        }

        reader.onload = () => {
          const fileData = reader.result;
          const wb = XLSX.read(fileData, {
            type: isExcel ? "binary" : "string",
            cellDates: true,
          });
          const sheetName = wb.SheetNames[0];
          const fetchedData = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
            raw: false,
          });

          if (!fetchedData.length) {
            setErrorMsg("File is not formatted correctly");
          } else {
            const formattedData = fetchedData.map((device) => {
              const formattedDevice = {};

              // S/N : no spaces
              formattedDevice.serial_number = device["Serial Number"].replace(/\s/g, "");
              formattedDevice.model = device.Model;
              // Price : no spaces, no "," and no "€"
              formattedDevice.price = device.Price.replace(/€|\s/g, "").replace(/,/g, ".");
              formattedDevice.grade = device.Grade;

              return formattedDevice;
            });

            setFileData(formattedData);
          }
        };
        reader.onerror = () => {
          setErrorMsg("File can't be read");
        };
      } catch (err) {
        setErrorMsg(err);
      }
    });
  }, [setErrorMsg, setFileData]);

  const ActionComponent = (
    <>
      {!isImported && <Button color="secondary" label="Close" onClick={onClose} />}
      <Button color="primary" label={isImported ? "Close" : "Upload"} onClick={isImported ? onClose : onSubmit} />
    </>
  );

  return (
    <Dialog
      onClose={onClose}
      title="Import Broker Data"
      Actions={ActionComponent}
      open={open}
    >
      <Layout direction="column" spacing={2} alignItems="center">
        {fileData
          ? (
            <>
              <Heading variant="h3" color="primary">PREVIEW OF FIRST ELEMENT</Heading>
              <Layout direction="row" spacing={8}>
                <Layout direction="column" spacing={2} alignItems="flex-end">
                  <Text variant="body1">Serial Number :</Text>
                  <Text variant="body1">Model :</Text>
                  <Text variant="body1">Price :</Text>
                  <Text variant="body1">Grade :</Text>
                </Layout>
                <Layout direction="column" spacing={2} alignItems="center">
                  <Text bold>{fileData[0]?.serial_number}</Text>
                  <Text variant="body1">{fileData[0]?.model}</Text>
                  <Text bold>{fileData[0]?.price}</Text>
                  <Text bold>{fileData[0]?.grade}</Text>
                </Layout>
              </Layout>
            </>
          )
          : (
            <FileDropzone
              onFilesAdded={handleFile}
              acceptedExtensions={xlsExtensions}
              helperText="Drag and drop or select"
            />
          )}
        {errorMsg && <Alert severity="error" onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}
      </Layout>
    </Dialog>
  );
};

export default RenewalImportModal;
