import React, { useState } from "react";
import { Icon } from "@fleet.co/tarmac";
import {
  faEye, faFileAlt, faFileContract, faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import FileTypeNiceName from "../../blocks/FileTypeNiceName";
import DeleteConfirmModal from "../../blocks/DeleteConfirmModal";

import styles from "../company/CompanyFileRow.module.scss"; // à retravailler

const OrderFileRow = (props) => {
  const {
    key, file, company, order, deleteAction, openFile,
  } = props;
  const [showConfirmModal, setshowConfirmModal] = useState(false);

  const handleShowConfirmDelete = () => {
    setshowConfirmModal(!showConfirmModal);
  };

  const fileTypeIcon = {
    CONTRACT: faFileContract,
    FLEET_INSURANCE: faFileContract,
    FLEET_INSURANCE_INVOICE: faFileAlt,
    ORDER_FORM_SIGNED: faFileAlt,
    SEPA_MANDATE: faFileAlt,
    DELIVERY_RECEIPT: faFileAlt,
    SHIPPING_NOTICE: faFileAlt,
  };

  return (
    <tr key={key}>
      <td>
        <div className={styles.flex}>
          <div className={styles.typeIconContainer}>
            <Icon className={styles.typeIcon} icon={fileTypeIcon[file.type]} color="secondary" size="S" />
          </div>
          <FileTypeNiceName type={file.type} />
        </div>
      </td>

      <td>
        {`${company ? company.name : ""} - `}
        <FileTypeNiceName type={file.type} />
        {` - ${order.order_number}`}
      </td>
      <td className="flex-row">
        <div className={styles.actionCellContainer}>
          <div className={styles.actionIconContainer}>
            <Icon icon={faEye} onClick={openFile} color="secondary" size="S" />
          </div>
          <div className={styles.actionIconContainer}>
            <Icon className={styles.trash} icon={faTrashAlt} onClick={handleShowConfirmDelete} color="secondary" size="S" />
          </div>
        </div>
      </td>

      <DeleteConfirmModal closeAction={handleShowConfirmDelete} deleteAction={deleteAction} target="this file" open={showConfirmModal} />
    </tr>
  );
};

export default OrderFileRow;
