import React, { useCallback, useMemo, useState } from "react";
import {
  Button, Dialog, Heading, Layout, Text,
  TextField,
} from "@fleet.co/tarmac";
import { useLogistics } from "../../../contexts/LogisticsContext";

const LogisticsHeader = () => {
  const { setIsMassEditModalOpen, deleteSelectedDevices, massEditDevices } = useLogistics();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteCount, setDeleteCount] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const shouldDisplayMassEdit = massEditDevices.length > 1;

  const confirmDeleteDevices = useCallback(async () => {
    if (Number(deleteCount) !== Number(massEditDevices.length)) {
      return;
    }
    setLoading(true);
    await deleteSelectedDevices();
    setLoading(false);
    setDeleteCount(undefined);
    setConfirmDeleteModal(false);
  }, [deleteCount, deleteSelectedDevices, massEditDevices.length]);

  const ActionComponent = useMemo(() => (
    <Layout direction="row">
      <Button color="error" label="Yes" onClick={confirmDeleteDevices} loading={loading} />
      <Button color="secondary" label="No" onClick={() => setConfirmDeleteModal(false)} loading={loading} />
    </Layout>
  ), [confirmDeleteDevices, loading]);

  return (
    <>
      <Layout direction="row" justifyContent="space-between">
        <Heading variant="h2">
          Logistics
        </Heading>
        {shouldDisplayMassEdit && (
          <Layout direction="row" gap={1} alignItems="center">
            <Text variant="body2" color="textSecondary">
              {`${massEditDevices.length} devices selected`}
            </Text>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setIsMassEditModalOpen(true)}
              label="Mass edit"
            />
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setConfirmDeleteModal(true)}
              label="Delete devices"
            />
          </Layout>
        )}
      </Layout>
      <Dialog
        title="Delete selected devices"
        onClose={() => setConfirmDeleteModal(false)}
        Actions={ActionComponent}
        open={confirmDeleteModal}
      >
        <Layout direction="column" spacing={2}>
          <Text>
            This action will permanently delete
            <Text variant="span" color="error" bold>
              {" "}
              {massEditDevices?.length}
              {" "}
            </Text>
            devices. Would you like to
            continue ?
          </Text>
          <Text>
            Devices with MDM subscriptions won't be deleted.
          </Text>
          <Text>
            To confirm, type the number of devices to delete:
          </Text>
          <TextField
            label="Number of devices to delete"
            type="number"
            error={deleteCount !== massEditDevices.length}
            value={deleteCount}
            onChange={(e) => setDeleteCount(e.target.value)}
          />
        </Layout>
      </Dialog>
    </>
  );
};

export default LogisticsHeader;
