import React, { useContext, useEffect, useState } from "react";

import {
  Heading, Icon, TabItem, Tabs,
} from "@fleet.co/tarmac";
import { faChevronDown, faChevronRight, faChevronUp } from "@fortawesome/pro-regular-svg-icons";

import qs from "qs";
import styles from "./Deliveries.module.scss";
import UserContext from "../../tools/UserContext";
import DeliveryRow from "../tables/deliveries/DeliveryRow";
import LoadingSpinner from "../common/LoadingSpinner";

const Deliveries = () => {
  const { user: adminUser } = useContext(UserContext);
  const [allOrdersDelivered, setAllOrdersDelivered] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortingRule, setSortingRule] = useState({
    column: "date",
    direction: true,
  });
  const [countryFilter, setCountryFilter] = useState("France");

  const countriesList = [
    {
      name: "France",
      value: "France",
    },
    {
      name: "Spain",
      value: "Spain",
    },
    {
      name: "Germany",
      value: "Germany",
    },
  ];

  const loadOrders = async () => {
    setIsLoading(true);
    const initialFilters = {
      status: ["DELIVERED"],
      number_exclude: 0,
    };

    const params = {
      ...initialFilters,
      ...(countryFilter && { country: countryFilter }),
    };

    const endpoint = `?${qs.stringify(params)}`;
    const fetchedOrders = await adminUser.api.getOrders(endpoint);

    setAllOrdersDelivered(fetchedOrders.data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadOrders();
  }, [countryFilter]);

  // Sort Table by Date or NET
  const sortTable = (a, b) => {
    if (sortingRule.column === "date") {
      return sortingRule.direction
        ? a.delivery_date?.localeCompare(b.delivery_date)
        : b.delivery_date?.localeCompare(a.delivery_date);
    }

    if (sortingRule.column === "NET") {
      return sortingRule.direction
        ? (a.rent * a.contract_duration).toString().localeCompare((b.rent * b.contract_duration).toString(), undefined, { numeric: true })
        : (b.rent * b.contract_duration).toString().localeCompare((a.rent * a.contract_duration).toString(), undefined, { numeric: true });
    }

    return 0;
  };

  const handleSortClick = (column) => {
    setSortingRule({
      column,
      direction: !sortingRule.direction,
    });
  };

  // Pre Order by Date
  let ordersToDisplay = [];

  ordersToDisplay = allOrdersDelivered.sort(sortTable);

  const getSortingIcon = (columnName) => {
    if (sortingRule.column === columnName) {
      return faChevronRight;
    }

    return sortingRule.direction ? faChevronDown : faChevronUp;
  };

  const iconDate = getSortingIcon("NET");
  const iconNET = getSortingIcon("date");

  const handleChange = (value) => {
    setCountryFilter(value);
  };

  if (isLoading) {
    return <LoadingSpinner isFullScreen={false} />;
  }

  return (
    <>
      <Heading variant="h3" className="title">Delivery Signing</Heading>

      <Tabs
        fullWidth
        scrollButtons
        variant="scrollable"
        value={countryFilter}
        onChange={(e, value) => handleChange(value)}
      >
        {countriesList.map((country) => <TabItem key={country.name} value={country.value} label={country.name} />)}
      </Tabs>

      <table className="table back-office-table">
        <thead>
          <tr>
            <td style={{ width: "12%" }}>Contract ID</td>
            <td style={{ width: "15%" }}>Order Number</td>
            <td>Company</td>
            <td style={{ width: "15%" }}>
              <span className={styles.pointer} onClick={() => handleSortClick("date")}>
                Delivery Date
                {" "}
                <Icon icon={iconDate} color="secondary" size="S" />
              </span>
            </td>
            <td style={{ width: "15%" }}>Serial Number</td>
            <td>Nbr Devices</td>
            <td>
              <span className={styles.pointer} onClick={() => handleSortClick("NET")}>
                NET revenue
                {" "}
                <Icon icon={iconNET} color="secondary" size="S" />
              </span>
            </td>
            <td>Leasers</td>
            <td style={{ width: "20%" }}>Actions</td>
          </tr>
        </thead>
        <tbody>
          {ordersToDisplay.map((order) => (
            <DeliveryRow
              order={order}
              loadOrders={loadOrders}
              key={order.id}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Deliveries;
