// components/auth/auth-service.js

import API from "../../tools/API";
import User from "../../tools/User";

class AuthService {
  constructor() {
    this.api_prefix = "/auth";
  }

  login = (email, password) => API.post(`${this.api_prefix}/login`, { email, password }).then(({ data: { accessToken, ...userInfo } }) => {
    window.localStorage.setItem("accessToken", accessToken);
    const user = new User();

    Object.assign(user, userInfo);

    return user.load();
  });

  loggedin = () => API.get("/auth/current").then((response) => {
    const user = new User();

    Object.assign(user, response.data);

    return user.load();
  });
}

export default AuthService;
