import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from "react";
import UserContext from "../tools/UserContext";
import useLocalStorage from "../hooks/useLocalStorage";

const LogisticsContext = createContext();

export const useLogistics = () => useContext(LogisticsContext);

const defaultFilters = [
  {
    id: "status",
    field: "status",
    operator: "isAnyOf",
    value: ["READY_FOR_PURCHASE"],
  },
  {
    id: "only_missing_serial_number",
    field: "only_missing_serial_number",
    operator: "equals",
    value: false,
  },
];

export const LogisticsProvider = ({ children }) => {
  const { user: adminUser } = useContext(UserContext);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isEditDeviceModalOpen, setIsEditDeviceModalOpen] = useState(false);
  const [massEditDevices, setMassEditDevices] = useState([]);
  const [isMassEditModalOpen, setIsMassEditModalOpen] = useState(false);

  const [filtersFromLocalStorage, setFiltersFromLocalStorage] = useLocalStorage("logistics_filters", JSON.stringify(defaultFilters));

  const initialFilters = typeof filtersFromLocalStorage === "string" ? JSON.parse(filtersFromLocalStorage) : filtersFromLocalStorage;
  const [filters, setFilters] = useState(initialFilters);

  // Fetch devices
  const fetchDevices = useCallback(async () => {
    try {
      setLoading(true);

      const orderStatusFilter = {
        field: "order_status",
        value: ["READY_FOR_PURCHASE", "PURCHASED", "SHIPPED", "DELIVERED", "BDL_SENT", "RECEIVED"],
      };

      const requestParams = {
        params: [...filters, orderStatusFilter].reduce((params, filter) => {
          const { field, value } = filter;

          params[field] = value;

          return params;
        }, {}),
      };

      const { data: fetchedDevices } = await adminUser.api.getDevices(requestParams);

      const deviceGroups = fetchedDevices.reduce((groups, device) => {
        const key = `${device.product.id}-${device.shipping_address_id}-${device.keyboard_layout}-${device.status}`;
        const group = groups.get(key) || [];

        const newDevice = {
          ...device,
          key,
          packageInfo: {
            itemPosition: group.length + 1, // position before the new device is added
          },
        };

        group.push(newDevice);

        // update nbIdenticalItems for all devices in the group
        group.forEach((device) => {
          device.packageInfo.nbIdenticalItems = group.length;
        });

        groups.set(key, group);

        return groups;
      }, new Map());

      const devicesWithPackageInfo = Array.from(deviceGroups.values()).flat();

      setDevices(devicesWithPackageInfo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching devices:", error);
      // Handle error appropriately
    }
  }, [adminUser.api, filters]);

  const handleFilterChange = useCallback((filter, value) => {
    setFilters((prev) => {
      const filterIndex = prev.findIndex((f) => f.field === filter);
      const addedFilter = { field: filter, value, operator: "equals" };

      if (filterIndex === -1) {
        return [...prev, addedFilter];
      }

      const newFilters = [...prev];

      newFilters[filterIndex] = addedFilter;

      return newFilters;
    });
  }, []);

  const handleFilerModelChange = useCallback(({ items }) => {
    setFilters(items);
  }, []);

  // Open the edit modal for a specific device
  const openEditDeviceModal = useCallback((device) => {
    setSelectedDevice(device);
    setIsEditDeviceModalOpen(true);
  }, []);

  // Close the modal
  const closeEditDeviceModal = useCallback(() => {
    setIsEditDeviceModalOpen(false);
    setSelectedDevice(null);
  }, []);

  // Delete the selected devices
  const deleteSelectedDevices = useCallback(async () => {
    setLoading(true);

    for (const device of massEditDevices) {
      try {
        await adminUser.api.deleteDevice(device.id);
      } catch (error) {
        console.error("Error deleting device:", error);
        continue;
      }
    }

    await fetchDevices();

    setLoading(false);
  }, [adminUser.api, fetchDevices, massEditDevices]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    setFiltersFromLocalStorage(JSON.stringify(filters));
  }, [filters]);

  const values = useMemo(() => ({
    devices,
    fetchDevices,
    filters,
    selectedDevice,
    massEditDevices,
    handleFilterChange,
    handleFilerModelChange,
    deleteSelectedDevices,
    setMassEditDevices,
    openEditDeviceModal,
    closeEditDeviceModal,
    setIsMassEditModalOpen,
    isEditDeviceModalOpen,
    isMassEditModalOpen,
    loading,
  }), [fetchDevices, filters, devices, selectedDevice, massEditDevices, openEditDeviceModal, closeEditDeviceModal, setIsMassEditModalOpen, isEditDeviceModalOpen, isMassEditModalOpen, loading, deleteSelectedDevices, handleFilterChange, handleFilerModelChange]);

  return (
    <LogisticsContext.Provider value={values}>
      {children}
    </LogisticsContext.Provider>
  );
};
