import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  organization: "org-VU2dNr4ez7C9ul9lqMuEzoMV",
  apiKey: process.env.REACT_APP_OPENAPI_KEY,
});

const openai = new OpenAIApi(configuration);

const getProductDescription = async (pg) => {
  const content = `Crée moi pour le SEO pour le produit ${pg.name}`;

  const chatCompletion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo",
    messages: [{ role: "user", content }],
  });

  return chatCompletion.data.choices[0].message.content;
};

const getProductMarketingMaterial = async (pg) => {
  const content = `Donne moi au format d'un tableau d'objets JSON avec pour clé title et description trois arguments marketing optimisés pour le SEO pour vendre un ${pg.name}`;

  const chatCompletion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo",
    messages: [{ role: "user", content }],
  });

  const returnedMessage = chatCompletion.data.choices[0].message.content;

  // REGEX : find content that starts with ```json\n and ends with \n```
  // containing whitespace and non-whitespace characters ([\s\S]) one or more times (*)
  // Call that block codeBlock
  // Multiline-mode (m)
  const matchCodeBlock = /(?<jsonBlock>\[[\s\S]*\])/m;

  const match = matchCodeBlock.exec(returnedMessage);

  const jsonFromFile = match.groups.jsonBlock.trim();

  return JSON.parse(jsonFromFile);
};

export { getProductDescription, getProductMarketingMaterial };
