import React, { useState } from "react";
import { Button, Icon } from "@fleet.co/tarmac";
import { faCloudUploadAlt } from "@fortawesome/pro-regular-svg-icons";
import styles from "./UploadZone.module.scss";

const UploadZone = (props) => {
  const { uploadFile, uploadDisabled } = props;
  const [file, setFile] = useState([]);

  const selectFile = (e) => {
    setFile([e.target.files[0]]);
  };

  const clearFile = (e) => {
    e.preventDefault();
    setFile([]);
  };

  return (
    <div className={styles.imageUpload}>
      {!file.length ? (
        <form method="post" encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="file">
            <Icon icon={faCloudUploadAlt} color="secondary" size="S" />
            <div>
              Drag and drop
              {props.children}
            </div>
            <div>OR</div>
            <div className={styles.selectFile}>
              <Button variant="outlined" color="secondary" label="Upload" />
            </div>
          </label>
          <input type="file" id="file" onChange={selectFile} />
        </form>
      ) : (
        <div className={styles.imageSelected}>
          <p>{file[0].name}</p>
          <Button variant="outlined" color="primary" label="Upload File" disabled={uploadDisabled} onClick={() => uploadFile(file)} />
          <div className={styles.cancel} onClick={clearFile}>
            Cancel
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadZone;
