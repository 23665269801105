import React from "react";
import { Layout, Text } from "@fleet.co/tarmac";
import CopyButton from "../../../../tools/CopyButton";

const PurchaserCell = ({ value: purchaser }) => {
  if (!purchaser) {
    return null;
  }

  return (
    <Layout
      direction="column"
      alignItems="center"
    >
      <Text variant="caption">{`${purchaser.firstName} ${purchaser.lastName}`}</Text>
      <CopyButton value={purchaser.email}>
        <Text variant="caption">{purchaser.email}</Text>
      </CopyButton>
    </Layout>
  );
};

export default PurchaserCell;
