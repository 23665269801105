import React from "react";
import { Heading, Text } from "@fleet.co/tarmac";
import BreadcrumbAmount from "../../common/BreadcrumbAmount";
import styles from "./OrderLeaser.module.scss";
import EnvelopeCountdown from "../../blocks/EnvelopeCountdown";
import { formatMoney } from "../../../helpers/formatHelpers";
import { getLeaserLabel } from "../../../tools/OrderTools";

const getLabelColor = (status) => {
  switch (status) {
    case "ACCEPTED":
      return "#0DD27A";
    case "TODO":
      return "#C4CEEA";
    case "CLOSED":
      return "#FB8B8B";
    case "REJECTED":
      return "#F5ABAB";
    case "FUNDING_REQUESTED":
      return "#F8CCA4";
    case "FUNDING_MORE_DOCUMENTS":
      return "#F9E79F";
    default:
      return "#C4CEEA";
  }
};

const CompanyEnvelopeInfo = (props) => {
  const { envelopeInfos, order } = props;

  return envelopeInfos && (
    <section className={styles.envelopes_section}>
      <Heading variant="h6">
        Funding envelopes
      </Heading>
      <div className={styles.wrapper}>
        <div className={styles.envelope_info}>
          <Text variant="body2">STATUS</Text>
          <Text
            variant="body2"
            bold
            sx={{ color: getLabelColor(envelopeInfos.status) }}
          >
            {envelopeInfos.status}
          </Text>
        </div>
        <div className={styles.envelope_info}>
          <Text variant="body2">LEASER</Text>
          <p>{getLeaserLabel(envelopeInfos.leaser)}</p>
        </div>
        <div className={styles.envelope_info}>
          <Text variant="body2">REQUEST ID</Text>
          <p>{envelopeInfos.request_id}</p>
        </div>
        <div className={styles.envelope_info}>
          <Text variant="body2">TIME LEFT</Text>
          <EnvelopeCountdown envelope={envelopeInfos} />
        </div>

        <div className={styles.envelope_info}>
          <Text variant="body2">AMOUNT LEFT</Text>
          {formatMoney(envelopeInfos.current_amount, order.rent_currency)}
          <BreadcrumbAmount
            total_amount={envelopeInfos.total_amount}
            current_amount={envelopeInfos.current_amount}
          />
        </div>
        <div className={styles.envelope_info}>
          <Text variant="body2">AMOUNT SPENT</Text>
          <span className={styles.no_margin_left}>
            {formatMoney(envelopeInfos.total_amount - envelopeInfos.current_amount, order.rent_currency)}
          </span>
        </div>
        <div className={styles.envelope_info}>
          <Text variant="body2">AMOUNT GRANTED</Text>
          <span className={styles.no_margin_left}>
            {formatMoney(envelopeInfos.total_amount, order.rent_currency)}
          </span>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default CompanyEnvelopeInfo;
