import React, { useContext, useEffect, useState } from "react";

import {
  Badge, Heading, Icon, Layout, TabItem, Tabs,
} from "@fleet.co/tarmac";
import { faChevronDown, faChevronRight, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";
import UserContext from "../../tools/UserContext";
import FileValidationRow from "../tables/fileValidation/FileValidationRow";

import styles from "./FileValidation.module.scss";

const FileValidation = () => {
  const { user: adminUser } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("LEASER_MORE_DOCUMENTS");
  const [sortingRule, setSortingRule] = useState({
    column: "company",
    direction: true,
  });

  const loadOrders = async () => {
    const params = { status: ["LEASER_MORE_DOCUMENTS", "LEASER_REQUESTED"] };
    const endpoint = `?${qs.stringify(params)}`;
    const fetchedOrders = await adminUser.api.getOrders(endpoint);

    setOrders(fetchedOrders.data);
  };

  useEffect(() => {
    loadOrders();
  }, []);

  // get the company files to be displayed for each order
  const getCompanyFilesToDisplay = (order) => {
    let currentTypes;

    if (orderStatus === "LEASER_REQUESTED") {
      // MISSING INFO -> ERROR
      if (!order.signatory_id) {
        return null;
      }

      const signatory = order.company.members.find((member) => member.id === order.signatory_id);

      const isSignatory = signatory.roles.some((role) => role.role === "LEGAL_REPRESENTATIVE");

      currentTypes = isSignatory ? ["SIGNATORY_ID", "SIGNATORY_ID_VERSO", "IBAN"] : ["SIGNATORY_ID", "SIGNATORY_ID_VERSO", "SIGNING_AUTHORITY", "IBAN"];
    } else {
      currentTypes = ["BALANCE_SHEET"];
    }

    // display only the required type of documents depending on the order status
    const filesToDisplay = order.company.files.filter(
      (file) => currentTypes.includes(file.type) && file.status !== "REFUSED",
    );

    // ADD EMPTY LINES
    // display an empty line for each missing required document (not uploaded or refused)
    currentTypes.forEach((type) => {
      if (!filesToDisplay.some((file) => file.type === type)) {
        // TYPE IS MISSING
        // if file(s) of this type were refused before, display last notification date
        const refusedFiles = order.company.files.filter((file) => file.type === type && file.status === "REFUSED");

        let lastrefusal = null;

        if (refusedFiles.length !== 0) {
          // Returns 0 or Last Refusal Date
          lastrefusal = refusedFiles.reduce(
            (acc, file) => (Date.parse(file.last_notification) > Date.parse(acc) ? file.last_notification : acc),
            "1970",
          );
        }
        filesToDisplay.push({
          type, status: "EMPTY", created_at: null, last_notification: lastrefusal,
        });
      }
    });

    // if status is null change it to TO_EXAMINE
    filesToDisplay.forEach((file) => {
      if (!file.status) {
        file.status = "TO_EXAMINE";
      }
    });

    // order files by status TO_EXAMINE → EMPTY → ACCEPTED
    const file_status = ["TO_EXAMINE", "EMPTY", "ACCEPTED"];

    filesToDisplay.sort((a, b) => file_status.indexOf(a.status) - file_status.indexOf(b.status));

    return filesToDisplay;
  };

  const getGlobalFilesStatus = (files) => {
    if (files === null) return "ERROR";

    if (files.every((f) => f.status === "ACCEPTED")) return "VALIDATED";

    if (files.some((f) => f.status === "EMPTY")) return "INCOMPLETE";

    return "WAITING_VALIDATION";
  };

  const sortTable = (a, b) => {
    if (sortingRule.column === "company") {
      return sortingRule.direction
        ? a.company.name.localeCompare(b.company.name)
        : b.company.name.localeCompare(a.company.name);
    }

    if (sortingRule.column === "status") {
      // Order
      const file_status = ["INCOMPLETE", "WAITING_VALIDATION", "VALIDATED"];

      return sortingRule.direction
        ? file_status.indexOf(a.globalFileStatus) - file_status.indexOf(b.globalFileStatus)
        : file_status.indexOf(b.globalFileStatus) - file_status.indexOf(a.globalFileStatus);
    }

    if (sortingRule.column === "updated_at") {
      const sortByUpdateDate = (o1, o2) => o2.updated_at - o1.updated_at;
      const a_AllFiles = a.filesToDisplay;
      const b_AllFiles = b.filesToDisplay;
      const a_LastUpdateFile = a_AllFiles.sort(sortByUpdateDate)[0];
      const b_LastUpdateFile = b_AllFiles.sort(sortByUpdateDate)[0];

      return sortingRule.direction
        ? a_LastUpdateFile.updated_at.localeCompare(b_LastUpdateFile.updated_at)
        : b_LastUpdateFile.updated_at.localeCompare(a_LastUpdateFile.updated_at);
    }

    return 0;
  };

  const handleSortClick = (column) => {
    setSortingRule({
      column,
      direction: !sortingRule.direction,
    });
  };

  let ordersToDisplay = [];

  // Only show orders corresponding to current filter
  ordersToDisplay = orders.filter((o) => o.status === orderStatus);

  // Add global File info & prepare File data
  ordersToDisplay.map((order) => {
    order.filesToDisplay = getCompanyFilesToDisplay(order);
    order.globalFileStatus = getGlobalFilesStatus(order.filesToDisplay);

    return order;
  });
  // Sort orders
  ordersToDisplay = ordersToDisplay.sort(sortTable);

  const countLeaserMoreDocs = orders.filter((o) => o.status === "LEASER_MORE_DOCUMENTS").length;
  const countLeaserRequested = orders.filter((o) => o.status === "LEASER_REQUESTED").length;

  const getSortingIcon = (columnName) => {
    if (sortingRule.column === columnName) {
      return faChevronRight;
    }

    return sortingRule.direction ? faChevronDown : faChevronUp;
  };

  const iconCompany = getSortingIcon("status");
  const iconStatus = getSortingIcon("company");
  const iconDate = getSortingIcon("updated_at");

  return (
    <Layout direction="column" spacing={2}>
      <Heading variant="h2">File Validation</Heading>
      <Tabs value={orderStatus} onChange={(e, value) => setOrderStatus(value)}>
        <TabItem value="LEASER_MORE_DOCUMENTS" label={<Badge badgeContent={countLeaserMoreDocs} color="green">Leaser More Docs</Badge>} />
        <TabItem value="LEASER_REQUESTED" label={<Badge badgeContent={countLeaserRequested} color="green">Leaser Requested</Badge>} />
      </Tabs>

      <table className="table back-office-table">
        <thead>
          <tr>
            <td style={{ width: "15%" }}>Order Request N°</td>
            <td style={{ width: "10%", cursor: "pointer" }} onClick={() => handleSortClick("company")}>
              Company
              {" "}
              <Icon icon={iconCompany} color="secondary" size="S" />
            </td>
            <td style={{ width: "20%" }}>Customer</td>
            <td
              style={{ width: "15%", cursor: "pointer" }}
              className={styles.filesStatusTd}
              onClick={() => handleSortClick("status")}
            >
              Files Status
              {" "}
              <Icon icon={iconStatus} color="secondary" size="S" />
            </td>
            <td style={{ width: "10%" }}>Order Date</td>
            <td style={{ width: "12%", textAlign: "right" }}>Invoicing amount</td>
            <td style={{ width: "10%" }}>Leaser Name</td>
            <td style={{ width: "10%" }} onClick={() => handleSortClick("updated_at")}>
              Last Update
              <Icon icon={iconDate} color="secondary" size="S" />
            </td>
            <td style={{ width: "8%" }}>Actions</td>
          </tr>
        </thead>
        <tbody>
          {ordersToDisplay.map((order) => (
            <FileValidationRow order={order} key={order.id} reload={loadOrders} />
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default FileValidation;
