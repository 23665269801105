import axios from "axios";

class ApiService {
  constructor() {
    let base_url = "";

    // PR Number given : Query Preview URL
    if (process.env.REACT_APP_PR_NUMBER) {
      base_url = `https://fleet-co-pr-${process.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
    } else {
      base_url = process.env.REACT_APP_APIURL || "/v1";
    }

    this.axios = axios.create({
      baseURL: base_url,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    });
  }

  /* ---------- SEARCH BAR ---------- */

  useGlobalSearchBar = (query) => this.axios.get(`/search${query}`);

  /* ---------- COMPANY ---------- */

  getCompanies = (endpoint_query) => this.axios.get(`/company${endpoint_query}`);

  getCompany = (company_id) => this.axios.get(`/company/${company_id}`);

  modifyCompany = (company_id, company_infos) => this.axios.put(`/company/${company_id}`, company_infos);

  modifyCompanyLogo = (company_id, company_infos) => this.axios.patch(`/company/${company_id}/image`, company_infos);

  getCompanySignatory = (company_id) => this.axios.get(`/company/${company_id}/signatory`);

  getPaymentMethods = (companyId) => this.axios.get(`/company/${companyId}/payment_method`);

  /* ---------- ADDRESS ---------- */

  getAddresses = (query_endpoint) => this.axios.get(`/address${query_endpoint}`);

  getAddress = (address_id) => this.axios.get(`/address/${address_id}`);

  addAddress = (address_infos) => this.axios.post("/address", address_infos);

  modifyAddress = (address_id, address_infos) => this.axios.put(`/address/${address_id}`, address_infos);

  makeAddressDefault = (address_id, address_infos) => this.axios.patch(`/address/${address_id}/default`, address_infos);

  makeAddressHq = (address_id, address_infos) => this.axios.patch(`/address/${address_id}/hq`, address_infos);

  deleteAddress = (address_id) => this.axios.delete(`/address/${address_id}`);

  /* ---------- DEVICES ---------- */

  getDevices = (params) => this.axios.get("/device", params);

  addDevice = (device_infos) => this.axios.post("/device", device_infos);

  modifyDevice = (device_id, device_infos) => this.axios.put(`/device/${device_id}`, device_infos);

  deleteDevice = (device_id) => this.axios.delete(`/device/${device_id}`);

  updateDevicePenalty = (device_id, penaltyData) => this.axios.patch(`/device/${device_id}/penalty/`, penaltyData);

  renewalImport = (importData) => this.axios.patch("/device/renewal_import", importData);

  resetDeviceMdmEnrollment = (deviceId) => this.axios.post(`/device/${deviceId}/restart-mdm-enrollment`);

  //   /* ---------- ORDERS ---------- */

  requestLeaser = (order_id, request_info) => this.axios.post(`/order/${order_id}/financing_request`, request_info);

  getOrders = (endpoint_query) => this.axios.get(`/order${endpoint_query}`);

  getOrder = (order_id) => this.axios.get(`/order/${order_id}`);

  modifyOrder = (order_id, order_infos) => this.axios.put(`/order/${order_id}`, order_infos);

  getOrderFiles = (order_id) => this.axios.get(`/order/${order_id}/files`);

  getOrderZipFiles = (orderId) => this.axios.get(`/order/${orderId}/download_archive`, { responseType: "blob" });

  sendInvoice = (order_id) => this.axios.post(`/order/${order_id}/send_invoice`);

  // sendRenewalReminder = (order_id) => this.axios.post(`/order/${order_id}/send_renewal_reminder`);

  // sendRenewalReturnReminder = (order_id) => this.axios.post(`/order/${order_id}/send_renewal_return_reminder`);

  upgradeTheOrder = (order_id, params) => this.axios.post(`/order/${order_id}/upgrade`, params);

  moveOrderToPurchaser = (order_id, params) => this.axios.post(`/order/${order_id}/move_to_purchaser`, params);

  getSubscription = (order_id) => this.axios.get(`/order/${order_id}/subscription`);

  cancelInsurance = (order_id) => this.axios.post(`/order/${order_id}/insurance_cancel`);

  sendDocumentsToLeaser = (order_id) => this.axios.post(`/order/${order_id}/send_documents_to_leaser`);

  getFleetInsurancePrice = (order_id) => this.axios.get(`/order/${order_id}/insurance_offer_price`);

  //   /* ---------- TICKETS ---------- */

  getTicket = (ticket_id) => this.axios.get(`/ticket/${ticket_id}`);

  modifyTicket = (ticket_id, ticket_infos) => this.axios.patch(`/ticket/${ticket_id}`, ticket_infos);

  //   /* ---------- ORDERS ---------- */

  getUnpaid = () => this.axios.get("/unpaid_orders");

  batchUpdateUnpaid = (unpaidOrdersInfos) => this.axios.put("/unpaid_orders/batch", unpaidOrdersInfos);

  //   /* ---------- E-CONTRACT ---------- */

  generateContract = (order_id) => this.axios.post(`/order/${order_id}/generate_contract`);

  sendLeaserPackage = (order_id, order_infos) => this.axios.post(`/order/${order_id}/send_leaser_package`, order_infos);

  cancelEcontracts = (order_id) => this.axios.post(`/order/${order_id}/cancel_econtracts`);

  //   /* ---------- USERS ---------- */

  getUsers = (endpoint_query) => this.axios.get(`/user${endpoint_query}`);

  getUser = (user_id) => this.axios.get(`/user/${user_id}`);

  addUser = (user_infos) => this.axios.post("/user", user_infos);

  modifyUser = (user_id, user_infos) => this.axios.put(`/user/${user_id}`, user_infos);

  deleteUser = (user_id) => this.axios.delete(`/user/${user_id}`);

  connectAs = (user_id) => this.axios.post(`/user/${user_id}/connect_as`);

  migrateUser = (user_id, user_infos) => this.axios.patch(`/user/${user_id}/migrate`, user_infos);

  sendAccess = (user_infos) => this.axios.post("/auth/request_magic_link", user_infos);

  //   /* ---------- OFFERS ---------- */

  getOffers = () => this.axios.get("/offer");

  addOffer = (offer_infos) => this.axios.post("/offer/", offer_infos);

  modifyOffer = (offer_id, offer_infos) => this.axios.put(`/offer/${offer_id}`, offer_infos);

  deleteOffer = (offer_id) => this.axios.delete(`/offer/${offer_id}`);

  //   /* ---------- FILES (COMPANY LEVEL) ---------- */
  getFiles = (endpoint_query) => this.axios.get(`/file${endpoint_query}`);

  getFile = (file_id) => this.axios.get(`/file/${file_id}`, { responseType: "blob" });

  addFile = (file_infos) => this.axios.post("/file", file_infos);

  modifyFile = (file_id, file_infos) => this.axios.put(`/file/${file_id}`, file_infos);

  deleteFile = (file_id) => this.axios.delete(`/file/${file_id}`);

  //   /* ---------- FILES (ORDER LEVEL) ---------- */
  deliveryForm = (orderId) => this.axios.post(`/order/${orderId}/delivery_form`, null, { responseType: "blob" });

  orderForm = (orderId) => this.axios.post(`/order/${orderId}/order_form`, null, { responseType: "blob" });

  invoiceForm = (orderId) => this.axios.post(`/order/${orderId}/invoice`, null, { responseType: "blob" });

  insuranceForm = (orderId) => this.axios.post(`/order/${orderId}/insurance_certificate`);
  //   /* ---------- FUNDING ENVELOPES ---------- */

  getEnvelopes = () => this.axios.get("/funding_envelope");

  getEnvelope = (envelope_id) => this.axios.get(`/funding_envelope/${envelope_id}`);

  addEnvelope = (envelope_infos) => this.axios.post("/funding_envelope", envelope_infos);

  modifyEnvelope = (envelope_id, envelope_infos) => this.axios.put(`/funding_envelope/${envelope_id}`, envelope_infos);

  //   /* ---------- BANNER ---------- */

  getBanner = () => this.axios.get("/catalog_banner");

  addBanner = (bannerInfos) => this.axios.post("/catalog_banner", bannerInfos);

  modifyBanner = (bannerId, bannerInfos) => this.axios.put(`/catalog_banner/${bannerId}`, bannerInfos);

  //   /* ---------- PRODUCT GROUPS ---------- */

  getProductGroups = () => this.axios.get("/product_group");

  getProductGroup = (pg_id) => this.axios.get(`/product_group/${pg_id}`);

  addProductGroup = (pg_infos) => this.axios.post("/product_group", pg_infos);

  modifyProductGroup = (pg_id, pg_infos) => this.axios.put(`/product_group/${pg_id}`, pg_infos);

  deleteProductGroup = (pg_id) => this.axios.delete(`/product_group/${pg_id}`);

  //   /* ---------- PRODUCTS ---------- */

  getProducts = () => this.axios.get("/product");

  getProduct = (product_id) => this.axios.get(`/product/${product_id}`);

  addProduct = (product_infos) => this.axios.post("/product", product_infos);

  modifyProduct = (product_id, product_infos) => this.axios.put(`/product/${product_id}`, product_infos);

  changeProductVisibility = (product_id, visibility) => this.axios.patch(`/product/${product_id}/visibility`, { visibility });

  deleteProduct = (product_id) => this.axios.delete(`/product/${product_id}`);

  //   /* ---------- PARCEL ---------- */

  getParcel = (parcel_id) => this.axios.get(`/device/parcel/${parcel_id}`);

  //   /* ---------- COMPANY DISCOUNT ---------- */

  modifyCompanyDiscount = (company_id, discount) => this.axios.put(`/company/${company_id}/discount`, discount);

  addCompanyDiscount = (company_id, discount) => this.axios.post(`/company/${company_id}/discount`, discount);

  //   /* ---------- DEV TOOLS ---------- */
  sendYousignWebhook = (procedureId, memberId) => this.axios.post(
    "/yousign/webhook/member_finished",
    {
      procedure: {
        id: procedureId,
      },
      member: {
        id: memberId,
      },
    },
  );

  finishYousignProcedure = (procedureId) => this.axios.post(
    "/yousign/webhook/procedure_finished",
    {
      procedure: {
        id: procedureId,
      },
    },
  );
}

export default ApiService;
