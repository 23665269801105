import React from "react";
import {
  Button, DatePicker, Layout, Popover, Select, Text,
} from "@fleet.co/tarmac";
import { faBan } from "@fortawesome/pro-regular-svg-icons";

const ContractDecisionFilterMenu = (props) => {
  const {
    filters, setFilters, resetFilters,
  } = props;

  const selectedContractMinDate = filters.minDate ? new Date(filters.minDate) : null;
  const selectedContractMaxDate = filters.maxDate ? new Date(filters.maxDate) : null;

  const statusFilterOptions = [
    {
      label: "Extended",
      value: "EXTENDED",
    },
    {
      label: "Expire soon",
      value: "EXPIRE_SOON",
    },
    {
      label: "Done",
      value: "DONE",
    },
    {
      label: "All",
      value: "ALL",
    },
  ];

  const renewalTypeFilterOptions = [
    {
      label: "Stop",
      value: "STOP",
    },
    {
      label: "Renew",
      value: "RENEW",
    },
    {
      label: "Buyback",
      value: "BUYBACK",
    },
    {
      label: "Lost",
      value: "LOST",
    },
    {
      label: "Broken",
      value: "BROKEN",
    },
  ];

  return (
    <Popover
      AnchorComponent={Button}
      anchorProps={{ variant: "outlined", color: "primary", label: "Filters" }}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
    >
      <Layout direction="column" spacing={2}>
        <Select
          label="Filter by status"
          options={statusFilterOptions}
          value={filters.status}
          onChange={(value) => setFilters({ ...filters, status: value })}
        />
        <Select
          label="Filter by renewal decision"
          multiple
          options={renewalTypeFilterOptions}
          value={filters.deviceRenewalType}
          onChange={(value) => setFilters({ ...filters, deviceRenewalType: value })}
        />
        <Text variant="body1">Select contract end date range</Text>
        <Layout direction="row" spacing={2}>
          <DatePicker
            label="From"
            name="minDate"
            onChange={(date) => setFilters({ ...filters, minDate: date })}
            value={selectedContractMinDate}
          />
          <DatePicker
            label="To"
            name="maxDate"
            onChange={(date) => setFilters({ ...filters, maxDate: date })}
            value={selectedContractMaxDate}
          />
        </Layout>
        <Layout direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={resetFilters}
            label="Reset all filters"
            startIcon={faBan}
          />
        </Layout>
      </Layout>
    </Popover>
  );
};

export default ContractDecisionFilterMenu;
