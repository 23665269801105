import React, { useMemo } from "react";
import {
  Icon,
  Layout, Separator, Text,
} from "@fleet.co/tarmac";

import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { useSearchContext } from "../../contexts/SearchContext";
import OrderStatusNiceName from "./OrderStatusNiceName";

const OrderResult = (props) => {
  const { order } = props;
  const { searchHistory } = useSearchContext();

  const isFromHistory = useMemo(() => searchHistory.orders.some((item) => item.id === order.id), [searchHistory, order]);

  return (
    <Layout
      fullWidth
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      key={`orders-${order.id}`}
      divider={<Separator orientation="vertical" />}
    >
      <Text variant="body2" align="flex-start" sx={{ width: "20%" }}>
        {isFromHistory && (
          <>
            <Icon icon={faClock} />
            {" "}
          </>
        )}
        {order.request_number || "-"}
      </Text>
      <Text variant="body2" align="center" sx={{ width: "20%" }}>{order.order_number || "-"}</Text>
      <Text variant="body2" align="center" sx={{ width: "20%" }}>{order.bill_number || "-"}</Text>

      <Text variant="body2" align="center" sx={{ width: "20%" }}>
        <OrderStatusNiceName status={order.status} />
      </Text>

      <Text variant="body2" align="center" sx={{ width: "20%" }} noWrap>{order.company.name}</Text>
    </Layout>
  );
};

export default OrderResult;
