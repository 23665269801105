import React, { useContext, useState } from "react";
import {
  Button, Dialog, Icon, Layout, Tag, Text, TextField,
} from "@fleet.co/tarmac";
import {
  faCaretDown, faCaretRight, faCheck, faEuroSign, faPen, faRemove,
} from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../../tools/UserContext";
import styles from "./ContractEndingCSM.module.scss";

const ContractDecisionDevicesRow = (props) => {
  const { device, reloadOrders, order } = props;
  const { user: adminUser } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(device);
  const [isEditable, setIsEditable] = useState(false);
  const defautBuybackValue = Math.round(((25 * device.rent) / 100) * order.contract_duration * 100) / 100;

  const handleSubmit = async () => {
    // Only allow discounts
    const priceTooHigh = deviceInfo.buyback_value > defautBuybackValue;

    if (deviceInfo.buyback_value && priceTooHigh) {
      setIsEditable(true);

      return;
    }

    setIsLoading(true);
    await adminUser.api.modifyDevice(device.id, deviceInfo);

    setIsEditable(false);
    setIsModalOpen(false);
    setIsLoading(false);
    reloadOrders();
  };

  const handleClick = (type) => {
    setDeviceInfo({ ...deviceInfo, renewal_type: type });
    setIsModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleCancel = () => {
    setIsEditable(false);
    setDeviceInfo(device);
  };

  const getStatusName = () => {
    let color;

    switch (device.renewal_type) {
      case "STOP":
        color = "red";
        break;
      case "RENEW":
        color = "green";
        break;
      case "LOST":
        color = "purple";
        break;
      case "BROKEN":
        color = "orange";
        break;
      case "BUYBACK":
        color = "cyan";
        break;
      default:
        color = "grey";
        break;
    }

    const label = (
      <Layout direction="row" spacing={1} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {device?.renewal_type || "WAITING"}
        <Icon icon={isMenuOpen ? faCaretRight : faCaretDown} color="secondary" size="S" />
      </Layout>
    );

    return (
      <Layout direction="column" className={styles.statusContainer}>
        <Tag variant="filled" label={label} backgroundColor={color} />
        {isMenuOpen
          && (
            <Layout direction="column" className={styles.statusList}>
              <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>RENEW</Text>
              <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>STOP</Text>
              <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>BUYBACK</Text>
              <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>LOST</Text>
              <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>BROKEN</Text>
            </Layout>
          )}
      </Layout>
    );
  };

  const editableBuybackValue = () => {
    const hasPaid = order.payments.find((p) => p.type === "BUYBACK" && p.status === "PAID");

    if (hasPaid) {
      return null;
    }

    if (isEditable) {
      return (
        <Layout direction="row" spacing={1}>
          <Icon
            icon={faRemove}
            color="secondary"
            size="S"
            onClick={handleCancel}
          />

          <Icon
            icon={faCheck}
            color="secondary"
            size="S"
            onClick={handleSubmit}
          />
        </Layout>
      );
    }

    return (<Icon icon={faPen} color="secondary" size="S" onClick={() => setIsEditable(!isEditable)} />);
  };

  const paymentStatus = order.payments.find((p) => p.type === "BUYBACK")?.status;

  const getPaymentStatusColor = (paymentStatus) => {
    let color;

    switch (paymentStatus) {
      case "OPEN":
        color = "purple";
        break;
      case "DRAFT":
      case "VOID":
        color = "orange";
        break;
      case "PAID":
        color = "green";
        break;
      case "UNCOLLECTIBLE":
      case "DELETED":
        color = "red";
        break;
      default:
        color = "grey";
        break;
    }

    return color;
  };

  const ActionComponent = (
    <>
      <Button variant="contained" color="secondary" label="Cancel" onClick={() => setIsModalOpen(false)} />
      <Button variant="contained" color="primary" label={isLoading ? "Loading" : "Confirmation"} onClick={handleSubmit} />
    </>
  );

  return device && (
    <>
      <Layout direction="row" fullWidth key={device.id} className={styles.deviceRow}>
        <div>{device.name}</div>
        <div>{device.model_year}</div>
        <div>{device.serial_number}</div>
        <div>
          {device.rent}
          €
        </div>
        <Layout direction="row" spacing={1}>
          <Tag variant="filled" label={paymentStatus || "WAITING"} backgroundColor={getPaymentStatusColor()} />
        </Layout>
        <Layout direction="row" spacing={1}>
          <TextField
            name="buyback_value"
            type="number"
            onChange={(e) => setDeviceInfo({ ...deviceInfo, buyback_value: e.target.value })}
            value={deviceInfo.buyback_value}
            placeholder={defautBuybackValue}
            disabled={!isEditable}
            icon={faEuroSign}
            onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
          />
          {editableBuybackValue()}
        </Layout>
        <div>{getStatusName()}</div>
      </Layout>

      <Dialog
        title="Warning"
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        Actions={ActionComponent}
      >
        <Text variant="body1">You are about to change the renewal type of a device</Text>
        <br />
        <br />
        <Layout direction="row" spacing={2}>
          <Layout direction="column" spacing={2}>
            <Text variant="body1">Device :</Text>
            <Text variant="body1">S/N :</Text>
            <Text variant="body1">New renewal type :</Text>
          </Layout>
          <Layout direction="column" spacing={2} alignItems="center">
            <Text variant="body1">{deviceInfo.name}</Text>
            <Text variant="body1">{deviceInfo.serial_number}</Text>
            <Text variant="danger">{deviceInfo.renewal_type}</Text>
          </Layout>
        </Layout>
      </Dialog>
    </>
  );
};

export default ContractDecisionDevicesRow;
