import React, {
  useContext, useEffect, useMemo, useState,
} from "react";
import { Route, useLocation } from "react-router-dom";
import { Layout } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";

import CompanyInfoTabs from "../sections/company/CompanyInfoTabs";
import CompanyGeneralInfo from "../sections/company/CompanyGeneralInfo";
import CompanyAddressesInfo from "../sections/company/CompanyAddressesInfo";
import CompanyUsersInfo from "../sections/company/CompanyUsersInfo";
import CompanyDevicesInfo from "../sections/company/CompanyDevicesInfo";
import CompanyFiles from "../sections/company/CompanyFiles";
import CompanyFundingInfo from "../sections/company/CompanyFundingInfo";
import Breadcrumb from "../common/Breadcrumb";
import CompanyContext from "../../contexts/CompanyContext";
import CompanyOrdersTable from "../tables/companyOrders/CompanyOrdersTable";

const CompanyInfo = (props) => {
  const location = useLocation();
  const [companyInfo, setCompanyInfo] = useState(null);
  const { user: adminUser } = useContext(UserContext);
  const [currentAction, setCurrentAction] = useState(null);
  const [modalOpened, setModalOpened] = useState({
    type: "",
  });

  const [showFileUpload, setShowFileUpload] = useState(false);

  const getCompany = async () => {
    const company = await adminUser.api.getCompany(props.match.params.id);

    setCompanyInfo({ ...company.data, abm_id: company.data.company_mdm?.abm_id || null });
  };

  // returns a list of {name, link} objects, that are used in the Breadcrumb component
  const generateBC = () => {
    const res = [{ name: "Companies" }];

    // Main company page : no links
    if (location.pathname.endsWith(`${companyInfo.id}`)) {
      res.push({ name: companyInfo.name });

      return res;
    }

    // Sub pages
    res.push({ name: companyInfo.name, link: `/companies/${companyInfo.id}` });

    if (location.pathname.endsWith("devices")) {
      res.push({ name: "Devices" });
    } else if (location.pathname.endsWith("files")) {
      res.push({ name: "Files" });
    } else if (location.pathname.endsWith("users")) {
      res.push({ name: "Users" });
    } else if (location.pathname.endsWith("addresses")) {
      res.push({ name: "Addresses" });
    } else if (location.pathname.endsWith("orders")) {
      res.push({ name: "Orders" });
    } else if (location.pathname.endsWith("funding")) {
      res.push({ name: "Funding" });
    }

    return res;
  };

  useEffect(() => {
    getCompany();
  }, [location]);

  const companyContextValue = useMemo(() => ({
    company: companyInfo,
    setCompany: setCompanyInfo,
    reloadCompany: getCompany,
  }), [companyInfo]);

  if (!companyInfo) return "";

  return (
    <CompanyContext.Provider value={companyContextValue}>
      <Layout direction="column" spacing={1}>
        <Breadcrumb nameAndLinkArray={generateBC()} />

        <CompanyInfoTabs
          triggerAction={setCurrentAction}
          currentAction={currentAction}
          setModalOpened={setModalOpened}
          setShowFileUpload={setShowFileUpload}
        />

        <Route exact path="/companies/:id/">
          <CompanyGeneralInfo />
        </Route>
        <Route path="/companies/:id/addresses">
          <CompanyAddressesInfo
            setModalOpened={setModalOpened}
            modalOpened={modalOpened}
          />
        </Route>

        <Route path="/companies/:id/orders">
          <CompanyOrdersTable />
        </Route>

        <Route path="/companies/:id/funding">
          <CompanyFundingInfo
            setModalOpened={setModalOpened}
            modalOpened={modalOpened}
          />
        </Route>

        <Route path="/companies/:id/devices">
          <CompanyDevicesInfo />
        </Route>

        <Route exact path="/companies/:id/users">
          <CompanyUsersInfo
            setShowAddUser={(show) => setCurrentAction(show ? "add_user" : "")}
            showAddUser={currentAction === "add_user"}
          />
        </Route>
        <Route exact path="/companies/:id/files">
          <CompanyFiles
            showFileUpload={showFileUpload}
            closeUpload={() => setShowFileUpload(false)}
          />
        </Route>
      </Layout>
    </CompanyContext.Provider>
  );
};

export default CompanyInfo;
