import React, {
  useCallback, useContext, useMemo, useState,
} from "react";
import {
  Button, DatePicker, Icon, IconButton, Layout, Menu, MenuItem, Select, Text, TextField,
} from "@fleet.co/tarmac";
import {
  faBuilding, faCheckSquare, faEdit, faEllipsisVertical, faPaperPlane, faTrash, faUserSecret, faWindowClose,
} from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../../tools/UserContext";
import ErrorNotification from "../../common/ErrorNotification";
import styles from "./CompanyUserRow.module.scss";
import PhoneInput from "../../../tools/PhoneInput";
import StyledFlags from "../../common/StyledFlag";
import CopyButton from "../../../tools/CopyButton";
import { downloadFileBlob } from "../../../helpers/FilesHelpers";
import notifications from "../../../data/notifications";

const CompanyUserRow = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [errors, setErrors] = useState([]);

  const initial_user = {
    id: props.user.id,
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    email: props.user.email,
    phone: props.user.phone,
    phone_country: props.user.phone_country,
    roles: props.user.roles ? props.user.roles.map((r) => r.role) : [],
    notifications: props.user.notifications ? props.user.notifications.map((n) => n.type) : [],
    emails: props.user.emails ? props.user.emails : [],
    employee_access: props.user.employee_access,
    files: props.user.files ? props.user.files : [],
    city_of_birth: props.user.city_of_birth,
    country_of_birth: props.user.country_of_birth,
    date_of_birth: props.user.date_of_birth,
    company_id: props.user.company_id,
  };
  const [user, setUser] = useState(initial_user);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setUser((prevInfo) => ({ ...prevInfo, phone, phone_country: countryPhone }));
  };

  const saveUser = async () => {
    try {
      const updatedUser = await adminUser.api.modifyUser(user.id, user);

      setUser({
        ...updatedUser.data,
        roles: updatedUser.data.roles ? updatedUser.data.roles.map((r) => r.role) : [],
        notifications: updatedUser.data.notifications ? updatedUser.data.notifications.map((n) => n.type) : [],
      });
    } catch (e) {
      if (e.response?.data?.message) {
        setErrors([...errors, e.response.data.message]);
        setUser(initial_user);
      }
    }
  };

  const closeDeleteError = () => {
    setErrors([]);
  };

  const handleSubmit = () => {
    saveUser();
    setIsEditable(false);
  };

  const connectAs = useCallback(async () => {
    try {
      const { data: { accessToken } } = await adminUser.api.connectAs(user.id);
      const baseUrl = process.env.REACT_APP_PR_NUMBER ? `https://deploy-preview-${process.env.REACT_APP_PR_NUMBER}--fleet-cockpit.netlify.app` : process.env.REACT_APP_COCKPITURL;

      window.open(`${baseUrl}/?accessToken=${accessToken}`, "_blank");
    } catch (e) {
      setErrors([...errors, e.response.data.message]);
    }
  }, [adminUser.api, errors, user.id]);

  const deleteUser = async () => {
    await adminUser.api.deleteUser(user.id);
    props.reload();
  };

  const sendCockpitAccess = useCallback(async () => {
    await adminUser.api.sendAccess({ email: user.email });
  }, [adminUser.api, user.email]);

  const selectRoles = (rolesList) => {
    setUser({
      ...user,
      roles: rolesList || [],
    });
  };

  const selectNotifications = (notificationsList) => {
    setUser({
      ...user,
      notifications: notificationsList || [],
    });
  };

  const roles_options = [
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "Contract recipient",
      value: "CONTRACT_RECIPIENT",
    },
    {
      label: "Employee",
      value: "EMPLOYEE",
    },
    {
      label: "Legal representative",
      value: "LEGAL_REPRESENTATIVE",
    },
    {
      label: "Signatory",
      value: "SIGNATORY",
    },
  ];

  const notificationsOptions = notifications.map((n) => ({
    label: n.value[0] + n.value.slice(1).toLowerCase(),
    value: n.value,
  }));

  if (user.company_id === 1) {
    roles_options.push({
      label: "Super Admin",
      value: "FLEET_ADMIN",
    });
  }

  const menuContent = useMemo(() => {
    const canConnectAs = (user.roles.includes("ADMIN") || user.employee_access) && user.company_id !== 1;
    const connectAsAction = [{ label: "Connect as", action: () => connectAs(), icon: <Icon icon={faUserSecret} /> }];
    const sendLinkAction = [{ label: "Send login link", action: () => sendCockpitAccess(), icon: <Icon icon={faPaperPlane} /> }];

    const menuRows = [
      { label: "Edit", action: () => setIsEditable(true), icon: <Icon icon={faEdit} /> },
      { label: "Delete", action: () => setDeleteConfirmation(true), icon: <Icon icon={faTrash} /> },
      ...((user.roles.includes("ADMIN") || user.employee_access) ? sendLinkAction : []),
      ...(canConnectAs ? connectAsAction : []),
      { label: "Change company", action: () => props.openChangeCompanyModal(props.user), icon: <Icon icon={faBuilding} /> },
    ];

    return menuRows;
  }, [user.roles, user.employee_access, user.company_id, connectAs, sendCockpitAccess, props]);

  const openFile = async (file) => {
    const response = await adminUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  return (
    <>
      {errors.length ? (
        <tr>
          <td colSpan={8}>
            <ErrorNotification errors={errors} clear={closeDeleteError} />
          </td>
        </tr>
      ) : null}
      <tr key={`${user.company_id}-${user.id}`}>
        <td>
          {!isEditable && (
            <Layout direction="row" alignItems="center">
              {user.firstName}
              <CopyButton value={user.firstName} />
            </Layout>
          )}

          {isEditable && (
            <Layout direction="row" alignItems="center">
              <TextField
                required
                disabled={!isEditable}
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
              />
              <CopyButton value={user.firstName} />
            </Layout>
          )}
        </td>
        <td>
          {!isEditable && (
            <Layout direction="row" alignItems="center">
              {user.lastName}
              <CopyButton value={user.lastName} />
            </Layout>
          )}
          {isEditable && (
            <Layout direction="row" alignItems="center">
              <TextField
                required
                disabled={!isEditable}
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleChange}
              />
              <CopyButton value={user.lastName} />
            </Layout>
          )}
        </td>
        <td>
          {!isEditable && (
            <Layout direction="row" alignItems="center">
              <a
                target="_new"
                href={`https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTALID}/${props.user.hubspot_id}`}
              >
                {user.email}
              </a>
              <CopyButton value={user.email} />
            </Layout>
          )}

          {isEditable && (
            <Layout direction="row" alignItems="center">
              <TextField
                required
                disabled={!isEditable}
                type="email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
            </Layout>
          )}
        </td>
        <td>
          {!isEditable && (
            <Layout direction="row" alignItems="center">
              {user.phone}
              <CopyButton value={user.phone} />
            </Layout>
          )}
          {isEditable && (
            <PhoneInput
              value={user.phone}
              onChange={handlePhoneChange}
              country={user.phone_country || "fr"}
              placeholder="Enter phone number"
            />
          )}
        </td>
        <td>
          {isEditable ? (
            <Select
              label="Roles"
              multiple
              value={user.roles}
              options={roles_options}
              onChange={selectRoles}
            />
          ) : (
            user.roles.map((r, i) => <StyledFlags key={i} type={r} />)
          )}
        </td>
        <td>
          {isEditable ? (
            <Select
              label="Notifications"
              multiple
              value={user.notifications}
              options={notificationsOptions}
              onChange={selectNotifications}
            />
          ) : (
            user.notifications.map((n, i) => <StyledFlags key={i} type={n} />)
          )}
        </td>
        <td>
          {user.files?.map((file, i) => {
            if (file.type !== "OTHER") {
              return (
                <StyledFlags key={i} type="file" text={file.type.replace("_", " ")} onClick={() => openFile(file)} />
              );
            }

            return null;
          })}
        </td>
        <td>
          {!isEditable ? (
            <Layout direction="column">
              <Text variant="body1">
                city:
                {user.city_of_birth || "unknown"}
              </Text>
              <Text variant="body1">
                country code:
                {user.country_of_birth || "unknown"}
              </Text>
              <Text variant="body1">
                date:
                {user.date_of_birth ? new Date(user.date_of_birth).toLocaleDateString("fr-FR") : "unknown"}
              </Text>
            </Layout>
          )
            : (
              <Layout direction="column">
                <TextField
                  disabled={!isEditable}
                  label="City of birth"
                  name="city_of_birth"
                  value={user.city_of_birth}
                  onChange={handleChange}
                />
                <TextField
                  disabled={!isEditable}
                  label="Country code"
                  name="country_of_birth"
                  value={user.country_of_birth}
                  onChange={handleChange}
                  maxLength={2}
                />
                <DatePicker
                  label="Date of birth"
                  name="date_of_birth"
                  value={user.date_of_birth ? new Date(user.date_of_birth) : null}
                  onChange={(date) => handleChange({ target: { name: "date_of_birth", value: new Date(date.setHours(12)) } })}
                />
              </Layout>
            )}
        </td>
        <td
          className={`bo-dropdown-menu-container ${styles.dropdownMenu}`}
        >
          {!isEditable && !deleteConfirmation && (
            <Menu
              AnchorComponent={IconButton}
              anchorProps={{ color: "secondary", size: "M", icon: <Icon icon={faEllipsisVertical} /> }}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
            >
              {menuContent.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={item.action}
                  label={item.label}
                  icon={item.icon}
                />
              ))}
            </Menu>
          )}
          {deleteConfirmation && (
            <>
              <p className="delete-confirmation">Are you sure?</p>
              <div className="flex-row">
                <Button
                  variant="contained"
                  color="error"
                  label="Delete"
                  size="small"
                  onClick={() => deleteUser()}
                />

                <Button
                  variant="outlined"
                  color="secondary"
                  label="Cancel"
                  size="small"
                  onClick={() => setDeleteConfirmation(false)}
                />
              </div>
            </>
          )}
          {isEditable && (
            <div className="flex sa">
              <Icon icon={faCheckSquare} color="secondary" size="S" className="edit-row-validate" onClick={() => handleSubmit()} />
              <Icon icon={faWindowClose} color="secondary" size="S" onClick={() => setIsEditable(false)} className="edit-row-cancel" />
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default CompanyUserRow;
