import React, { useState } from "react";

import {
  Button, Heading, Layout, Text,
} from "@fleet.co/tarmac";

import styles from "./ProductGroupTable.module.scss";

import ProductRow from "./ProductRow";
import { productSpecs } from "../../../data/productSpecs";
import { formatMoney } from "../../../helpers/formatHelpers";

const ProductGroupTable = (props) => {
  const {
    openProductGroupModal, product_group, all_product_groups, getProductGroups, openProductModal, currentCurrency,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const allProducts = product_group.products;

  // products with the visibility status "AVAILABLE"
  const availables = allProducts.filter((p) => p.visibility === "AVAILABLE").length;
  const [availableProducts, setAvailableProducts] = useState(availables);

  const getLowerPrice = () => {
    let lowerPrice = null;

    for (const product of allProducts) {
      const price = product.prices.find((price) => price.currency === currentCurrency);

      if (price !== null && price !== undefined) {
        if (lowerPrice === null || price < lowerPrice) {
          lowerPrice = price;
        }
      }
    }

    return lowerPrice?.amount;
  };

  const getTypologyList = () => {
    const typologyKey = {
      new: { name: "NEUF", color: "#ACEACD" },
      asnew: { name: "CERTIFIÉ APPLE", color: "#B0B2DB" },
      refurb: { name: "RECONDITIONNÉ +", color: "#F8CCA4" },
    };

    const state_list = allProducts
      .map((product) => product.quality)
      .filter((quality) => quality !== null);

    const no_duplicate = [...new Set(state_list)];
    const french_wording = no_duplicate.map((s) => (typologyKey[s].name));
    const clean_state_list = french_wording.join(" ");
    const typology_color = typologyKey[state_list[0]] ? typologyKey[state_list[0]].color : null;

    if (!typology_color) return null;

    return (
      <div className={styles.statusContainer}>
        <span className={styles.qualityImg}>
          <img
            src={`${process.env.REACT_APP_WEBSITEURL}/images/quality/${state_list[0]}.svg`}
            alt="quality"
          />
        </span>

        <Text variant="body1" style={no_duplicate.length > 1 ? { color: "red" } : null}>
          {" "}
          {clean_state_list || null}
        </Text>
      </div>
    );
  };

  const getAvailableProductWording = () => (
    <>
      {" "}
      available
      {" "}
      <br />
      {" "}
      model
      {allProducts.length > 1 ? "s" : ""}
    </>
  );

  return (
    <div className={styles.productGroupTable}>
      <div className={styles.productGroupTableHeader}>
        <Layout direction="column" spacing={1} justifyContent="center" onClick={() => setIsOpen(!isOpen)}>
          <Text variant="body1" color="info" align="center" bold>
            {allProducts.length === 1
              ? availableProducts
              : `${availableProducts} sur ${allProducts.length}`}
          </Text>
          <Text variant="body2" align="center" bold>{getAvailableProductWording()}</Text>
        </Layout>

        <div
          className={styles.productGroupPictureContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            className={styles.productGroupPicture}
            src={product_group.img_url}
            alt={product_group.name}
          />
        </div>

        <div
          className={styles.productGroupNameAndDescription}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Heading variant="h6">{product_group.name}</Heading>
          <Text variant="body2" noWrap color="secondary">{product_group.description}</Text>
        </div>

        {allProducts.length > 0 && (
          <>
            <div className={styles.rseInfo} onClick={() => setIsOpen(!isOpen)}>
              {getTypologyList()}
            </div>

            <div
              className={styles.productGroupPriceBox}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Text variant="body2" align="center">à partir de</Text>
              <Text variant="body1" bold align="center">
                {" "}
                {formatMoney(getLowerPrice(), currentCurrency)}
              </Text>
              <Text variant="body2" align="center">/mois (HT)</Text>
            </div>
          </>
        )}

        {!allProducts.length && (
          <>
            <div
              className={styles.productGroupState}
              onClick={() => setIsOpen(!isOpen)}
            />
            <div
              className={styles.productGroupPriceBox}
              onClick={() => setIsOpen(!isOpen)}
            />
          </>
        )}

        <Layout direction="row" spacing={1}>
          <Button variant="contained" color="secondary" size="small" label="Edit Product Group" onClick={() => openProductGroupModal(product_group)} />

          <Button variant="contained" color="secondary" size="small" label="Add Product" onClick={() => openProductModal(product_group, null)} />
        </Layout>
      </div>

      <div style={!isOpen ? { display: "none" } : null} className={styles.productsTable}>
        <div className={styles.productsTableHeader}>
          <div>Price</div>
          {productSpecs.filter((spec) => spec.categories.includes(product_group.category) && spec.applyTo.includes("PRODUCT")).map((spec) => (
            <div key={spec.name}>
              {spec.label}
            </div>
          ))}
          <div>Delivery Range</div>
          <div>Shop Visibility</div>
        </div>

        <hr />

        {allProducts.map((p) => (
          <ProductRow
            product={p}
            product_group={product_group}
            key={p.id}
            all_product_groups={all_product_groups}
            getProductGroups={getProductGroups}
            products={allProducts}
            openProductModal={openProductModal}
            availableProducts={availableProducts}
            setAvailableProducts={setAvailableProducts}
            currentCurrency={currentCurrency}
          />
        ))}

        {!allProducts.length && (
          <div className={styles.noProduct}>
            This Product Group has no Product.
            <span onClick={() => openProductModal(product_group, null)}>Click here to add one. </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductGroupTable;
