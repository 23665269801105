import React, { useEffect, useMemo, useState } from "react";
import {
  CssBaseline, ThemeProvider, theme,
} from "@fleet.co/tarmac";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import "moment/locale/fr";
import { LicenseInfo } from "@mui/x-license-pro";

import { Route, Switch, useHistory } from "react-router-dom";
import qs from "qs";
import AuthRoute from "./AuthRoute";
import UserContext from "./tools/UserContext";
import AuthService from "./components/auth/auth-service";
import { ToastContainer } from "./components/common/ToastContainer";
import AdminContainer from "./AdminContainer";
import Login from "./components/login/Login";
import LoadingSpinner from "./components/common/LoadingSpinner";
import useLocalStorage from "./hooks/useLocalStorage";

import Zendesk from "./components/views/Zendesk";

const App = () => {
  const [user, setUser] = useState(null);
  const [source, setSource] = useState({
    utm_term: null,
    utm_campaign: null,
    utm_source: null,
    utm_medium: null,
    gclid: null,
    landing_page: null,
    referrer: null,
    google_client_id: null,
    referral: null,
    language: null,
    leaseback: null,
    after_life: null,
  });

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [, setAccessToken] = useLocalStorage("accessToken");
  const sourceParams = qs.parse(window.location.search.slice(1)) || {};

  // Set MUI License Key
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_ID);

  const service = new AuthService();

  const fetchUser = async () => {
    try {
      const loggedUser = await service.loggedin();

      setUser(loggedUser);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sourceParams.accessToken) {
      setAccessToken(sourceParams.accessToken);
      history.replace(history.location.pathname);
    }

    // TODO : if referrer or landing_page are passed : do not overwrite
    if (!sourceParams.referrer) {
      sourceParams.referrer = document.referrer;
    }

    if (!sourceParams.landing_page) {
      sourceParams.landing_page = window.location.pathname;
    }

    if (sourceParams) {
      const state_params = Object.keys(source)
        // If the state param is in the URL
        .filter((key) => sourceParams[key])
        // Create a key-value pair
        .map((key) => [key, sourceParams[key]]);

      // Object.entity turns it into an object
      setSource({ ...source, ...Object.fromEntries(state_params) });
    }

    if (user === null) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (sourceParams.accessToken) {
      window.location.reload();
    }
  }, [sourceParams.accessToken]);

  const isSuperAdmin = user && user.hasRole("FLEET_ADMIN");

  const values = useMemo(() => ({ user, updateUser: setUser }), [user]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <UserContext.Provider value={values}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/zendesk" component={Zendesk} />
            <AuthRoute path="/" component={AdminContainer} isSuperAdmin={isSuperAdmin} />
          </Switch>
          <ToastContainer />
        </UserContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
