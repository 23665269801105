import React, { useMemo } from "react";
import {
  Icon, Layout, Separator, Text,
} from "@fleet.co/tarmac";
import { faClock } from "@fortawesome/pro-regular-svg-icons";

import { useSearchContext } from "../../contexts/SearchContext";
import OrderStatusNiceName from "./OrderStatusNiceName";

const DeviceResult = (props) => {
  const { device } = props;
  const { searchHistory } = useSearchContext();

  const isFromHistory = useMemo(() => searchHistory.devices.some((item) => item.id === device.id), [searchHistory, device]);

  return (
    <Layout
      fullWidth
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      key={`devices-${device.id}`}
      divider={<Separator orientation="vertical" />}
    >
      <Text variant="body2" align="center" sx={{ width: "20%" }}>
        {isFromHistory && (
          <>
            <Icon icon={faClock} />
            {" "}
          </>
        )}
        {device.serial_number}
        {" "}
        🏷
      </Text>

      <Text variant="body2" align="center" sx={{ width: "20%" }}>
        🚛
        {" "}
        {device.tracking_number}
      </Text>

      <Layout direction="column" spacing={1} justifyContent="center" fullHeight sx={{ width: "10%" }}>
        <OrderStatusNiceName status={device.status} />
        {device.renewal_type && <OrderStatusNiceName status={device.renewal_type} />}
      </Layout>

      <Text variant="body2" align="center" sx={{ width: "30%" }}>{device.name}</Text>
      <Text variant="body2" align="center" sx={{ width: "20%" }}>{device.company?.name}</Text>
    </Layout>
  );
};

export default DeviceResult;
