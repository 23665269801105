import React, { useContext } from "react";
import {
  Avatar, Icon, IconButton, Layout, Text,
} from "@fleet.co/tarmac";
import {
  faDownload, faFileAlt, faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../tools/UserContext";
import { downloadFileBlob } from "../../helpers/FilesHelpers";

const FileOrderSection = ({
  file, refresh, isEditable,
}) => {
  const { user: adminUser } = useContext(UserContext);

  const removeFile = async (file_id) => {
    await adminUser.api.deleteFile(file_id);
    refresh();
  };

  const openFile = async (file) => {
    const response = await adminUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  return (
    <Layout direction="row" alignItems="center" spacing={2}>
      <Avatar
        icon={<Icon icon={faFileAlt} color="primary" size="S" />}
        size={40}
        color="#F0EDFF"
      />
      <Text variant="body2">
        {file?.original_name}
      </Text>
      <Layout direction="row" spacing={2}>
        <IconButton
          variant="contained"
          color="secondary"
          icon={<Icon icon={faDownload} color="secondary" size="S" />}
          onClick={() => openFile(file)}
          disabled={!isEditable}
        />
        <IconButton
          variant="contained"
          color="secondary"
          icon={<Icon icon={faTrash} color="secondary" size="S" />}
          onClick={() => removeFile(file.id)}
          disabled={!isEditable}
        />
      </Layout>
    </Layout>
  );
};

export default FileOrderSection;
