import React, { useContext, useEffect, useState } from "react";
import {
  Alert, Button, Drawer, Layout, SearchBar, Select, Text, TextField,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import PhoneInput from "../../../tools/PhoneInput";
import PlaceService from "../../../tools/PlaceService";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";

const initialAddress = {
  label: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  country: "",
  firstName: "",
  lastName: "",
  phone: "",
  phone_country: null,
};

const AdminAddressesModal = (props) => {
  // PROPS & CONTEXT
  const {
    onSave, closeAction, open, companyId, address: addressFromProps,
  } = props;
  const { user: adminUser } = useContext(UserContext);

  // GENERAL STATES
  const [errors, setErrors] = useState("");
  const [addressQuery, setAddressQuery] = useState("");
  const [, setPhoneIsValid] = useState(false);

  const [addressInfo, setAddressInfo] = useState(initialAddress);
  const [suggestions, setSuggestions] = useState([]);

  const authorizedCountries = [
    { label: "Choose a country*", value: "" },
    { label: "France", value: "France" },
    { label: "Portugal", value: "Portugal" },
    { label: "Spain", value: "Spain" },
    { label: "Italy", value: "Italia" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "Germany", value: "Germany" },
    { label: "Luxemburg", value: "Luxembourg" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Belgium", value: "Belgium" },
    { label: "Canada", value: "Canada" },
    { label: "Other", value: "Autre" },
  ];

  const place_service = new PlaceService();

  // FUNCTIONS
  // Close modal (cancel)
  const onCloseAddressModal = () => {
    setErrors("");
    setAddressInfo(initialAddress);
    closeAction();
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddressInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setAddressInfo((prevInfo) => ({ ...prevInfo, phone, phone_country: countryPhone }));
  };

  const handleCountryChange = (country) => {
    setAddressInfo((prevInfo) => ({ ...prevInfo, country }));
  };

  const handleSearch = async () => {
    if (!open) return;

    if (addressQuery.length < 3) {
      clearSuggestions();

      return;
    }
    const suggestionsFromApi = await place_service.getPlaceIdFromText(addressQuery);

    setSuggestions(suggestionsFromApi);
  };

  useDebouncedEffect(handleSearch, 500, [addressQuery]);

  // Handle address select in results box
  const handleSelect = async (_, suggestion) => {
    if (!suggestion) {
      setAddressInfo(initialAddress);
      setAddressQuery("");
      clearSuggestions();

      return;
    }

    const selectedSuggestion = suggestions.find((s) => s.description === suggestion);

    const placeId = selectedSuggestion.place_id;
    const addressFromApi = await place_service.getAddressForPlaceId(placeId);

    setAddressQuery(selectedSuggestion.structured_formatting.main_text || "");
    setAddressInfo({
      ...addressInfo,
      ...addressFromApi,
      address1: selectedSuggestion.structured_formatting.main_text || "",
    });

    clearSuggestions();
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  // Check if modal button should be disabled
  const isButtonDisabled = addressInfo.label === ""
    || addressInfo.address1 === ""
    || addressInfo.zip === ""
    || addressInfo.city === ""
    || addressInfo.country === ""
    || addressInfo.lastName === ""
    || addressInfo.firstName === ""
    || JSON.stringify(addressInfo) === JSON.stringify(initialAddress);

  //   Modal "submit" button
  //   Create a new address or update it
  const saveChanges = async () => {
    try {
      if (addressFromProps) {
        await adminUser.api.modifyAddress(addressFromProps.id, addressInfo);
      } else {
        await adminUser.api.addAddress({ ...addressInfo, company_id: companyId });
      }
      onCloseAddressModal();
      onSave();
    } catch (err) {
      console.log(err);
      setErrors(err.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (addressFromProps) {
      // INITIALISATION STATES & VARIABLES
      const address = Object.keys(initialAddress).reduce((acc, key) => {
        acc[key] = addressFromProps[key] || "";

        return acc;
      }, {});

      setPhoneIsValid(Boolean(addressFromProps?.phone));
      setAddressInfo(address);
      setAddressQuery(addressFromProps?.address1 || "");
    } else {
      setAddressInfo(initialAddress);
      setAddressQuery("");
    }
  }, [addressFromProps, companyId]);

  return (
    <Drawer
      open={open}
      title={
        addressFromProps && Object.entries(addressFromProps).length > 0
          ? "Update address"
          : "Add new address"
      }
      Actions={(
        <Button
          variant="contained"
          color="primary"
          label="Save"
          onClick={saveChanges}
          disabled={isButtonDisabled}
        />
      )}
      onClose={onCloseAddressModal}
    >
      <Layout direction="column" spacing={1}>
        {errors && <Alert severity="error" onClose={() => setErrors(null)}>{errors}</Alert>}
        <TextField
          label="Title of this address*"
          name="label"
          value={addressInfo.label || ""}
          onChange={handleChange}
          placeholder="Name of the address"
        />
        <Layout direction="column" spacing={2}>
          <Text variant="body1">Shipping contact</Text>
          <Layout direction="row" spacing={2}>
            <TextField
              label="First name"
              name="firstName"
              value={addressInfo.firstName || ""}
              onChange={handleChange}
              placeholder="Firstname"
              required
              fullWidth
            />
            <TextField
              label="Last name"
              name="lastName"
              value={addressInfo.lastName || ""}
              onChange={handleChange}
              placeholder="Lastname"
              required
              fullWidth
            />
          </Layout>
          <Layout>
            <Text variant="caption">Phone number*</Text>
            <PhoneInput
              value={addressInfo.phone}
              onChange={handlePhoneChange}
              country={addressInfo.phone_country || "fr"}
              setIsPhoneValid={setPhoneIsValid}
              placeholder="Enter phone number"
            />
          </Layout>
        </Layout>
        <Layout direction="column" spacing={2}>
          {/* If modal intend to modify an existing address, input field is standard */}
          {addressFromProps && Object.entries(addressFromProps).length > 0 ? (
            <TextField
              label="Address*"
              autoComplete="off"
              placeholder="21 rue de Bruxelles"
              name="address1"
              value={addressInfo.address1 || ""}
              onChange={handleChange}
              maxLength="40"
            />
          ) : (
            // If modal intend to create a new address, the "search address" results box is displayed
            <SearchBar
              name="address1"
              value={addressQuery}
              onInputChange={(_, value) => setAddressQuery(value)}
              placeholder="21 rue de Bruxelles"
              maxLength="40"
              options={suggestions.map((suggestion) => suggestion.description)}
              onChange={handleSelect}
              renderOption={(option) => option}
            />
          )}

          <TextField
            name="address2"
            value={addressInfo.address2 || ""}
            onChange={handleChange}
            label="Additional address"
            maxLength="60"
          />

          <Layout direction="row" spacing={2}>
            <TextField
              name="zip"
              value={addressInfo.zip || ""}
              onChange={handleChange}
              label="Postal code"
              sx={{ width: "25%" }}
            />
            <TextField
              name="city"
              value={addressInfo.city || ""}
              onChange={handleChange}
              label="City"
              fullWidth
            />
          </Layout>

          <Select
            label="Country"
            value={addressInfo.country}
            options={authorizedCountries}
            onChange={(value) => handleCountryChange(value)}
          />
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default AdminAddressesModal;
